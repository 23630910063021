<template>
  <div class="section">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/' }">首頁</el-breadcrumb-item>
      <el-breadcrumb-item>服務項目</el-breadcrumb-item>
      <el-breadcrumb-item>{{ pathName }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row :gutter="$isMobile() ? 0 : 50">
      <el-col :xs="24" :span="6" v-if="!$isMobile()">
        <Sidebar :serviceList="serviceList" />
      </el-col>
      <el-col :xs="24" :span="18" id="service-detail">
        <ServiceDetailComponent
          :serviceName="pathName"
          :serviceDetail="apiServiceDetail"
          :orderName="orderName"
          :subOrder="subOrder"
        />
        <Alert />
      </el-col>
    </el-row>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variable.scss';
.section {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: calc(#{$navigationHeight} + 100px);
  .breadcrumb {
    margin: 50px 0;
  }
}
@media screen and (max-width: 500px) {
  .section {
    max-width: 100%;
    margin-top: $navigationHeight;
    padding: 20px;
  }
}
</style>

<script>
import serviceList from '@/info/serviceList.js'

import ServiceDetailComponent from '@/components/section/serviceDetail.vue'
import Sidebar from '@/components/section/sidebar.vue'

import Alert from '@/components/section/alert.vue'

export default {
  name: 'Section',
  data() {
    return {
      pathName: '',
      serviceNo: '',
      serviceDetail: {},
      serviceList,
      orderName: '',
      subOrder: '',
      setting: {
        title: '',
        description: ''
      }
    }
  },
  components: {
    ServiceDetailComponent,
    Sidebar,
    Alert
  },
  computed: {
    apiServiceDetail() {
      return this.$store.state.serviceDetail
    }
  },
  async created() {
    this.pathName = this.$router.currentRoute.name
    const matched = this.$router.currentRoute.matched

    ///第一層
    if (matched.length == 1) {
      this.$router.push({ name: 'notfound' })
    } else if (matched.length == 2) {
      ///第二層
      this.serviceDetail = this.serviceList.find(
        (item) => item.name == this.pathName
      )
      this.serviceNo = this.serviceList.find(
        (item) => item.name == this.pathName
      ).no
      this.orderName = this.pathName
    } else if (matched.length == 3) {
      ///第三層
      const parent = this.serviceList.find(
        (item) => item.name == matched[1].name // 上一級名稱
      )
      this.serviceDetail = parent.children.find(
        (item) => item.name == this.pathName
      )
      this.serviceNo = parent.children.find(
        (item) => item.name == this.pathName
      ).no

      this.orderName = matched[1].name
      this.subOrder = this.pathName
    }
    if (this.pathName !== '購買時數') {
      await this.$store.dispatch('getServiceDetail', this.serviceNo)
      await this.$store.dispatch('getServicePys')
      const descEl = document.querySelector('head meta[name="Description"]')
      await descEl.setAttribute(
        'content',
        this.apiServiceDetail.service?.description
          .replace(/(?:\\[rn]|[\r\n]+)+/g, '')
          .trim()
      )
    } else {
      this.orderName = '購買時數'
      this.$store.commit('setServiceDetail', {
        online_form: null,
        service: {
          description: '',
          images: [require('../assets/service/hours/introduction.jpg')],
          simple_description:
            '<h2>時數使用規範</h2><p>1. 對外統一限制使用一個地址,若會員想與家人共用,可通融在同一社區使用,並且限制為親戚、家人<br/>2. 若不同區的話,僅通融一次使用同一個會員帳號使用。<br/>3. 若搬家至寶實潔服務區域以外,可客戶轉讓給其他朋友或親戚。<br/>一年轉移地址僅限一次。<br/>4. 若客人的時數使用不同區,客服需在往來紀錄備註(僅限一次)。<br/>5. 居家/辦公室/營業場所,分開購買。<br/>6. 服務地址有車馬費,於每次服務現收取。<br/>7. 年前加價,依年度公告而定<br/></p>'
        }
      })
    }
    const titleEl = document.querySelector('head title')
    titleEl.textContent = this.pathName
  }
}
</script>
