<template>
  <div class="profile">
    <form-create
      :rule="rule"
      v-model="fApi"
      :option="options"
      :value.sync="value"
    />
    <el-button type="success" @click="send">保存</el-button>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/style/variable.scss";
.profile {
  h2 {
    text-align: center;
    color: $primaryColor;
  }
}
@media screen and (max-width: 500px) {
  .profile {
    padding: 30px;
  }
}
</style>

<script>
export default {
  data() {
    const self = this;
    return {
      fApi: {},
      value: {},
      options: {
        form: {
          labelPosition: "left",
        },
        submitBtn: {
          show: false,
        },
        onSubmit: (formData) => {
          alert(JSON.stringify(formData));
        },
      },
      rule: [
        // {
        //   type: "el-col",
        //   children: [
        //     {
        //       type: "h3",
        //       children: ["變更名稱"],
        //     },
        //   ],
        // },
        // {
        //   type: "input",
        //   field: "service",
        //   title: "名稱",
        //   value: "測試帳戶",
        //   props: {
        //     placeholder: "",
        //     size: "large",
        //   },
        //   col: {
        //     span: 13,
        //     xs: 24,
        //   },
        // },
        {
          type: "el-col",
          children: [
            {
              type: "h3",
              children: ["變更密碼"],
            },
          ],
        },
        // {
        //   type: "input",
        //   field: "password",
        //   title: "目前密碼",
        //   value: "",
        //   validate: [
        //     { required: true, message: "此欄位必填" },
        //     { type: "string", min: 8, message: "最少8個字元" },
        //   ],
        //   props: {
        //     placeholder: "輸入目前密碼",
        //     size: "large",
        //   },
        //   col: {
        //     span: 13,
        //     xs: 24,
        //   },
        // },
        {
          type: "input",
          field: "newPassword",
          title: "新密碼",
          value: "",
          validate: [
            { required: true, message: "此欄位必填" },
            { type: "string", min: 8, message: "最少8個字元" },
          ],
          props: {
            placeholder: "輸入新密碼",
            size: "large",
            type: "password",
          },
          col: {
            span: 13,
            xs: 24,
          },
        },
        {
          type: "input",
          field: "newPassword2",
          title: "確認新密碼",
          value: "",
          validate: [
            { required: true, message: "此欄位必填" },
            {
              validator: (rule, value, d) => {
                self.fApi.form.newPassword != value ? d(true) : d();
              },
              message: "兩次輸入密碼不一致",
            },
            { type: "string", min: 8, message: "最少8個字元" },
          ],
          props: {
            placeholder: "再次輸入新密碼",
            size: "large",
            type: "password",
          },
          col: {
            span: 13,
            xs: 24,
          },
        },
        // {
        //   type: "el-col",
        //   children: [
        //     {
        //       type: "el-divider",
        //       children: ["已綁定社群登入"],
        //     },
        //   ],
        // },
        // {
        //   type: "el-col",
        //   children: [
        //     {
        //       type: "el-empty",
        //       props: {
        //         description: "沒有已綁定的社群登入",
        //       },
        //     },
        //   ],
        // },
      ],
    };
  },
  methods: {
    send() {
      const self = this;

      this.fApi.submit(
        (formData, fapi) => {
          const payload = {
            password: formData.newPassword,
          };

          this.axios
            .patch(
              this.$store.state.apiUrl + "customers/password-reset",
              payload
            )
            .then((response) => {
              if (response.status == 200) {
                this.$message({
                  message: "密碼修改成功，請重新登入。",
                  type: "success",
                });
                fapi.resetFields();

                this.$router.push("/login");
              }
            })
            .catch((e) => {
              if (e.response.data.status == 401) {
                this.$message({
                  message: "密碼修改失敗，請確認資料已正確填寫",
                  type: "error",
                });
              } else {
                this.$message({
                  message: "目前無法修改密碼，請稍後再試",
                  type: "error",
                });
              }
            });
        },
        () => {
          self.$message({
            message: "資料格式錯誤",
            type: "error",
          });
        }
      );
    },
  },
};
</script>
