<template>
  <div class="section section1">
    <div class="swiper-box">
      <swiper :options="swiperOptions">
        <swiper-slide
          v-for="(img, i) in bannerList"
          :key="i"
          v-bind:style="{
            backgroundImage: 'url(' + img.img + ')',
          }"
        >
        <a :href="img.link" target="_blank"></a>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.section1 {
  .swiper-box {
    .swiper-slide {
      cursor: pointer;
      text-align: center;
      background-size: 100%;
      background-position: center;
      height: 0;
      padding-bottom: 31%;
      a {
        z-index: 1;
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .section1 {
    .swiper-box {
      .swiper-slide {
        text-align: center;
        background-size: cover;
        background-position: center;
        height: 0;
        padding-bottom: 100%;
      }
    }
  }
}
</style>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

import Swiper2, { Pagination, Autoplay } from "swiper";
Swiper2.use([Pagination, Autoplay]);

import "swiper/swiper-bundle.css";

export default {
  name: "Banner",
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
        },
      },
      bannerList: [],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  mounted() {},
  created() {
    this.axios.get(this.$store.state.apiUrl + "banners").then((response) => {
      if (response.status == 200) {
        response.data.data.banners.forEach((element) => {
          const obj = {
            img: this.$isMobile() ? element.mobile_img : element.pc_img,
            link: element.url,
          };
          this.bannerList.push(obj);
        });
      }
    });
  },
  method: {
    goTo(link) {
      // console.log(link);
      window.open(link);
    },
  },
};
</script>