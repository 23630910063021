<template>
  <div class="section section4 w1200" id="comment">
    <h1>客戶佳評</h1>
    <div class="swiper-box">
      <swiper :options="commentSwiperOptions">
        <swiper-slide v-for="(comment, i) in commentList" :key="i">
          <div class="comment-box">
            <div
              class="comment-img"
              :style="{ backgroundImage: 'url(' + comment.avatar + ')' }"
              @click="goto(comment.link)"
            >
              <div class="comment-badge">{{comment.category}}</div>
            </div>
            <div class="comment-content">
              <div class="comment-name" v-html="comment.name"></div>
              <div class="comment-comment" v-html="comment.comment"></div>
            </div>
            <div class="show-more" @click="showMore(comment.id)">...更多</div>
          </div>
        </swiper-slide>
        <div class="swiper-button-next comment-next" slot="button-next"></div>
        <div class="swiper-button-prev comment-prev" slot="button-prev"></div>
      </swiper>
    </div>

    <el-dialog
      title="客戶好評"
      v-if="commentDetail"
      :visible.sync="commentDetailShow"
      :width="$isMobile() ? '100%' : '30%'"
    >
      <div>
        <el-avatar
          shape="square"
          :size="250"
          :fit="contain"
          :src="commentDetail.avatar"
        ></el-avatar>
        <h2 style="margin: 0; margin-top: 20px">{{ commentDetail.name }}</h2>
        <p>{{ commentDetail.comment }}</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="commentDetail = !commentDetail">關閉</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<style lang="scss" scoped>
@import "@/assets/style/variable.scss";

.section4 {
  .swiper-box {
    .swiper-slide {
      position: relative;
      .comment-box {
        height: auto;
        display: flex;
        flex-direction: column;
        transition: all 0.3s;
        &:hover {
          cursor: pointer;
          transform: translateY(-5%);
        }
        .comment-img {
          flex: auto;
          width: 100%;
          height: 0;
          padding-bottom: 70%;
          background-size: cover;
          background-position: center;
          position: relative;
          border-radius: 8px 8px 8px 8px;
          .comment-badge {
            position: absolute;
            right: 15px;
            bottom: 15px;
            color: #fff;
            background-color: rgba($color: $primaryColor, $alpha: 0.8);
            padding: 8px 12px;
            font-weight: 100;
            border-radius: 10px;
            font-size: 17px;
          }
        }
        .comment-content {
          box-sizing: border-box;
          padding: 18px 14px;
          flex: auto;
          .comment-name {
            font-weight: 500;
            font-size: 22px;
            margin-bottom: 12px;
          }
          .comment-comment {
            font-weight: 200;
            line-height: 1.5;

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            text-align: justify;
          }
        }

        .show-more {
          right: 5px;
          bottom: 21px;
          position: absolute;
          z-index: 1;
          color: $primaryColor;
          cursor: pointer;
          background: #fff;
          padding: 0 7px;
          border-radius: 10px;

          &:hover {
            color: rgb(33, 109, 145);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .section4 {
    .swiper-box {
      .swiper-slide {
        .comment-box {
          .comment-img {
            border-radius: 0;
            .comment-badge {
              right: 10px;
              bottom: 10px;
              padding: 4px 8px;
              border-radius: 4px;
              font-size: 12px;
            }
          }
          .comment-content {
            .comment-name {
              font-weight: bold;
              font-size: 18px;
              margin-bottom: 12px;
            }
            .comment-comment {
              font-weight: 200;
              line-height: 1.3;
              font-size: 14px;
            }
          }
        .show-more {
          bottom:18px;

          &:hover {
            color: rgb(33, 109, 145);
          }
        }
        }
      }
    }
  }
}
</style>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

import Swiper2, { Navigation, Pagination, Autoplay } from "swiper";
Swiper2.use([Navigation, Pagination, Autoplay]);
// import commentList from "@/info/commentList";

export default {
  name: "Comment",
  data() {
    return {
      commentList: [],
      commentDetailShow: false,
      commentDetail: null,
      commentSwiperOptions: {
        navigation: {
          nextEl: ".comment-next",
          prevEl: ".comment-prev",
        },
        breakpoints: {
          300: {
            slidesPerView: 2,
            slidesPerColumn: 1,
            slidesPerColumnFill: "row",
            spaceBetween: 30,
          },
          500: {
            slidesPerView: 4,
            slidesPerColumn: 2,
            slidesPerColumnFill: "row",
            spaceBetween: 30,
          },
        },
      },
    };
  },

  components: {
    Swiper,
    SwiperSlide,
  },
  created() {
    const self = this;

    this.axios.get(this.$store.state.apiUrl + "shares").then((response) => {
      if (response.status == 200) {
        response.data.data.shares.forEach((elem) => {
          let obj = {
            id: elem.id,
            name: elem.title,
            avatar: elem.pic,
            category: elem.category,
            comment: elem.simple_content,
            background: "",
            link: elem.link_url,
          };
          this.commentList.push(obj);
        });
      }
    });
  },
  methods: {
    goto(link) {
      if (link) {
        window.open(link);
      }
    },
    showMore(id) {
      let comment_ = this.commentList.find((item) => item.id == id);
      this.commentDetail = comment_;
      this.commentDetailShow = true;
    },
  },
  mounted() {},
};
</script>
