<template>
  <div class="step3">
    <el-row
      :type="$isMobile() ? '' : 'flex'"
      justify="space-between"
      align="flex-start"
    >
      <el-col :span="$isMobile() ? 24 : 12">
        <form-create
          :rule="rule"
          v-model="fApi"
          :option="options"
          :value.sync="value"
          @login-click="login"
          @register-click="register"
        />
      </el-col>
      <el-col :span="$isMobile() ? 24 : 10" class="info-block">
        <OrderDetail />
      </el-col>
    </el-row>
    <div class="tip-text">
      <h3>填寫客戶資料</h3>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variable.scss';

.source-radio .el-radio {
  margin-top: 0px !important;
}
.step3 {
  margin-top: 50px;
  .info-block {
    display: flex;
    align-items: flex-start;
  }
  .tip-text {
    position: fixed;
    left: 40px;
    top: $navigationHeight + 25px;
    z-index: 51;
    color: #fff;
    h3 {
      font-weight: 300;
      margin: 0;
      font-size: 24px;
    }
  }
}
@media screen and (max-width: 500px) {
  .step3 {
    margin-top: 50px;
    .info-block {
      flex-wrap: wrap;
      margin-bottom: 120px;
      .form-order-detail {
        margin-top: 10px;
        margin-left: 0px;
      }
    }
    .tip-text {
      left: 0;
      top: unset;
      bottom: 20px;
      width: 100%;
      text-align: center;
      h3 {
        font-size: 16px;
      }
    }
  }
}
</style>

<script>
import OrderDetail from '@/components/order/OrderDetail.vue'
export default {
  name: 'Step3',
  components: {
    OrderDetail
  },
  data() {
    return {
      fApi: {},
      value: {},
      options: {
        form: {
          labelPosition: 'left'
        },
        submitBtn: {
          show: false
        },
        onSubmit: (formData) => {
          alert(JSON.stringify(formData))
        }
      },
      rule: [
        {
          type: 'input',
          field: 'accountName',
          title: '會員帳號',
          value: '',
          validate: [
            {
              type: 'string',
              min: 5,
              max: 8,
              required: true,
              message: '最小長度為5，最大長度為8，並不得包含特殊符號',
              pattern: `^[A-Za-z0-9]+$`
            }
          ]
          // style: this.$isMobile() ? 'width: 100%;' : 'width: 41.66667%;'
        },
        {
          type: 'input',
          field: 'address2',
          title: '詳細地址',
          value: '',
          validate: [{ required: true, message: '此欄位必填' }],
          props: {
            placeholder: '請填寫詳細地址 (例如: 台北市中正區忠孝西路X段X號)',
            readonly: true
          }
          // style: this.$isMobile() ? 'width: 100%;' : 'width: 41.66667%;'
        },
        {
          type: 'input',
          field: 'contactName',
          title: '訂購人',
          value: '',
          validate: [{ required: true, message: '此欄位必填' }]
          // style: this.$isMobile() ? 'width: 100%;' : 'width: 41.66667%;'
        },
        {
          type: 'input',
          field: 'contactPhone',
          title: '聯絡電話',
          value: '',
          validate: [{ required: true, message: '此欄位必填' }],
          props: {
            type: 'tel'
          }
          // style: this.$isMobile() ? 'width: 100%;' : 'width: 29.16667%'
        },
        {
          type: 'input',
          field: 'contactEmail',
          title: 'Email',
          value: '',
          validate: [{ required: true, message: '此欄位必填' }],
          props: {
            type: 'email'
          }
          // style: this.$isMobile() ? 'width: 100%;' : 'width: 29.16667%'
        },
        {
          type: 'radio',
          title: '如何得知寶實潔  ',
          field: 'source',
          value: [],
          validate: [],
          options: [
            { value: 1, label: 'FB' },
            { value: 2, label: '搜尋引擎' },
            { value: 3, label: '親友介紹' },
            { value: 4, label: '其他' }
          ],
          props: {
            class: 'source-radio'
          }
        },
        {
          type: 'input',
          field: 'note',
          title: '訂單備註',
          value: '',
          props: {
            type: 'textarea',
            rows: 4
          }
          // style: this.$isMobile() ? 'width: 100%;' : 'width: 29.16667%'
        }
      ],
      currentStep: 3
    }
  },
  methods: {
    login() {
      this.$router.push({ name: 'login' })
    },
    register() {
      this.$router.push({ name: 'register' })
    },
    validateForm() {
      const self = this
      this.fApi.submit(
        (formData, fapi) => {
          self.$store.dispatch('setFormValid', true)
        },
        () => {
          self.$store.dispatch('setFormValid', false)
        }
      )
    },
    saveSessionFormData() {
      const formData = this.fApi.formData()
      window.sessionStorage.setItem(
        'step' + this.currentStep,
        JSON.stringify(formData)
      )

      this.validateForm()
    },
    getSessionFormData() {
      let step1 = JSON.parse(window.sessionStorage.getItem('step1'))
      let sessionData = window.sessionStorage.getItem('step' + this.currentStep)
      if (sessionData) {
        sessionData = JSON.parse(sessionData)
        if (this.$route.params.service == step1.service) {
          this.fApi.coverValue(sessionData)
        }
      }
      if (localStorage.getItem('token') !== null) {
        this.fApi.updateRule('source', { type: 'hidden' })
        this.fApi.updateRule('accountName', { type: 'hidden' })
        this.fApi.setValue({ accountName: this.$store.state.userData.username })
        this.fApi.setValue({ contactName: this.$store.state.userData.name })
        this.fApi.setValue({
          contactPhone: this.$store.state.userData.customer.phone
        })
        this.fApi.setValue({ contactEmail: this.$store.state.userData.email })
      } else {
        this.fApi.updateValidate('source', [
          { required: true, message: '此欄位必填' }
        ])
      }
    }
  },
  created() {
    const titleEl = document.querySelector('head title')
    titleEl.textContent = '預約 ' + this.$route.params.service
  },
  mounted() {
    let step1 = JSON.parse(window.sessionStorage.getItem('step1'))
    this.fApi.setValue({ address2: step1.address2 })
    this.fApi.on('change', this.saveSessionFormData)
    this.$store.dispatch('setFormValid', false)
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.getSessionFormData()
  }
}
</script>
