export default [
    {
        tabName: "關於清潔",
        qa: [
            {
                q: "寶實潔專業清潔公司的服務方式?",
                a: "本公司服務時間為週一到周日09:00-20:00，服務方式採預約制，若有緊急清潔需求，請聯繫客服，將由專人為您處理。"
            },
            {
                q: "每次清潔會有幾個人?",
                a: "會依照您的需求幫您預估最適合的人員以及時間，在最有效率的情況下完成本次清潔服務唷!"
            },
            {
                q: "需要自己準備清潔工具嗎?",
                a: `單次清潔服務，我們皆會準備基礎工具，可另加購加租特殊工具<br/>
                定期清潔服務，我們會提供全新工具包，可另加購加租特殊工具<br/>
                若您希望使用自己的清潔劑或工具可以提前告知唷`
            },
            {
                q: "我想要指定人員清潔，要怎麼做呢?",
                a: `定期客戶可以享有安排指定固定清潔人員為您服務。`
            },
            {
                q: "一般清潔跟大掃除差別是什麼?",
                a: `一般清潔 : 一般清潔會依照您的需求以及現場狀況幫您做清潔時數預估以及規劃，但因每一個場所的實際狀況會有些許差異，在有限的時間內較沒辦法完整清潔所有區域。若您有定期清潔的需求，可以洽詢客服購買時數會比較划算喔!<br/><br/>
                大掃除 : 包含久未清潔場所、入住/退租清潔、年度大掃除；大掃除前會指派督導人員至現場進行免費場勘，能夠較準確預估時數並討論清潔優先順序，依照您的需求進行大掃除服務。`
            },
            {
                q: "什麼是裝潢細清?",
                a: `裝潢細清是現場經過裝潢，現場大型垃圾回收清除後，進行的細部清潔，將空間內所有細節仔細清潔乾淨，完成清潔後，屋主即可入住。`
            },
            {
                q: "若滿意清潔服務，想要轉換定期可以嗎?",
                a: `您可以直接購買時數或是直接洽詢客服，我們將為您詳細說明定期優惠方案。`
            },
            {
                q: "清潔服務可以加時嗎?",
                a: `如果服務當天有加時狀況，可以與清潔人員協調是否可以配合加時，若清潔人員無法配合，則麻煩您再預約下一次清潔服務，並不保證一定能夠加時。`
            },
            {
                q: "一般清潔未包含的項目有那些呢?",
                a: `(1)抬起或移動超過10公斤大型家具或重物。<br/>
                (2)具危險性高處、窗戶外側、外牆清潔。<br/>
                (3)窗戶(只限內部玻璃清潔)/窗簾/抽油煙機(濾油網除外)/瓦斯爐芯細部/家電拆洗。<br/>
                (4)寵物排泄及重度汙染區域清潔。<br/>
                (5)地毯深度清潔及清洗(基本除塵除外)。<br/>
                (6)各式陳年/染色汙點清潔(嚴重水垢/霉垢/殘膠等等)，無法完全去除。<br/>
                (7)消毒/除蟲害(蜂窩/昆蟲等等)。<br/>
                (8)垃圾清運工作(社區有垃圾集中處可協助丟棄，請自備垃圾袋)。<br/>
                (9)有害人體之工作環境(惡臭/高溫)。<br/>
                (10)車庫清潔(基本操除除外)或園藝照護。<br/>
                (11)貴重物品清潔收納。`
            },
        ]
    },
    {
        tabName: "會員問題",
        qa: [
            {
                q: "如何成為會員?",
                a: "不管您一開始是否是會員，只要預約成功後，系統會自動將您設成會員。您的電子郵件即為會員帳號，手機號碼為密碼。"
            },
            {
                q: "成為會員有什麼好處?",
                a: `
                1. 定期收到寶實潔專業清潔寄發的電子報，優惠資訊不遺漏。<br/>
                2. 管理優惠折價券，查看使用情形。<br/>
                3. 可查詢歷史消費紀錄、預定付款狀況。`
            },
            {
                q: "如何查詢修改訂單?",
                a: ""
            }
        ]
    },
    {
        tabName: "付款方式",
        qa: [
            {
                q: "付款方式有那些呢?",
                a: "可以選擇線上刷卡、超商付款或網路/ATM/臨櫃轉帳進行付款。若您有購買時數，也可以在一般清潔預定中使用。"
            },
            {
                q: "可以清潔完成再付款嗎?",
                a: `我們服務流程是「先付款後清潔」。(※ 小部分服務項目可以預付訂金，尾款由清潔人員代收)`
            },
            {
                q: "同次購買的時數可以清潔不同地址嗎?",
                a: "時數優惠是沒有限制清潔地址的，但只限於一般清潔時使用，預約接洽人也只限於購買人唷，感謝您的支持與體諒"
            },
            {
                q: "購買時數可以終止嗎?",
                a: "可以的，如果需要中止，清潔款項會以原價計算後，進行退款。"
            },
            {
                q: "可以開發票嗎?",
                a: "每一筆訂單在付款完成之後都會開立發票，若有需要統一編號或是其他需求都可以提前告知我們唷!"
            },
            {
                q: "想要取消/更改預約該怎麼做呢?",
                a: `
                一般客戶 :須於服務前一日19:00完成付款，即完成預約。<br>
若需更改或取消服務 :<br>
服務前一天18:00前取消/更改，不需收取任何費用。<br>
服務前一天18:00後取消/更改，酌收消費總額30%服務費。<br>
服務當天取消/更改，酌收消費總額50%服務費。`
            }
        ]
    }
]