<template>
  <div class="order-pre">
    <h3>
      預估：清潔時數 {{ predictTime_ }} 小時｜清潔人數 {{ predictCleaner_ }} 人
    </h3>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variable.scss';

.order-pre {
  position: fixed;
  left: 40px;
  bottom: 20px;
  z-index: 51;
  color: #fff;
  h3 {
    font-weight: 300;
    margin: 0;
    font-size: 24px;
  }
}

@media screen and (max-width: 500px) {
  .order-pre {
    left: 0;
    top: unset;
    width: 100%;
    text-align: center;
    h3 {
      font-size: 16px;
    }
  }
}
</style>

<script>
export default {
  name: 'Predict',
  props: ['predictTime', 'predictCleaner'],
  data() {
    return {}
  },
  computed: {
    predictTime_() {
      return this.$props.predictTime
    },
    predictCleaner_() {
      return this.$props.predictCleaner
    }
  },
  watch: {
    predictTime_(newVal, oldVal) {
      sessionStorage.setItem('predictTime', newVal)

      if (newVal > 16) {
        this.$message({
          message: '預估的清潔時數超過十六小時，請與客服聯絡',
          type: 'warning'
        })
      }
    },
    predictCleaner_(newVal, oldVal) {
      sessionStorage.setItem('predictCleaner', newVal)
      this.getOrderPrice()
    }
  },
  methods: {
    getOrderPrice() {
      const pricePerHour = parseInt(
        this.$store.state.serviceDetail.service.price
      )
      if (this.$route.params.service == '購買時數') {
        const order = JSON.parse(sessionStorage.getItem('step1'))

        const orderData = this.$store.state.timeProducts.find(
          (item) => item.id == order.selectedTimeProduct
        )
        this.$store.commit('setFormPrice', orderData.price)
      } else {
        //以小時計算價格
        let predictTime = sessionStorage.getItem('predictTime')
        let total = 0

        if (predictTime) {
          total = pricePerHour * predictTime
        }
        this.$store.commit('setFormPrice', total)
      }
    }
  }
}
</script>
