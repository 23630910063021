<template>
  <div class="about">
    <About class="about-section" />
    <Feature class="section feature-section" />
    <div class="section concept">
      <h1>寶實潔理念<br />BABY CLEAN CONCEPT</h1>
      <p>
        專業交給我，舒適留給您<br />打造潔淨舒適的空間<br />
        從客戶觀點出發，提供完善清潔服務
      </p>
    </div>
  </div>
</template>


<style lang="scss">
@import "@/assets/style/variable.scss";
.about {
  margin-top: $navigationHeight;
  .section {
    //max-width: 80%;
    margin: 0 auto;
    max-width: 1900px;
    margin-top: -5vw;

    h1 {
      display: inline-block;
      color: $primaryColor;
      margin: 100px 0;
      text-align: center;
      font-weight: bold;
      letter-spacing: 5px;
      position: relative;
      z-index: 1;
      left: 50%;
      transform: translateX(-50%) skewX(-6deg);

      &::before {
        content: "";
        width: 10vw;
        background: $primaryColor;
        height: 1px;
        position: absolute;
        left: -15vw;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;
      }

      &::after {
        content: "";
        width: 10vw;
        background: $primaryColor;
        height: 1px;
        position: absolute;
        right: -15vw;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;
      }
    }

    &.w1200 {
      max-width: 1200px;
    }

    &.concept {
      margin: 2.5vw 0;
      p {
        font-size: 20px;
        line-height: 2;
        letter-spacing: 1px;
        text-align: center;
        font-weight: 200;
      }
    }

    &.feature-section {
      margin-top: 2.5vw;
    }
  }
}

@media screen and (max-width: 500px) {
  .about {
    margin-top: $navigationHeight;
    .section {
      //max-width: 80%;
      margin: 0 auto;
      max-width: 1900px;

      h1 {
        transform: translateX(-50%) skewX(-6deg) scale(0.9);
        margin: 50px 0;

        &::before {
          content: none;
        }

        &::after {
          content: none;
        }
      }

      &.w1200 {
        max-width: 1200px;
      }
    }
  }
}
</style>
<script>
import About from "@/components/home/about.vue";
import Feature from "@/components/home/feature.vue";

export default {
  components: {
    About,
    Feature,
  },
};
</script>