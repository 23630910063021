<template>
  <div class="step4">
    <el-row
      :type="$isMobile() ? '' : 'flex'"
      justify="space-between"
      align="flex-start"
    >
      <el-col :span="$isMobile() ? 24 : 12">
        <form-create
          :rule="rule"
          v-model="fApi"
          :option="options"
          :value.sync="value"
          @coupon-click="openCouponDialog"
          @couponcheck-click="checkCoupon"
      /></el-col>
      <el-col :span="$isMobile() ? 24 : 10" class="info-block">
        <OrderDetail
      /></el-col>
    </el-row>
    <div class="terms-checkbox">
      <el-checkbox
        size="medium"
        label="訂單資料和服務條款已確認"
        border
        v-model="termsChecked"
      ></el-checkbox>
      <div>
        <el-button type="text" @click="showTerms = true"
          >查看服務條款</el-button
        >
      </div>
    </div>
    <el-dialog width="80%" :visible.sync="showTerms">
      <div v-html="terms.terms"></div>
    </el-dialog>

    <el-dialog
      title="選擇折扣碼"
      :visible.sync="couponDialogShow"
      width="300px"
    >
      <div class="coupon-list">
        <el-tag type="danger" dark @click="setCouponCode(['cheap500', 500])"
          >折500</el-tag
        >
        <el-tag type="danger" dark @click="setCouponCode(['cheap1000', 1000])"
          >折1000</el-tag
        >
        <el-tag type="danger" dark @click="setCouponCode(['cheap200', 200])"
          >折200</el-tag
        >
        <el-tag type="info" dark @click="setCouponCode([])"
          >不使用折扣碼</el-tag
        >
      </div>
    </el-dialog>

    <div>
      <template v-if="$store.state.ecPay != ''">
        <form
          id="ec-pay-form"
          method="post"
          :target="$store.state.ecPay.target"
          :action="$store.state.ecPay.ServiceURL"
        >
          <input
            type="hidden"
            name="CheckMacValue"
            :value="$store.state.ecPay.szCheckMacValue"
          />
          <input
            type="hidden"
            :name="i"
            :value="param"
            :key="i"
            v-for="(param, i) in Object.keys($store.state.ecPay.arParameters)
              .sort()
              .reduce((obj, key) => {
                obj[key] = $store.state.ecPay.arParameters[key]
                return obj
              }, {})"
          />
        </form>
      </template>

      <div class="tip-text">
        <h3>訂單確認</h3>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variable.scss';
.step4 {
  margin-top: 50px;
  .info-block {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .el-form {
      width: fit-content;
    }
  }

  .result-card {
    .result-list {
      padding-left: 20px;
      .result-item {
        margin-bottom: 12px;
        display: flex;
        justify-content: space-between;
        .price {
          font-weight: bold;
        }
        &.coupon {
          color: coral;
        }
        &:last-child {
          margin: 0;
        }
      }
    }

    .reset-coupon {
      font-size: 13px;
      color: grey;
      cursor: pointer;
      margin-left: 4px;
      border: 1px solid;
      padding: 1px 5px;
      border-radius: 5px;
      &:hover {
        background: grey;
        color: #fff;
      }
    }
  }

  .terms-checkbox {
    padding-top: 40px;
    text-align: center;
    label {
      transform: scale(1.2);
    }
  }

  .coupon-list {
    display: flex;
    flex-direction: column;

    .el-tag {
      margin: 4px 0;
      &:hover {
        opacity: 0.6;
        cursor: pointer;
      }
    }
  }

  .tip-text {
    position: fixed;
    left: 40px;
    top: $navigationHeight + 25px;
    z-index: 51;
    color: #fff;
    h3 {
      font-weight: 300;
      margin: 0;
      font-size: 24px;
    }
  }

  #ec-pay-form {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .step4 {
    margin-top: 50px;
    margin-bottom: 120px;
    .info-block {
      flex-wrap: wrap;
      .form-order-detail {
        margin-top: 10px;
        margin-left: 0px;
      }
    }
    .tip-text {
      left: 0;
      top: unset;
      bottom: 20px;
      width: 100%;
      text-align: center;
      h3 {
        font-size: 16px;
      }
    }
  }
}
</style>

<script>
import terms from '@/info/terms'
import OrderDetail from '@/components/order/OrderDetail.vue'

export default {
  name: 'Step4',
  components: {
    OrderDetail
  },
  data() {
    return {
      terms,
      showTerms: false,
      fApi: {},
      value: {},
      options: {
        form: {
          labelPosition: 'left'
        },
        submitBtn: {
          show: false
        },
        onSubmit: (formData) => {
          alert(JSON.stringify(formData))
        }
      },
      rule: [
        {
          type: 'row',
          children: [
            {
              type: 'col',
              props: {
                // span: this.$isMobile() ? 24 : 16
              },
              children: [
                {
                  type: 'select',
                  field: 'payment_method',
                  title: '付款方式',
                  validate: [{ required: true, message: '此欄位必填' }],
                  effect: {
                    fetch: {
                      action:
                        this.$store.state.formService !== '購買時數'
                          ? process.env.VUE_APP_APIURL +
                            'code-type/payment_method_day'
                          : process.env.VUE_APP_APIURL +
                            'code-type/time_payment_method_day',
                      to: 'options',
                      method: 'GET',
                      parse(res) {
                        const isLogin = window.sessionStorage.getItem('isLogin')
                        if (isLogin !== 'true') {
                          return res.data.codeType.code
                            .filter((e) => e.code !== 'Hours')
                            .map((row) => {
                              return {
                                label: row.description,
                                value: row.code
                              }
                            })
                        } else {
                          return res.data.codeType.code.map((row) => {
                            return {
                              label: row.description,
                              value: row.code
                            }
                          })
                        }
                      }
                    }
                  },
                  props: {
                    placeholder: '請選擇付款方式',
                    multiple: false,
                    id: 'paymentSelect'
                  },
                  emit: ['change'],
                  emitPrefix: 'pay'
                }
              ]
            }
            // {
            //   type: "col",
            //   props: {
            //     span: 6,
            //     offset: this.$isMobile() ? 9 : 2,
            //   },
            //   children: [
            //     {
            //       type: "el-button",
            //       class: "coupon",
            //       props: {
            //         round: true,
            //         type: "warning",
            //       },
            //       children: ["查看折扣碼"],
            //       emit: ["click"],
            //       emitPrefix: "coupon",
            //     },
            //   ],
            // },
          ]
        },
        {
          type: 'row',
          children: [
            {
              type: 'col',
              props: {
                span: this.$isMobile() ? 24 : 16
              },
              children: [
                {
                  type: 'input',
                  field: 'couponCode',
                  title: '折扣碼',
                  value: '',
                  info: '',
                  props: {
                    placeholder: '輸入折扣碼'
                  }
                }
              ]
            },
            {
              type: 'col',
              props: {
                span: 6,
                offset: this.$isMobile() ? 9 : 2
              },
              children: [
                {
                  type: 'el-button',
                  class: 'coupon',
                  field: 'couponBtn',
                  props: {
                    round: true,
                    type: 'warning'
                  },
                  children: ['套用'],
                  emit: ['click'],
                  emitPrefix: 'couponcheck'
                }
              ]
            }
          ]
        },
        {
          type: 'row',
          children: [
            {
              type: 'col',
              props: {
                span: this.$isMobile() ? 24 : 16
              },
              children: [
                {
                  type: 'select',
                  field: 'invoice_type',
                  title: '發票類型',
                  validate: [{ required: true, message: '此欄位必填' }],
                  effect: {
                    fetch: {
                      action: process.env.VUE_APP_APIURL + 'code-type/invoice',
                      to: 'options',
                      method: 'GET',
                      parse(res) {
                        return res.data.codeType.code
                          .filter((e) => e._remove_ === '0')
                          .map((row) => {
                            return {
                              label: row.description,
                              value: row.code
                            }
                          })
                      }
                    }
                  },
                  props: {
                    placeholder: '請選擇發票類型',
                    multiple: false
                  },
                  emit: ['change'],
                  emitPrefix: 'invoice'
                },
                {
                  type: 'hidden',
                  title: '手機載具',
                  field: 'einvoice_carrier_no_mobile',
                  validate: [
                    {
                      required: true,
                      len: 8,
                      message: '請輸入正確手機載具格式'
                    }
                  ],
                  pattern: /^\/[0-9A-Z.+-]{7}$/
                },
                {
                  type: 'hidden',
                  title: '自然人憑證',
                  field: 'einvoice_carrier_no_person',
                  validate: [
                    {
                      required: true,
                      len: 16,
                      message: '請輸入正確自然人憑證格式'
                    }
                  ],
                  pattern: /^[a-zA-Z]{2}[0-9]{14}$/
                },
                {
                  type: 'hidden',
                  title: '發票抬頭',
                  field: 'einvoice_company_title'
                },
                {
                  type: 'hidden',
                  title: '統一編號',
                  field: 'einvoice_taxId',
                  validate: [
                    { required: true, len: 8, message: '請輸入8位數統編格式' }
                  ],
                  props: {
                    minlength: 8,
                    maxlength: 8,
                    type: 'tel'
                  }
                },
                {
                  type: 'hidden',
                  field: 'donate_organization',
                  title: '捐贈單位',
                  validate: [{ required: true, message: '此欄位必填' }],
                  effect: {
                    fetch: {
                      action:
                        process.env.VUE_APP_APIURL +
                        'code-type/donate_organization',
                      to: 'options',
                      method: 'GET',
                      parse(res) {
                        return res.data.codeType.code
                          .filter((e) => e._remove_ === '0')
                          .map((row) => {
                            return {
                              label: row.description,
                              value: row.code
                            }
                          })
                      }
                    }
                  },
                  props: {
                    placeholder: '請選擇捐贈單位',
                    multiple: false
                  },
                  emit: ['change'],
                  emitPrefix: 'donate'
                }
              ]
            }
          ]
        }
      ],
      termsChecked: false,
      couponDialogShow: false,
      coupon: '',
      couponAmount: 0,
      passServices: [
        '營業場所清潔',
        '大掃除清潔',
        '裝潢細清',
        '其他清潔',
        '防疫消毒',
        '除螨清潔',
        '冷氣清潔',
        '地毯清潔',
        '床墊清潔',
        '沙發清潔',
        '洗地打蠟'
      ],
      currentStep: 4
    }
  },
  created() {
    if (this.passServices.indexOf(this.$store.state.formService) > -1) {
      if (this.$store.state.formLastStep > this.$store.state.formStep) {
        this.$store.dispatch('prevFormStep')
      } else {
        this.$store.dispatch('nextFormStep')
      }
    }
    const titleEl = document.querySelector('head title')
    titleEl.textContent = '預約 ' + this.$route.params.service
  },
  watch: {
    termsChecked() {
      this.validateForm()
    }
  },
  computed: {
    cleanerAndHours() {
      if (this.$route.params.service != '購買時數') {
        const cleaner = window.sessionStorage.getItem('predictCleaner')
        const hours = window.sessionStorage.getItem('predictTime')

        return {
          cleaner: cleaner,
          hours: hours
        }
      } else {
        return {
          cleaner: 0,
          hours: 0
        }
      }
    }
  },
  methods: {
    resetCoupon() {
      this.coupon = ''
      this.couponAmount = 0
      this.fApi.setValue({
        couponCode: ''
      })
      this.$message({
        message: '已取消使用折扣',
        type: 'warning'
      })
    },
    checkCoupon() {
      const params = {
        coupon_code: this.value.couponCode
      }

      this.$store.dispatch('showLoading')
      this.axios
        .post(this.$store.state.apiUrl + 'orders/check-coupon-code', params)
        .then((response) => {
          if (response.status == 200) {
            if (response.data.data.correct == false) {
              this.$message({
                message: '優惠券不存在',
                type: 'error'
              })
              return
            } else if (response.data.data.correct == true) {
              this.coupon = response.data.data.CouponCode
              if (this.coupon['type'] == 'fixed') {
                this.couponAmount = parseInt(this.coupon['value'])
              } else if (this.coupon['type'] == 'percent') {
                this.couponAmount =
                  this.$store.state.formPrice * (this.coupon['value'] / 100)
              }
              this.$store.commit('setCouponAmount', this.couponAmount)

              this.$message({
                message: '成功套用優惠券',
                type: 'success'
              })
            }
          }
        })
        .catch((e) => {})
        .then(() => {
          this.$store.dispatch('hideLoading')
        })
    },
    openCouponDialog() {
      this.couponDialogShow = true
    },
    setCouponCode(coupon) {
      this.coupon = coupon
      this.fApi.setValue({ couponCode: coupon[0] })
      this.couponDialogShow = false
    },
    validateForm() {
      const self = this
      const paymentMethod = this.fApi.getValue('payment_method')
      if (paymentMethod === 'Hours' && self.$store.state.userHours <= 0) {
        self.$store.dispatch('setFormValid', false)
      } else {
        this.fApi.nextTick(() => {
          this.fApi.submit(
            (formData, fapi) => {
              self.$store.dispatch('setFormValid', this.termsChecked)
            },
            () => {
              self.$store.dispatch('setFormValid', false)
            }
          )
        })
      }
    },
    saveSessionFormData() {
      const formData = this.fApi.formData()
      window.sessionStorage.setItem(
        'step' + this.currentStep,
        JSON.stringify(formData)
      )
      this.validateForm()
    },
    getSessionFormData() {
      let sessionData = window.sessionStorage.getItem('step' + this.currentStep)

      if (sessionData) {
        sessionData = JSON.parse(sessionData)

        this.fApi.coverValue(sessionData)
      }
    },
    handleInvoiceChange() {
      const invoiceType = this.fApi.getValue('invoice_type')
      // 1 會員載具E-mail
      // 2 三聯式發票
      // 3 捐贈發票
      // 4 載具發票-手機載具
      // 5 載具發票-自然人憑證載具
      switch (invoiceType) {
        case '1':
          this.fApi.updateRule('einvoice_carrier_no_mobile', { type: 'hidden' })
          this.fApi.updateRule('einvoice_carrier_no_person', { type: 'hidden' })
          this.fApi.updateRule('einvoice_company_title', { type: 'hidden' })
          this.fApi.updateRule('einvoice_taxId', { type: 'hidden' })
          this.fApi.updateRule('donate_organization', { type: 'hidden' })
          break
        case '2':
          this.fApi.updateRule('einvoice_carrier_no_mobile', { type: 'hidden' })
          this.fApi.updateRule('einvoice_carrier_no_person', { type: 'hidden' })
          this.fApi.updateRule('einvoice_company_title', { type: 'input' })
          this.fApi.updateRule('einvoice_taxId', { type: 'input' })
          this.fApi.updateRule('donate_organization', { type: 'hidden' })
          break
        case '3':
          this.fApi.updateRule('einvoice_carrier_no_mobile', { type: 'hidden' })
          this.fApi.updateRule('einvoice_carrier_no_person', { type: 'hidden' })
          this.fApi.updateRule('einvoice_company_title', { type: 'hidden' })
          this.fApi.updateRule('einvoice_taxId', { type: 'hidden' })
          this.fApi.updateRule('donate_organization', { type: 'select' })
          break
        case '4':
          this.fApi.updateRule('einvoice_carrier_no_mobile', { type: 'input' })
          this.fApi.updateRule('einvoice_carrier_no_person', { type: 'hidden' })
          this.fApi.updateRule('einvoice_company_title', { type: 'hidden' })
          this.fApi.updateRule('einvoice_taxId', { type: 'hidden' })
          this.fApi.updateRule('donate_organization', { type: 'hidden' })
          break
        case '5':
          this.fApi.updateRule('einvoice_carrier_no_mobile', { type: 'hidden' })
          this.fApi.updateRule('einvoice_carrier_no_person', { type: 'input' })
          this.fApi.updateRule('einvoice_company_title', { type: 'hidden' })
          this.fApi.updateRule('einvoice_taxId', { type: 'hidden' })
          this.fApi.updateRule('donate_organization', { type: 'hidden' })
          break
        default:
      }
    },
    handlePayChange() {
      const invoiceType = this.fApi.getValue('payment_method')
      if (invoiceType === 'Hours') {
        this.fApi.updateRule('couponCode', { type: 'hidden' })
        this.fApi.updateRule('couponBtn', { type: 'hidden' })
        this.fApi.updateRule('invoice_type', { type: 'hidden' })
        this.fApi.updateRule('einvoice_carrier_no_mobile', { type: 'hidden' })
        this.fApi.updateRule('einvoice_carrier_no_person', { type: 'hidden' })
        this.fApi.updateRule('einvoice_company_title', { type: 'hidden' })
        this.fApi.updateRule('einvoice_taxId', { type: 'hidden' })
        this.fApi.updateRule('donate_organization', { type: 'hidden' })
        if (this.$store.state.userHours <= 0) {
          this.$store.dispatch('setFormValid', false)
          if (document.getElementById('error-msg')) {
            document.getElementById('error-msg').style.display = 'block'
          } else {
            const node = document.createElement('div')
            node.setAttribute('id', 'error-msg')
            node.style.cssText =
              'color: #f56c6c;font-size: 12px;line-height: 1;padding-top: 4px;'
            const textNode = document.createTextNode('剩餘時數不足')
            node.appendChild(textNode)
            document.getElementById('paymentSelect').appendChild(node)
          }
        }
      } else {
        if (document.getElementById('error-msg')) {
          document.getElementById('error-msg').style.display = 'none'
        }
        this.fApi.updateRule('couponCode', { type: 'input' })
        this.fApi.updateRule('couponBtn', { type: 'el-button' })
        this.fApi.updateRule('invoice_type', { type: 'select' })
        this.fApi.setValue({
          invoice_type: null
        })
      }
    }
  },
  mounted() {
    const self = this
    self.$store.dispatch('setFormValid', false)
    this.fApi.on('invoice-change', this.handleInvoiceChange)
    this.fApi.on('pay-change', this.handlePayChange)
    this.fApi.on('change', this.saveSessionFormData)
    //this.getSessionFormData();
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
}
</script>
