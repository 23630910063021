export default [
  {
    title: '安心人員保證',
    desc: '居清師皆經過專業訓練、考核通過﹐並具備良民證﹐了解顧客隱私的重要性。',
    icon: require('../assets/home/feature/id-card.gif'),
  },
  {
    title: '客製化服務',
    desc: '不管是居家清潔、辦公室清潔、營業場清潔等等﹐皆可依您的需求及預算﹐量身打造。',
    icon: require('../assets/home/feature/order.gif'),
  },
  {
    title: '環境友善清潔劑',
    desc: '品牌嚴選eco-cert有機驗證之對環境友善的清潔劑﹐讓您安全又安心。',
    icon: require('../assets/home/feature/cleanser.gif'),
  },
  {
    title: '高標準服務',
    desc: '專注細節職人態度，給在乎細節的你，提供最快的效率帶給您乾淨舒適的環境。',
    icon: require('../assets/home/feature/sop.gif'),
  },
];
