<template>
  <div class="order-list">
    <div class="order-list-form">
      <el-table :data="orderListData" style="width: 100%">
        <el-table-column prop="no" label="訂單編號" min-width="16">
          <template slot-scope="scope">
            <a
              target="_blank"
              style="cursor: pointer"
              @click="goDetail(scope.row.no)"
              >{{ scope.row.no
              }}<el-button
                style="border: none"
                type="link"
                icon="el-icon-search"
                size="large"
            /></a>
          </template>
        </el-table-column>

        <el-table-column prop="item" label="服務項目" min-width="10">
        </el-table-column>
        <el-table-column prop="serviceDate" label="服務日期" min-width="10">
        </el-table-column>
        <el-table-column prop="orderDate" label="訂購日期" min-width="10">
        </el-table-column>
        <el-table-column prop="price" label="訂單金額" min-width="10">
          <template slot-scope="scope">
            <span v-if="scope.row.payment_method == 'Hours'"
              >{{ scope.row.hour }}小時</span
            >
            <span v-else>{{ scope.row.price }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="payment_status" label="付款狀態" min-width="10"
          ><template slot-scope="scope">
            <!-- 只有信用卡與ATM的待付款訂單可連結重新付款 -->
            <a
              v-if="
                scope.row.payment_status == '待付款' &&
                (scope.row.payment_method == 'Credit' ||
                  scope.row.payment_method == 'ATM')
              "
              target="_blank"
              style="cursor: pointer"
              @click="goRePay(scope.row.no)"
              >{{ scope.row.payment_status
              }}<i class="el-icon-money el-icon--right"
            /></a>
            <span v-else>{{ scope.row.payment_status }}</span></template
          ></el-table-column
        >
        <el-table-column prop="status" label="訂單狀態" min-width="10">
        </el-table-column>
      </el-table>
    </div>
    <div v-html="ecpayHtml" class="ecpay-html"></div>
  </div>
</template>

<style lang="scss">
@import '@/assets/style/variable.scss';

.ecpay-html {
  display: none;
}

.order-list {
  //margin-top: $navigationHeight;
  height: auto;
  //padding: 5vw 0;
  display: flex;
  align-items: center;

  .order-list-form {
    width: 100%;
    // margin-top: -10%;
    margin: 0 auto;

    h1 {
      margin-bottom: 50px;
      color: $primaryColor;
    }
  }
}

@media screen and (max-width: 500px) {
  .order-list {
    //margin-top: $navigationHeight;
    height: auto;
    //padding: 5vw 0;
    display: flex;
    align-items: center;

    .order-list-form {
      width: 90%;
      // margin-top: -10%;
      margin: 0 auto;

      h1 {
        text-align: center;
      }
    }
  }
}
</style>

<script>
export default {
  name: 'OrderList',
  components: {},
  mounted() {
    this.getOrderList()
  },
  data() {
    return {
      fApi: {},
      value: { field1: '', field2: '' },
      orderListData: [],
      ecpayHtml: '',
      options: {
        form: {
          labelPosition: 'left',
          labelWidth: this.$isMobile() ? '100px' : ''
        },
        submitBtn: {
          show: false
        },
        onSubmit: (formData) => {
          alert(JSON.stringify(formData))
        }
      }
    }
  },
  methods: {
    goDetail(orderId) {
      this.$router.push('/orderDetail/' + orderId)
    },
    goRePay(orderId) {
      const self = this

      this.$store.dispatch('showLoading')
      this.axios
        .post(this.$store.state.apiUrl + 'ordernew/repay', { no: orderId })
        .then((response) => {
          if (response.status == 200) {
            this.ecpayHtml = response.data

            // this.repay = response.data.data;
            setTimeout(() => {
              document.getElementById('__ecpayForm').submit()
            }, 0)
          }
        })
        .catch((e) => {
          this.$message({
            message: '憑證錯誤，請重新登入',
            type: 'error'
          })
        })
        .then(() => {
          setTimeout(() => {
            this.$store.dispatch('hideLoading')
          }, 100)
        })

      // window.location.replace(
      //   this.$store.state.apiUrl + 'ordernew/repay?no=' + orderId
      // )
    },
    getOrderList() {
      this.$store.dispatch('showLoading')
      this.$store.dispatch('getOrdeStatusDay')
      this.$store.dispatch('getPaymentStatus')
      this.axios
        .get(this.$store.state.apiUrl + 'orders/')
        .then((response) => {
          if (response.status == 200) {
            let items = response.data.data.orderNew

            for (let i = 0; i < items.length; i++) {
              let tmp = {}
              tmp['no'] = items[i].no
              tmp['item'] = items[i].title
              tmp['serviceDate'] = items[i].reserve_date
              tmp['orderDate'] = items[i].created_at
              tmp['price'] = items[i].total_amount
              tmp['hour'] = items[i].hour
              tmp['payment_status'] =
                this.$store.state.paymentStatus[items[i].payment_status]
              tmp['note'] = items[i].remark['order_remark']
              tmp['payment_method'] = items[i].payment_method
              tmp['status'] = this.$store.state.orderStatusDay[items[i].status]
              this.orderListData.push(tmp)
            }
          }
        })
        .catch((e) => {
          this.$message({
            message: '憑證錯誤，請重新登入',
            type: 'error'
          })
        })
        .then(() => {
          this.$store.dispatch('hideLoading')
        })
    }
  }
}
</script>
<style>
.el-table__empty-block {
  display: none !important;
}
</style>
