<template>
  <div class="service-detail">
    <el-row :gutter="$isMobile() ? 0 : 25" class="service-info">
      <el-col :xs="24" :md="12">
        <Swipe :slides="serviceDetail.service?.images"
      /></el-col>
      <el-col :xs="24" :md="12">
        <h2 class="service-name">{{ $props.serviceName }}</h2>
        <div
          class="service-content"
          v-html="$props.serviceDetail.service?.simple_description"
        ></div>
        <router-link
          v-if="$props.serviceDetail.online_form === null"
          :to="{
            name: 'order',
            params: { service: $props.orderName, subOrder: $props.subOrder }
          }"
        >
          <el-button type="warning" round class="order-button"
            >立即預約</el-button
          >
        </router-link>
        <el-button v-else type="warning" round class="order-button"
          ><a :href="$props.serviceDetail.online_form?.link"
            >立即預約</a
          ></el-button
        >
      </el-col>
    </el-row>
    <div v-html="$props.serviceDetail.service?.description" />
  </div>
</template>

<style lang="scss">
@import '@/assets/style/variable.scss';
.service-detail {
  .service-info {
    margin-bottom: 40px;
  }

  .service-content {
    color: #333;
    font-weight: 300;
    line-height: 1.5;
    margin-bottom: 40px;
    h2 {
      color: $secondColor;
      font-weight: 500;
    }
    .slash {
      text-decoration: line-through;
      text-decoration-color: $secondColor;
    }
  }
  .order-button a {
    color: #fff;
  }
}
</style>

<style lang="scss" scoped>
@import '@/assets/style/variable.scss';
.service-detail {
  .service-name {
    margin-top: 0;
    font-weight: 500;
    font-size: 36px;
    color: #333;
  }
  .service-image-box {
    img {
      max-width: 100%;
      margin: 35px 0;
      border-radius: 10px;
      box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.15);
      transition: all 0.3s;
      &:hover {
        box-shadow: 10px 20px 20px rgba(0, 0, 0, 0.2);
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .service-detail {
    .service-name {
      margin-top: 50px;
    }
  }
}
</style>

<script>
import Swipe from '@/components/section/swipe.vue'
import meta from '@/info/meta'
export default {
  props: {
    serviceDetail: Object,
    serviceName: String,
    orderName: String,
    subOrder: String
  },
  components: {
    Swipe
  },
  data() {
    return { meta }
  },
  created() {
    const matched = this.$router.currentRoute.matched

    if (matched.length == 2 && this.$router.currentRoute.name == '其他清潔') {
      this.$router.push('/service/other/mite_remove')
    }
  }
}
</script>
