import Vue from 'vue'
import Vuex from 'vuex'
// import createLogger from 'vuex/dist/logger'

import router from '@/router'
import dayjs from 'dayjs'

import axios from 'axios'
import { parse } from '@fortawesome/fontawesome'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const state = () => ({
  loading: false,
  userToken: '',
  userData: {},
  userHours: 0,
  userBookPoint: '',
  formValid: false, //當前表單驗證
  formService: '', //當前表單類型
  formServiceData: '', //當前表單欄位選項資料, 從backendServices取得
  formWorkRange: [],
  additionalWorkRange: [],
  formLastStep: 0, //最後表單步驟
  formStep: 1, //當前表單步驟
  formPrice: 0,
  formType: {},
  apiUrl: process.env.VUE_APP_APIURL,
  imageUrl: process.env.VUE_APP_IMAGEURL,
  backendServices: [],
  timeProducts: [],
  serviceRanges: [],
  serviceTypes: [],
  occupations: [],
  occupationDetail: {},
  serviceDetail: {},
  faqTypes: [],
  faqData: [],
  ecPay: '',
  servicePys: [],
  trafficDetail: {},
  invoiceTypes: [],
  orderNotice: [],
  paymentMethodDay: [],
  orderStatusDay: {},
  paymentStatus: {},
  serviceRangeId: 0, // 選取服務地區的id,
  selectedTime: {}, // 第二步驟選取的日期時段
  specialPrice: 0,
  additionalPrice: 0,
  couponAmount: 0
})

const TEST_TOKEN = `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvY2xlYW4uc3RvcmV3cC5zaXRlXC9hcGlcL2F1dGhcL2xvZ2luIiwiaWF0IjoxNjM1NDMxMDUzLCJleHAiOjE2MzU1MTc0NTMsIm5iZiI6MTYzNTQzMTA1MywianRpIjoibHplV0RvRGViTE9EbXBCZyIsInN1YiI6NTIsInBydiI6Ijg3ZTBhZjFlZjlmZDE1ODEyZmRlYzk3MTUzYTE0ZTBiMDQ3NTQ2YWEifQ.H5R1RAyAmOAbb3YGY6NoqZNcO_-B5509w40ykV6MRBs`

const getters = {}

const actions = {
  register({ commit, state }, payload) {
    commit('showLoading')
    Vue.axios
      .post(state.apiUrl + 'auth/register', payload)
      .then((response) => {
        if (response.status == 200) {
          router.push({ name: 'login' })
          this._vm.$message({
            message: '註冊成功，請登入您的帳號',
            type: 'success'
          })
        }
      })
      .catch((e) => {
        if (e.response.data.status == 401) {
          this._vm.$message({
            message: '註冊失敗，請確認資料已正確填寫',
            type: 'error'
          })
        } else if (e.response.data.status == 'error') {
          let errObj = e.response.data.errors
          let errMsg = ''
          let keys = Object.keys(errObj)
          for (let i = 0; i < keys.length; i++) {
            errMsg += errObj[keys[i]]

            if (i != keys.length - 1) errMsg += '\n'
          }
          this._vm.$message({
            message: errMsg,
            type: 'error'
          })
        } else {
          this._vm.$message({
            message: '目前無法註冊，請稍後再試',
            type: 'error'
          })
        }
      })
      .then(() => {
        commit('hideLoading')
      })
  },
  socialLogin({ commit, state }, payload) {
    commit('showLoading')
    Vue.axios
      .get(state.apiUrl + `auth/login/${payload}`)
      .then((response) => {
        if (response.data.data.socialite_url) {
          window.location.href = response.data.data.socialite_url
        }
      })
      .catch((e) => {
        this._vm.$message({
          message:
            '目前無法使用 ' + payload.toUpperCase() + ' 登入，請稍後再試',
          type: 'error'
        })
      })
      .then(() => {
        commit('hideLoading')
      })
  },
  login({ commit, state }, payload) {
    commit('showLoading')
    Vue.axios
      .post(state.apiUrl + 'auth/login', payload)
      .then((response) => {
        if (response.status == 200) {
          commit('setToken', response.data.data.token)
          localStorage.setItem('token', response.data.data.token)

          router.push({ name: 'home' })
          if (
            state.userToken != '' &&
            Object.keys(state.userData).length === 0
          ) {
            Vue.axios.get(state.apiUrl + 'customers').then((response) => {
              if (response.status == 200) {
                commit('setUserData', response.data.data.user)
                commit('setUserHours', parseInt(response.data.data.hours))
              }
            })
          }
        }
      })
      .catch((e) => {
        if (e.response.data.status == 401) {
          this._vm.$message({
            message: '登入失敗，帳號或密碼錯誤',
            type: 'error'
          })
        } else {
          this._vm.$message({
            message: '目前無法登入，請稍後再試',
            type: 'error'
          })
        }
      })
      .then(() => {
        commit('hideLoading')
      })
  },
  logout({ commit, state }) {
    commit('showLoading')
    Vue.axios
      .post(state.apiUrl + 'auth/logout')
      .then((response) => {
        if (response.status == 200) {
          commit('delToken')
          localStorage.removeItem('token')
          router.push({ name: 'home' })
        }
      })
      .catch((e) => {
        if (e.response.data.status == 401) {
          this._vm.$message({
            message: '登出失敗',
            type: 'error'
          })
        } else {
          this._vm.$message({
            message: '目前無法登出，請稍後再試',
            type: 'error'
          })
        }
      })
      .then(() => {
        commit('hideLoading')
      })
  },

  registerBeforeOrder({ commit, state, dispatch }) {
    const step3 = JSON.parse(sessionStorage.getItem('step3'))
    const registerPayload = {
      username: step3.accountName,
      name: step3.contactName,
      address: step3.address2,
      email: step3.contactEmail,
      phone: step3.contactPhone
    }
    const loginPayload = {
      username: step3.accountName,
      password: step3.contactPhone.substr(step3.contactPhone.length - 6)
    }
    commit('showLoading')
    Vue.axios
      .post(state.apiUrl + 'auth/register', registerPayload)
      .then((response) => {
        if (response.status == 200) {
          Vue.axios
            .post(state.apiUrl + 'auth/login', loginPayload)
            .then((response) => {
              if (response.status == 200) {
                commit('setToken', response.data.data.token)
                localStorage.setItem('token', response.data.data.token)
                Vue.axios.defaults.headers.common['Authorization'] =
                  'Bearer ' + response.data.data.token
                dispatch('checkUserData')
                dispatch('nextFormStep')
              }
            })
        }
      })
      .catch((e) => {
        if (e.response.data.status == 401) {
          this._vm.$message({
            message: '註冊失敗，請確認資料已正確填寫',
            type: 'error'
          })
        } else if (e.response.data.status == 'error') {
          let errObj = e.response.data.errors
          let errMsg = ''
          let keys = Object.keys(errObj)
          for (let i = 0; i < keys.length; i++) {
            errMsg += errObj[keys[i]]

            if (i != keys.length - 1) errMsg += '\n'
          }
          errMsg += '\n請先登入會員'
          this._vm.$message({
            message: errMsg,
            type: 'error'
          })
        } else {
          this._vm.$message({
            message: '目前無法註冊，請稍後再試',
            type: 'error'
          })
        }
      })
      .then(() => {
        commit('hideLoading')
      })
  },
  createOrder({ commit, state }) {
    const step1 = JSON.parse(sessionStorage.getItem('step1'))
    const step2 = JSON.parse(sessionStorage.getItem('step2'))
    const step3 = JSON.parse(sessionStorage.getItem('step3'))
    const step4 = JSON.parse(sessionStorage.getItem('step4'))
    const einvoiceInfo = {}
    einvoiceInfo.einvoice_type = step4.invoice_type
    if (step4.invoice_type === '2') {
      einvoiceInfo.einvoice_company_title = step4.einvoice_company_title
      einvoiceInfo.einvoice_taxId = step4.einvoice_company_title
    } else if (step4.invoice_type === '3') {
      einvoiceInfo.donate_organization = step4.donate_organization
    } else if (step4.invoice_type === '4') {
      einvoiceInfo.einvoice_carrier = 'mobile'
      einvoiceInfo.einvoice_carrier_no = step4.einvoice_carrier_no_mobile
    } else if (step4.invoice_type === '5') {
      einvoiceInfo.einvoice_carrier = 'natural_person'
      einvoiceInfo.einvoice_carrier_no = step4.einvoice_carrier_no_person
    }
    let payload = {
      service_no: state.serviceDetail.service.no,
      address: step1.address2,
      work_py: step1.size,
      work_items: state.formWorkRange,
      additional: state.additionalWorkRange,
      hour: parseInt(sessionStorage.getItem('predictTime')),
      people: parseInt(sessionStorage.getItem('predictCleaner')),
      reserve_date: step2
        ? step2.selectedTime.date.replaceAll('/', '-')
        : '2021-01-01',
      time_period_id: step2.selectedTime.timeId,
      employee_schedule_ids: step2.selectedTime.employee_schedule_ids.splice(
        0,
        parseInt(sessionStorage.getItem('predictCleaner'))
      ),
      order_from: step3.source.toString(),
      notice: step1.notice,
      remark: step3.note,
      coupon_code: step4.couponCode,
      traffic_expenses: state.trafficDetail.traffic_expenses,
      special_price: state.specialPrice,
      total_amount:
        state.formPrice -
        state.couponAmount +
        state.additionalPrice +
        state.specialPrice,
      client_back_url: process.env.VUE_APP_URL + 'finish-order',
      payment_method: step4.payment_method,
      einvoice_data: JSON.stringify(einvoiceInfo)
    }
    const getFormData = (object) =>
      Object.entries(object).reduce((fd, [key, val]) => {
        if (Array.isArray(val)) {
          if (val.length === 0) {
            fd.append(`${key}[]`, '')
          } else {
            val.forEach((v) => {
              fd.append(`${key}[]`, JSON.stringify(v))
            })
          }
        } else {
          fd.append(key, val)
        }
        return fd
      }, new FormData())
    commit('showLoading')
    Vue.axios
      .post(state.apiUrl + 'ordernew', getFormData(payload))
      .then((response) => {
        if (response.status == 200) {
          sessionStorage.clear()
          if (
            step4.payment_method !== 'Remit' &&
            step4.payment_method !== 'Hours'
          ) {
            let PAY_DATA = response.data.data
            state.ecPay = PAY_DATA
            setTimeout(() => {
              //綠界表單送出
              document.getElementById('ec-pay-form').submit()
            }, 500)
          } else {
            router.push({ name: 'finish-order' })
          }
        }
      })
      .catch((e) => {
        console.log(e)
        if (
          e.response.data.message === '預約日期人員已被使用，請重新選擇日期'
        ) {
          state.formStep = 2
          this._vm.$message({
            message: '預約日期人員已被使用，請重新選擇日期',
            type: 'error'
          })
        } else if (e.response.data.message === '優惠卷號輸入錯誤') {
          state.formStep = 4
          this._vm.$message({
            message: '優惠卷號輸入錯誤',
            type: 'error'
          })
        } else {
          this._vm.$message({
            message: '訂單建立失敗，請稍後再試',
            type: 'error'
          })
        }

        commit('hideLoading')
      })
  },
  createHoursOrder({ commit, state, dispatch }) {
    const step1 = JSON.parse(sessionStorage.getItem('step1'))
    const step3 = JSON.parse(sessionStorage.getItem('step3'))
    const step4 = JSON.parse(sessionStorage.getItem('step4'))
    const selectedTimeProduct = state.timeProducts.find(
      (item) => item.id == step1.selectedTimeProduct
    )
    const einvoiceInfo = {}
    einvoiceInfo.einvoice_type = step4.invoice_type
    if (step4.invoice_type === '2') {
      einvoiceInfo.einvoice_company_title = step4.einvoice_company_title
      einvoiceInfo.einvoice_taxId = step4.einvoice_company_title
    } else if (step4.invoice_type === '3') {
      einvoiceInfo.donate_organization = step4.donate_organization
    } else if (step4.invoice_type === '4') {
      einvoiceInfo.einvoice_carrier = 'mobile'
      einvoiceInfo.einvoice_carrier_no = step4.einvoice_carrier_no_mobile
    } else if (step4.invoice_type === '5') {
      einvoiceInfo.einvoice_carrier = 'natural_person'
      einvoiceInfo.einvoice_carrier_no = step4.einvoice_carrier_no_person
    }
    const payload = {
      time_product_id: selectedTimeProduct['id'],
      name: selectedTimeProduct['name'],
      minute: selectedTimeProduct['minute'],
      total_amount: parseInt(selectedTimeProduct['price']),
      client_back_url: process.env.VUE_APP_URL + 'finish-order',
      payment_method: step4.payment_method,
      einvoice_data: JSON.stringify(einvoiceInfo),
      remark: step3.note
    }
    const registerPayload = {
      username: step3.accountName,
      name: step3.contactName,
      address: step3.address2,
      email: step3.contactEmail,
      phone: step3.contactPhone
    }
    const loginPayload = {
      username: step3.accountName,
      password: step3.contactPhone.substr(step3.contactPhone.length - 6)
    }
    commit('showLoading')
    Vue.axios
      .post(state.apiUrl + 'time-orders', payload)
      .then((response) => {
        if (response.status == 200) {
          sessionStorage.clear()
          if (step4.payment_method !== 'Remit') {
            let PAY_DATA = response.data.data
            PAY_DATA.arParameters['MerchantTradeDate'] = dayjs().format(
              'YYYY/MM/DD HH:mm:ss'
            )
            state.ecPay = PAY_DATA
            setTimeout(() => {
              //綠界表單送出
              document.getElementById('ec-pay-form').submit()
            }, 500)
          } else {
            router.push({ name: 'finish-order' })
          }
        }
      })
      .catch((e) => {
        console.log(e)
        this._vm.$message({
          message: '訂單建立失敗，請稍後再試',
          type: 'error'
        })

        commit('hideLoading')
      })
  },
  checkUserData({ commit, state }, force = false) {
    if (state.userToken != '' && (Object.keys(state.userData).length === 0 || force)) {
      Vue.axios.get(state.apiUrl + 'customers').then((response) => {
        if (response.status == 200) {
          commit('setUserData', response.data.data.user)
          commit('setUserHours', parseInt(response.data.data.hours))
        }
      })
    }
  },
  refreshToken({ commit, state }) {
    Vue.axios
      .get(state.apiUrl + 'auth/refresh')
      .then((response) => {
        if (response.status == 200) {
          commit('setToken', response.data.token)
          localStorage.setItem('token', response.data.token)

          Vue.axios.defaults.headers.common['Authorization'] =
            'Bearer ' + response.data.token
        }
      })
      .catch((e) => {
        this._vm.$message({
          message: '憑證已失效，請重新登入。',
          type: 'error'
        })

        commit('delToken')
        localStorage.removeItem('token')
      })
  },
  showLoading({ commit, state }) {
    commit('showLoading')
  },
  hideLoading({ commit, state }) {
    commit('hideLoading')
  },
  setFormValid({ commit, state }, payload) {
    commit('setFormValid', payload)
  },
  setFormService({ commit, state }, payload) {
    commit('setFormService', payload)
  },
  nextFormStep({ commit, state }) {
    commit('setFormLastStep', state.formStep)
    commit('nextFormStep')
  },
  prevFormStep({ commit, state }) {
    commit('setFormLastStep', state.formStep)
    commit('prevFormStep')
  },
  resetFormStep({ commit, state }) {
    commit('resetFormStep')
    commit('setFormLastStep', 0)
  },
  getBackendServices({ commit, state }) {
    commit('showLoading')
    Vue.axios.get(state.apiUrl + 'time-products').then((response) => {
      if (response.status == 200) {
        //commit('showLoading')
        commit('setTimeProducts', response.data.data.time_products)

        //取得服務區域
        Vue.axios
          .get(state.apiUrl + 'code-type/service_range')
          .then((response) => {
            if (response.status == 200) {
              commit('showLoading')
              commit('setServiceRanges', response.data.data.codeType.code)

              //取得serviceTYPE
              Vue.axios
                .get(state.apiUrl + 'code-type/service_type')
                .then((response) => {
                  if (response.status == 200) {
                    commit('setServiceTypes', response.data.data.codeType.code)
                    commit('hideLoading')
                  }
                })
            }
          })
      }
    })
  },
  getOccupations({ commit, state }) {
    commit('showLoading')
    Vue.axios
      .get(state.apiUrl + 'occupations', { params: { take: 100 } })
      .then((response) => {
        if (response.status == 200) {
          commit('setOccupations', response.data.data.occupations)
        }
      })
      .catch(() => {})
      .then(() => {
        commit('hideLoading')
      })
  },
  getServiceDetail({ commit, state }, params) {
    commit('showLoading')
    return Vue.axios
      .get(state.apiUrl + 'services/' + params)
      .then((response) => {
        if (response.status == 200) {
          commit('setServiceDetail', response.data.data)
        }
      })
      .catch(() => {})
      .then(() => {
        commit('hideLoading')
      })
  },
  getServicePys({ commit, state }, params) {
    commit('showLoading')
    return Vue.axios
      .get(state.apiUrl + 'code-type/service_pys')
      .then((response) => {
        if (response.status == 200) {
          commit(
            'setServicePys',
            response.data.data.codeType.code.filter((e) => e._remove_ === '0')
          )
        }
      })
      .catch(() => {})
      .then(() => {
        commit('hideLoading')
      })
  },
  getFaqType({ commit, state }) {
    commit('showLoading')
    Vue.axios.get(state.apiUrl + 'code-type/faq_type').then((response) => {
      if (response.status == 200) {
        commit('setFaqTypes', response.data.data.codeType.code)
        Vue.axios
          .get(state.apiUrl + 'faqs', { params: { take: 100 } })
          .then((response) => {
            if (response.status == 200) {
              commit('setFaqData', response.data.data.faqs)
              commit('hideLoading')
            }
          })
      }
    })
  },
  getInvoiceTypes({ commit, state }) {
    Vue.axios.get(state.apiUrl + 'code-type/invoice_type').then((response) => {
      if (response.status == 200) {
        commit('setInvoiceTypes', response.data.data.codeType.code)
      }
    })
  },
  getOrderNotice({ commit, state }) {
    Vue.axios.get(state.apiUrl + 'code-type/order_notice').then((response) => {
      if (response.status == 200) {
        commit('setOrderNotice', response.data.data.codeType.code)
      }
    })
  },
  getPaymentMethodDay({ commit, state }) {
    Vue.axios
      .get(state.apiUrl + 'code-type/payment_method_day')
      .then((response) => {
        if (response.status == 200) {
          commit('setPaymentMethodDay', response.data.data.codeType.code)
        }
      })
  },
  getOrdeStatusDay({ commit, state }) {
    Vue.axios
      .get(state.apiUrl + 'code-type/order_status_day')
      .then((response) => {
        if (response.status == 200) {
          const tmp = response.data.data.codeType.code
          const status = tmp.reduce(
            (c, v) => ({ ...c, [v.code]: v.description }),
            {}
          )
          commit('setOrderStatusDay', status)
        }
      })
  },
  getPaymentStatus({ commit, state }) {
    Vue.axios
      .get(state.apiUrl + 'code-type/payment_status')
      .then((response) => {
        if (response.status == 200) {
          const tmp = response.data.data.codeType.code
          const status = tmp.reduce(
            (c, v) => ({ ...c, [v.code]: v.description }),
            {}
          )
          commit('setPaymentStatus', status)
        }
      })
  }
}

const mutations = {
  setToken(state, payload) {
    state.userToken = payload
    sessionStorage.setItem('token', payload)
    const now = new Date()
    sessionStorage.setItem('expiry', now.getTime() + 1800000)
  },
  delToken(state) {
    state.userToken = ''
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('expiry')
    state.userData = {}
    state.userHours = 0
    sessionStorage.setItem('isLogin', false)
  },
  setUserData(state, payload) {
    state.userData = payload
    sessionStorage.setItem('isLogin', true)
  },
  setUserHours(state, payload) {
    state.userHours = payload
  },
  showLoading(state) {
    state.loading = true
  },
  hideLoading(state) {
    state.loading = false
  },
  setFormValid(state, payload) {
    state.formValid = payload
  },
  setFormService(state, payload) {
    state.formService = payload.name
    const fService = state.backendServices.find(
      (item) => item.title == state.formService
    )
    if (fService) {
      state.formServiceData = fService
    }
    state.formType = payload.type
  },
  nextFormStep(state) {
    state.formStep++
  },
  prevFormStep(state) {
    state.formStep--
  },
  resetFormStep(state) {
    state.formStep = 1
  },
  setFormStep(state, payload) {
    state.formStep = payload
  },
  setFormLastStep(state, payload) {
    state.formLastStep = payload
  },
  setBackendServices(state, payload) {
    state.backendServices = payload
  },
  setTimeProducts(state, payload) {
    state.timeProducts = payload
  },
  setServiceRanges(state, payload) {
    state.serviceRanges = payload
  },
  setServiceTypes(state, payload) {
    state.serviceTypes = payload
  },
  setFormPrice(state, payload) {
    state.formPrice = payload
  },
  setFormWorkRange(state, payload) {
    state.formWorkRange = payload[0]
    state.additionalWorkRange = payload[1]
  },
  setUserBookPoint(state, payload) {
    state.userBookPoint = payload
  },
  setOccupations(state, payload) {
    state.occupations = payload
  },
  setOccupationDetail(state, payload) {
    state.occupationDetail = payload
  },
  setServiceDetail(state, payload) {
    state.serviceDetail = payload
  },
  setFaqTypes(state, payload) {
    state.faqTypes = payload
  },
  setFaqData(state, payload) {
    state.faqData = payload
  },
  setServicePys(state, payload) {
    state.servicePys = payload
  },
  setTrafficDetail(state, payload) {
    state.trafficDetail = payload
  },
  setInvoiceTypes(state, payload) {
    state.invoiceTypes = payload
  },
  setOrderNotice(state, payload) {
    state.orderNotice = payload
  },
  setPaymentMethodDay(state, payload) {
    state.paymentMethodDay = payload
  },
  setOrderStatusDay(state, payload) {
    state.orderStatusDay = payload
  },
  setPaymentStatus(state, payload) {
    state.paymentStatus = payload
  },
  setServiceRangeId(state, payload) {
    state.serviceRangeId = payload
  },
  setSelectedTime(state, payload) {
    state.selectedTime = payload
  },
  setSpecialPrice(state, payload) {
    state.specialPrice = payload
  },
  setAdditionalPrice(state, payload) {
    state.additionalPrice = payload
  },
  setCouponAmount(state, payload) {
    state.couponAmount = payload
  }
}

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations
  // plugins: debug ? [createLogger()] : []
})
