import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"

// mixins
import { globalMixin } from "./mixins"
Vue.mixin(globalMixin)

//Element UI
import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
import "./assets/style/element-variables.scss"
Vue.use(ElementUI)

//css
import "./assets/style/hambergurs.css"
import "normalize.css/normalize.css"
import "./assets/style/custom.scss"
import "./assets/style/font.scss"
import "./assets/style/icon.scss"

//swiper
import "swiper/swiper-bundle.css"

//axios
import axios from "axios"
import VueAxios from "vue-axios"
Vue.use(VueAxios, axios)

//form
import formCreate from "@form-create/element-ui"
Vue.use(formCreate)

//calendar
import VCalendar from "v-calendar"
Vue.use(VCalendar)

//MobileDetection
import VueMobileDetection from "vue-mobile-detection"
Vue.use(VueMobileDetection)

// affix
import Affix from "vue-affix"
Vue.use(Affix)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app")