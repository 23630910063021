<template>
  <div class="profile">
    <el-row class="mb" :gutter="$isMobile() ? 0 : 50">
      <el-col :span="24" :xs="24">
        <form-create
          :rule="rule"
          v-model="fApi"
          :option="options"
          :value.sync="value"
        />
        <form-create
          :rule="rule2"
          v-model="fApi"
          :option="options"
          :value.sync="value"
        />
      </el-col>
    </el-row>
    <ChangePassword />
  </div>
</template>

<style lang="scss" scoped>
@media screen and (max-width: 500px) {
  .profile {
    padding: 30px;
  }
}
</style>

<script>
import ChangePassword from "@/components/member/changePassword.vue";
import axios from "axios";
import { Message } from "element-ui";

function patchData() {
  const payload = {
    name: document.getElementById("newName").value,
    address: document.getElementById("newAddr").value,
    email: document.getElementById("newEMail").value,
  };

  axios
    .patch(process.env.VUE_APP_APIURL + "customers", payload)
    .then((response) => {
      if (response.status == 200) {
        Message({
          message: "修改成功",
          type: "success",
        });
        location.reload();
      }
    })
    .catch((e) => {
      if (e.response.data.status == 401) {
        Message({
          message: "修改失敗",
          type: "error",
        });
      } else {
        Message({
          message: "目前無法修改，請稍後再試",
          type: "error",
        });
      }
    });
}
export default {
  components: {
    ChangePassword,
  },
  watch: {
    '$store.state.userData': function() {
      this.getCustom();
    },
  },
  mounted() {
    this.getCustom()
    document
      .getElementById("doEditName")
      .addEventListener("click", function (event) {
        patchData();
      });
    document
      .getElementById("doEditAddr")
      .addEventListener("click", function (event) {
        patchData();
      });
    document
      .getElementById("doEditEMail")
      .addEventListener("click", function (event) {
        patchData();
      });
  },
  data() {
    return {
      customerTypeConvert: {
        time: "時數會員",
        one: "一般會員",
        long: "月結會員",
      },
      fApi: {},
      value: {},
      options: {
        form: {
          labelPosition: "left",
        },
        submitBtn: {
          show: false,
        },
      },
      rule2: [
        {
          type: "input",
          field: "customerName",
          title: "會員姓名",
          value: '',
          props: {
            id: "newName",
          },
          children: [
            {
              type: "el-button",
              class: "el-button el-button--success",
              slot: "append",
              inject: true,
              on: {},
              children: ["修改"],
              props: {
                id: "doEditName",
              },
            },
          ],
        },
        {
          type: "input",
          field: "address",
          title: "會員地址",
          value: '',
          props: {
            id: "newAddr",
          },
          children: [
            {
              type: "el-button",
              class: "el-button el-button--success",
              slot: "append",
              inject: true,
              on: {},
              children: ["修改"],
              props: {
                id: "doEditAddr",
              },
            },
          ],
        },
        // {
        //   type: "input",
        //   field: "mobild",
        //   title: "聯絡電話",
        //   value: this.$store.state.userData.customer.phone,
        //   props: {
        //     type: "tel",
        //     maxlength: 10,
        //   },
        //   children: [
        //     {
        //       type: "elbutton",
        //       class: "el-button el-button--success",
        //       slot: "append",
        //       inject: true,
        //       on: {},
        //       children: ["修改"],
        //       props: {
        //         id: "doEditMoblie",
        //       },
        //     },
        //   ],
        // },
        {
          type: "input",
          field: "email",
          title: "E-mail",
          value: '',
          validate: [{ type: "email", message: "無效的email" }],
          props: {
            id: "newEMail",
          },
          children: [
            {
              type: "el-button",
              class: "el-button--success",
              slot: "append",
              inject: true,
              on: {},
              children: ["修改"],
              props: {
                id: "doEditEMail",
              },
            },
          ],
        },
      ],

      rule: [
        {
          type: "input",
          field: "customerType",
          title: "會員類別",

          value: "",

          props: {
            disabled: true,
            id: "cType",
          },
        },
        {
          type: "input",
          field: "customerAccount",
          title: "會員帳號",
          value: '',
          props: {
            disabled: true,
          },
        },
        {
          type: "input",
          field: "phone",
          title: "聯絡電話",
          value: '',
          props: {
            disabled: true,
          },
        },
      ],
    };
  },
  methods: {
    getCustom() {
      if (Object.keys(this.$store.state.userData).length > 0) {
        this.rule.find((r) => r.field === 'customerType').value = this.customerTypeConvert[this.$store.state.userData.customer.type];
        this.rule.find((r) => r.field === 'phone').value = this.$store.state.userData.customer.phone ?? '';
        this.rule.find((r) => r.field === 'customerAccount').value = this.$store.state.userData.username ?? '';
        this.rule2.find((r) => r.field === 'customerName').value = this.$store.state.userData.name ?? '';
        this.rule2.find((r) => r.field === 'address').value = this.$store.state.userData.customer.address ?? '';
        this.rule2.find((r) => r.field === 'email').value = this.$store.state.userData.email ?? '';
      }
    },
  },
};
</script>
