<template>
  <div class="comment">
    <div class="comment-form">
      <h1>服務滿意度回覆</h1>

      <el-descriptions :column="3" title="訂單細節"></el-descriptions>
      <el-table :data="orderData">
        <el-table-column prop="orderId" label="訂單編號"></el-table-column>
        <el-table-column prop="orderDate" label="訂單日期"></el-table-column>
        <el-table-column prop="accountName" label="會員姓名"></el-table-column>
        <el-table-column prop="employees" label="服務人員"></el-table-column>
      </el-table>

      <div class="commentDiv">
        <el-descriptions :column="1" title="整體服務"></el-descriptions>

        <div style="margin-bottom: 30px">
          <div class="radioBtnTitle">人員有無準時到達</div>

          <el-radio-group v-model="onTime">
            <el-radio label="1">有</el-radio>
            <el-radio label="0">無</el-radio>
          </el-radio-group>
        </div>

        <div style="margin-bottom: 30px">
          <div class="radioBtnTitle">
            人員服務前溝通<br />(確認需求 施作順序)
          </div>

          <el-radio-group v-model="communicate">
            <el-radio label="1">有</el-radio>
            <el-radio label="0">無</el-radio>
          </el-radio-group>
        </div>

        <div style="margin-bottom: 30px">
          <div class="radioBtnTitle">有無穿著制服</div>

          <el-radio-group v-model="uniform">
            <el-radio label="1">有</el-radio>
            <el-radio label="0">無</el-radio>
          </el-radio-group>
        </div>

        <div style="margin-bottom: 30px">
          <div class="radioBtnTitle">服務後驗收</div>

          <el-radio-group v-model="afterCheck">
            <el-radio label="1">有</el-radio>
            <el-radio label="0">無</el-radio>
          </el-radio-group>
        </div>

        <div style="margin-bottom: 30px">
          <div class="radioBtnTitle">人員服務態度</div>

          <el-radio-group v-model="attitude">
            <el-radio label="1">良好</el-radio>
            <el-radio label="2">尚可</el-radio>
            <el-radio label="3">待改進</el-radio>
          </el-radio-group>
        </div>

        <el-input
          type="textarea"
          :rows="3"
          placeholder="請輸入評價內容"
          maxlength="50"
          show-word-limit
          class="lg"
          style="width: 100%"
          v-model="content"
        >
        </el-input>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button plain type="success" @click="send()">送出</el-button>
      </span>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/style/variable.scss';

.comment {
  margin-top: $navigationHeight;
  height: auto;
  padding: 5vw 0;
  display: flex;
  align-items: center;
  .comment-form {
    width: 60%;
    margin-top: -10%;
    margin: 0 auto;
    h1 {
      margin-bottom: 50px;
      color: $primaryColor;
    }
  }
}

@media screen and (max-width: 500px) {
  .comment {
    margin-top: $navigationHeight;
    height: auto;
    padding: 5vw 0;
    display: flex;
    align-items: center;
    .comment-form {
      width: 90%;
      margin-top: -10%;
      margin: 0 auto;
      h1 {
        text-align: center;
      }
    }
  }
}
</style>

<script>
import { Message } from 'element-ui'

export default {
  name: 'Comment',
  components: {},
  props: {
    orderId: {
      type: String
    },
    onTime: {
      type: Number
    },
    communicate: {
      type: Number
    },
    uniform: {
      type: Number
    },
    afterCheck: {
      type: Number
    },
    attitude: {
      type: Number
    },
    content: {
      type: String
    }
  },
  data() {
    return {
      fApi: {},
      orderData: [
        {
          orderId: this.orderId,
          orderDate: '',
          accountName: '',
          employees: ''
        }
      ],

      value: { field1: '', field2: '' },
      options: {
        form: {
          labelPosition: 'left',
          labelWidth: this.$isMobile() ? '100px' : ''
        },
        submitBtn: {
          show: false
        },
        onSubmit: (formData) => {
          alert(JSON.stringify(formData))
        }
      }
    }
  },

  mounted() {
    this.$store.dispatch('showLoading')
    const params = {
      no: this.orderId
    }

    this.axios
      .post(this.$store.state.apiUrl + 'ordernew/get-order', params)
      .then((response) => {
        if (response.status == 200) {
          let d = response.data.data.orderNew

          this.orderData[0].orderDate = d.created_at
          this.orderData[0].accountName = d.customer_name
          this.orderData[0].employees = d.employees
        }
      })

      .catch(function (error) {
        Message({
          message: error,
          type: 'error'
        })
      })

      .then(() => {
        this.$store.dispatch('hideLoading')
      })
  },
  methods: {
    send() {
      const self = this

      const params = {
        no: this.orderId,
        on_time: this.onTime,
        communicate: this.communicate,
        attitude: this.attitude,
        uniform: this.uniform,
        check: this.afterCheck,
        content: this.content
      }

      this.$store.dispatch('showLoading')

      this.axios
        .post(
          this.$store.state.apiUrl + 'ordernew/submit-order-comment',
          params
        )
        .then((response) => {
          if (response.status == 200) {
            Message({
              message: '評價己送出',
              type: 'success'
            })
            this.$router.push('/')
          }
        })

        .catch(function (error) {
          Message({
            message: error,
            type: 'error'
          })
        })

        .then(() => {
          this.$store.dispatch('hideLoading')
        })
    }
  }
}
</script>
<style scoped>
.el-descriptions__body
  .el-descriptions__table
  .el-descriptions-item__cell
  .el-descriptions-item__container {
  align-items: center;
}
.el-radio-group .el-radio {
  margin-top: 0px;
  padding-left: 20px;
  width: 40%;
}

.el-radio-button__inner,
.el-radio-group {
  display: flex;
}

.radioBtnTitle {
  float: left;
  color: #606266;
  font-size: 14px;
  width: 60%;
}
.commentDiv {
  margin: 20px 0px;
  width: 40%;
}
@media screen and (max-width: 500px) {
  .radioBtnTitle {
    width: 35%;
  }
  .commentDiv {
    margin: 20px 0px;
    width: 100%;
  }
}
</style>
