import store from '@/store'

export const globalMixin = {
  methods: {
    getServiceTypeByName(name) {
      const service = store.state.serviceTypes.find(
        (item) => item.description == name
      )
      return service ? service.code : null
    }
  }
}
