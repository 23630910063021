<template>
  <div class="forget-password">
    <div class="forget-password-form">
      <h1>忘記密碼</h1>

      <form-create
        :rule="rule"
        v-model="fApi"
        :option="options"
        :value.sync="value"
      />

      <el-row type="flex" justify="center">
        <el-button plain type="info" @click="$router.go(-1)">返回</el-button>
        <el-button plain type="success" @click="send()">送出</el-button>
      </el-row>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/style/variable.scss";

.forget-password {
  margin-top: $navigationHeight;
  height: auto;
  padding: 5vw 0;
  display: flex;
  align-items: center;
  .forget-password-form {
    width: 30%;
    margin-top: -10%;
    margin: 0 auto;
    h1 {
      margin-bottom: 50px;
      color: $primaryColor;
    }
  }
}

@media screen and (max-width: 500px) {
  .forget-password {
    margin-top: $navigationHeight;
    height: auto;
    padding: 5vw 0;
    display: flex;
    align-items: center;
    .forget-password-form {
      width: 90%;
      margin-top: -10%;
      margin: 0 auto;
      h1 {
        text-align: center;
      }
    }
  }
}
</style>

<script>
export default {
  name: "Register",
  components: {},
  data() {
    return {
      fApi: {},
      value: { field1: "", field2: "" },
      options: {
        form: {
          labelPosition: "left",
          labelWidth: this.$isMobile() ? "100px" : "",
        },
        submitBtn: {
          show: false,
        },
        onSubmit: (formData) => {
          alert(JSON.stringify(formData));
        },
      },
      rule: [
        {
          type: "input",
          field: "email",
          title: "Email",
          value: "",
          props: {
            placeholder: "請輸入帳號綁定的Email",
            clearable: true,
            size: "large",
            type: "email",
          },
        },
      ],
    };
  },
  methods: {
    send() {
      const self = this;
      this.fApi.submit(
        (formData, fapi) => {
          const params = {
            email: formData.email,
            client_back_url:
              process.env.VUE_APP_URL + "reset-password",
          };

          this.$store.dispatch("showLoading");
          this.axios
            .post(
              this.$store.state.apiUrl +
                "auth/forgot-password/send-verify-code",
              params
            )
            .then((response) => {
              if (response.status == 200) {
                self.$message({
                  message: "驗證信已經發送到您的信箱，請前往查看。",
                  type: "success",
                });
              }
            })
            .catch((e) => {
              let response = e.response.data;
              if (response.status == 102102) {
                self.$message({
                  message: "重設密碼信件已發送過，請稍後再試。",
                  type:'error'
                });
              } else if (response.status == 102003) {
                self.$message({
                  message: "沒有符合的Email帳號",
                  type:'error'
                });
              }
            })
            .then(() => {
              this.$store.dispatch("hideLoading");
            });
        },
        () => {
          this.$message({
            message: "請輸入Email",
            type: "error",
          });
        }
      );
    },
  },
};
</script>
