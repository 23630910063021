<template>
  <div class="section section3 w1200" id="step">
    <h1>預約流程</h1>
    <div class="step">
      <template>
        <div
          class="step-item-content"
          v-for="feature in stepList"
          :key="feature.title"
        >
          <div class="step-item">
            <div class="step-icon">
              <img :src="feature.icon" alt="" srcset="" />
            </div>
            <div>
              <div class="step-title" v-html="feature.title"></div>
              <div class="step-desc" v-html="feature.desc"></div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import '@/assets/style/variable.scss';
.section3 {
  .step {
    position: relative;
    width: 100vw;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2vw;
    left: 50%;
    padding: 0 15%;
    transform: translateX(-50%);
    background: rgba(96, 145, 177, 0.03);
    .step-item-content {
      height: auto;
      box-sizing: border-box;
      position: relative;
      .step-item {
        height: auto;
        padding: 30px;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        box-sizing: border-box;
        .step-icon {
          height: 5vw;
          background-size: cover;
          margin: 0 auto;
          img {
            height: 100%;
          }
        }
        .step-title {
          text-align: center;
          font-size: 28px;
          padding: 20px 0;
          color: $primaryTextColor;
        }
        .step-desc {
          text-align: center;
          font-size: 16px;
          padding: 20px 0;
          color: #333;
          line-height: 1.5;
        }
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .section3 {
    .step {
      grid-template-columns: repeat(1, 1fr);
      gap: 0;
      .step-item-content {
        .step-item {
          padding: 30px;
          flex-direction: row;
          align-items: flex-start;
          .step-icon {
            height: 70px;
            background-size: cover;
            margin: 0 auto;
            img {
              height: 100%;
            }
          }
          .step-title,
          .step-desc {
            padding: 0;
            text-align: left;
            margin-left: 10px;
          }
        }
      }
    }
  }
}
</style>
<script>
import stepList from '@/info/stepList'
export default {
  name: 'Step',
  data() {
    return {
      stepList
    }
  },
  mounted() {}
}
</script>
