<template>
  <div class="order-form">
    <p v-if="userHours > 0" class="user-hours">剩餘時數：{{ userHours }}小時</p>
    <form-create
      :rule="rule"
      v-model="fApi"
      :option="options"
      :value.sync="value"
    />
    <Predict :predictTime="predictTime" :predictCleaner="predictCleaner" />
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variable.scss';
.user-hours {
  font-size: 14px;
  color: #606266;
  margin-bottom: 22px;
}
.order-form {
  margin-top: 50px;
  width: 100%;
}
@media screen and (max-width: 500px) {
  .order-form {
    margin-bottom: 100px;
  }
}
</style>

<script>
import Predict from '@/components/order/step1service/Predict.vue'

export default {
  name: 'Step1Service1',
  components: {
    Predict
  },
  props: {
    service: String
  },
  data() {
    return {
      fApi: {},
      value: {},
      servicePys: [],
      options: {
        form: {
          labelPosition: 'left'
        },
        submitBtn: {
          show: false
        },
        onSubmit: (formData) => {
          alert(JSON.stringify(formData))
        }
      },
      rule: [
        {
          type: 'input',
          title: '詳細地址',
          field: 'address2',
          validate: [{ required: true, message: '此欄位必填' }],
          props: {
            placeholder: '請填寫詳細地址 (例如: 台北市中正區忠孝西路X段X號)'
          },
          col: {
            span: this.$isMobile() ? 24 : 12
          }
        },
        {
          type: 'el-alert',
          props: {
            type: 'info',
            title: '若沒有找到您所需的服務地址，請聯繫客服。',
            closable: false
          },
          style: 'margin-bottom: 22px;'
        },
        {
          type: 'checkbox',
          field: 'notice',
          title: '',
          value: [],
          effect: {
            fetch: {
              action: process.env.VUE_APP_APIURL + 'code-type/order_notice',
              to: 'options',
              method: 'GET',
              parse(res) {
                return res.data.codeType.code.map((row) => {
                  return {
                    label: row.description,
                    value: row.code
                  }
                })
              }
            }
          }
        },
        {
          type: 'input',
          field: 'service',
          title: '服務項目',
          value: this.$props.service,
          props: {
            placeholder: '',
            size: 'large',
            disabled: true
          },
          col: {
            span: this.$isMobile() ? 24 : 7
          }
        }
      ],
      areaPredictTime: [],
      predictTime: 0,
      predictCleaner: 0,
      currentStep: 1
    }
  },
  computed: {
    userHours() {
      return this.$store.state.userHours
    },
    serviceDetail() {
      return this.$store.state.serviceDetail
    },
    orderNoticeOptions() {
      return this.$store.state.orderNotice.map((e) => ({
        value: e.code,
        label: e.description
      }))
    }
  },
  watch: {
    serviceDetail() {
      if (!this.fApi.getRule('size')) {
        this.addRule()
      }
    }
  },
  created() {
    const titleEl = document.querySelector('head title')
    titleEl.textContent = '預約 ' + this.$route.params.service
    this.servicePys = this.$store.state.servicePys
      .map((row) => {
        return {
          label: row.description,
          value: row.code
        }
      })
  },
  methods: {
    predictData() {
      let newPredictTime = 0
      let newPredictCleaner = 0
      const workRanges = [],
        additionalWorkRanges = []
      let additionalPrice = 0
      const formData = this.fApi.formData()
      let workItems = []
      if (this.fApi.getValue('size')) {
        workItems = this.serviceDetail.work_items.find(
          (e) => e.py === this.fApi.getValue('size')
        ).items
      } else {
        let sessionData = window.sessionStorage.getItem(
          'step' + this.currentStep
        )
        sessionData = JSON.parse(sessionData)
        if (sessionData.size) {
          workItems = this.serviceDetail.work_items.find(
            (e) => e.py === sessionData.size
          ).items
        }
      }
      workItems.forEach((e) => {
        workRanges.push({
          number: formData[e.item],
          name: e.item,
          minute: e.minute
        })
        if (formData[e.item] > 0) {
          newPredictTime += formData[e.item] * e.minute
        }
      })
      this.serviceDetail.service.work_range.forEach((e) => {
        if (formData.other.includes(e.item)) {
          additionalWorkRanges.push({
            name: e.item,
            check: '1',
            price: e.price,
            hours: e.hours
          })
          additionalPrice += e.price
        } else {
          additionalWorkRanges.push({
            name: e.item,
            check: '0',
            price: e.price,
            hours: e.hours
          })
        }
      })
      this.$store.commit('setFormWorkRange', [workRanges, additionalWorkRanges])
      this.$store.commit('setAdditionalPrice', additionalPrice)
      newPredictTime = newPredictTime / 60
      if (newPredictTime > 0 && newPredictTime <= 3) {
        newPredictTime = 3
        newPredictCleaner = 1
      } else if (newPredictTime > 3 && newPredictTime <= 4) {
        newPredictTime = 4
        newPredictCleaner = 1
      } else if (newPredictTime > 4 && newPredictTime <= 6) {
        newPredictTime = 6
        newPredictCleaner = 2
      } else if (newPredictTime > 6 && newPredictTime <= 8) {
        newPredictTime = 8
        newPredictCleaner = 2
      } else if (newPredictTime > 8 && newPredictTime <= 12) {
        newPredictTime = 12
        newPredictCleaner = 2
      } else if (newPredictTime > 12 && newPredictTime <= 14) {
        newPredictTime = 14
        newPredictCleaner = 2
      } else if (newPredictTime > 14 && newPredictTime <= 16) {
        newPredictTime = 16
        newPredictCleaner = 2
      }
      this.predictCleaner = newPredictCleaner
      this.predictTime = newPredictTime
    },
    saveSessionFormData() {
      const formData = this.fApi.formData()
      window.sessionStorage.setItem('step1', JSON.stringify(formData))
      this.validateForm()
    },
    getSessionFormData() {
      let sessionData = window.sessionStorage.getItem('step' + this.currentStep)
      if (sessionData) {
        sessionData = JSON.parse(sessionData)

        if (sessionData.service == this.fApi.getValue('service')) {
          this.fApi.coverValue(sessionData)
          this.predictData()
        }
      }

      if (localStorage.getItem('token') !== null) {
        this.fApi.setValue({
          address2: this.$store.state.userData.customer.address
        })
      }
    },
    validateForm() {
      const self = this
      this.fApi.submit(
        (formData, fapi) => {
          self.$store.dispatch('setFormValid', true)
        },
        () => {
          self.$store.dispatch('setFormValid', false)
        }
      )
    },
    addRule() {
      if (this.serviceDetail.service && this.serviceDetail.work_items) {
        this.fApi.on('change', this.saveSessionFormData)
        this.fApi.on('area-change', this.predictData)
        this.fApi.on('size-change', this.predictData)
        this.fApi.on('other-change', this.predictData)
        this.fApi.append(
          {
            type: 'checkbox',
            title: '加購項目',
            field: 'other',
            value: [],
            emit: ['change'],
            emitPrefix: 'other',
            options: this.serviceDetail.service.work_range.map((item) => ({
              value: item.item,
              label: item.item
            }))
          },
          'service'
        )
        this.fApi.append(
          {
            type: 'col',
            field: 'work_range',
            children: this.serviceDetail.work_items[0].items.map((item) => ({
              type: 'InputNumber',
              field: item.item,
              title: item.item,
              value: 0,
              props: {
                precision: 0,
                min: 0
              },
              col: {
                span: this.$isMobile() ? 24 : 12
              },
              emit: ['change'],
              emitPrefix: 'area',
              suffix: {
                type: 'span',
                children: [
                  `${item.description === null ? '' : item.description}`
                ],
                style:
                  'margin-left: 5px;color: #606266; display: inline-block;word-break: break-all;'
              }
            }))
          },
          'service'
        )
        this.fApi.append(
          {
            type: 'el-col',
            children: [
              {
                type: 'el-divider',
                children: ['服務範圍']
              }
            ]
          },
          'service'
        )
        this.fApi.prepend(
          {
            type: 'select',
            field: 'size',
            title: '坪數',
            validate: [{ required: true, message: '此欄位必填' }],
            options: this.servicePys,
            props: {
              placeholder: '選擇坪數',
              multiple: false
            },
            emit: ['change'],
            emitPrefix: 'size'
          },
          'notice'
        )
      }
    }
  },
  async mounted() {
    if (this.serviceDetail) {
      await this.addRule()
    }
    await this.getSessionFormData()
  }
}
</script>
