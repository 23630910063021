<template>
  <div class="order-form">
    <p v-if="userHours > 0" class="user-hours">剩餘時數：{{ userHours }}小時</p>
    <form-create
      :rule="rule"
      v-model="fApi"
      :option="options"
      :value.sync="value"
    />
    <Predict :predictTime="predictTime" :predictCleaner="predictCleaner" />
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variable.scss';
.user-hours {
  font-size: 14px;
  color: #606266;
  margin-bottom: 22px;
}
.order-form {
  margin-top: 50px;
  width: 100%;
}
</style>
<style lang="scss">
@import '@/assets/style/variable.scss';
.el-radio-group .el-radio {
  margin-top: 0px !important;
}

@media screen and (max-width: 500px) {
  .el-radio-group {
    .el-radio {
      display: flex;
      margin-bottom: 10px;
      .el-radio__label {
        white-space: break-spaces;
      }
    }
  }
}
</style>
<script>
import Predict from '@/components/order/step1service/Predict.vue'

export default {
  name: 'Step1Service8',
  components: {
    Predict
  },
  props: {
    service: String
  },
  data() {
    return {
      fApi: {},
      value: {},
      options: {
        form: {
          labelPosition: 'left'
        },
        submitBtn: {
          show: false
        },
        onSubmit: (formData) => {
          alert(JSON.stringify(formData))
        }
      },
      rule: [
        {
          type: 'input',
          field: 'service',
          title: '服務項目',
          value: this.$props.service,
          props: {
            placeholder: '',
            size: 'large',
            disabled: true
          },
          col: {
            span: this.$isMobile() ? 24 : 7
          }
        },
        {
          type: 'radio',
          title: '時數',
          field: 'selectedTimeProduct',
          value: '',
          props: {
            placeholder: '選擇購買時數'
          },
          validate: [{ required: true, message: '此欄位必填' }],
          options: this.$store.state.timeProducts.map((item) => ({
            value: item.id,
            label: item.name
          }))
        }
      ],
      predictTime: 0,
      predictCleaner: 0,
      currentStep: 1
    }
  },
  computed: {
    userHours() {
      return this.$store.state.userHours
    }
  },
  created() {
    const titleEl = document.querySelector('head title')
    titleEl.textContent = '預約 ' + this.$route.params.service
  },
  methods: {
    predictData() {
      const formData = this.fApi.formData()

      let newPredictTime = 0
      let newPredictCleaner = 0

      for (const key in formData) {
        if (Object.hasOwnProperty.call(formData, key)) {
          if (key.match('area')) {
            const areaName = key,
              areaAmount = formData[key]

            if (areaAmount > 0) {
              const areaTime = this.getAreaTime(areaName)

              newPredictTime += areaTime * areaAmount
            }
          }
        }
      }

      newPredictTime = newPredictTime / 60
      newPredictTime = newPredictTime.toFixed(1)

      if (newPredictTime > 0 && newPredictTime <= 4) {
        newPredictCleaner = 1
      } else if (newPredictTime > 4) {
        newPredictCleaner = 2
      } else {
        newPredictCleaner = 0
      }

      this.predictCleaner = newPredictCleaner
      this.predictTime = newPredictTime
    },
    getAreaTime(area) {
      const areaData = this.areaPredictTime.find((item) => item.area == area)
      return areaData['time']
    },
    saveSessionFormData() {
      const formData = this.fApi.formData()
      window.sessionStorage.setItem(
        'step' + this.currentStep,
        JSON.stringify(formData)
      )
      this.validateForm()
    },
    getSessionFormData() {
      let sessionData = window.sessionStorage.getItem('step' + this.currentStep)

      if (sessionData) {
        sessionData = JSON.parse(sessionData)

        if (sessionData.service == this.fApi.getValue('service')) {
          this.fApi.coverValue(sessionData)
        }
      }
    },
    validateForm() {
      const self = this
      this.fApi.submit(
        (formData, fapi) => {
          self.$store.dispatch('setFormValid', true)
        },
        () => {
          self.$store.dispatch('setFormValid', false)
        }
      )
    }
  },
  mounted() {
    this.fApi.on('change', this.saveSessionFormData)
    this.getSessionFormData()
  }
}
</script>
