<template>
  <div class="login">
    <div class="login-form">
      <h1>會員登入</h1>
      <div class="social-login" style="text-align: center">
        <!-- <el-button
          plain
          icon="fab fa-line"
          @click="$store.dispatch('socialLogin', 'line')"
          >LINE</el-button
        > -->
        <el-button
          plain
          icon="fab fa-facebook"
          @click="$store.dispatch('socialLogin', 'facebook')"
          >Facebook</el-button
        >
        <el-button
          plain
          icon="fab fa-google"
          @click="$store.dispatch('socialLogin', 'google')"
        >
          <span>Google</span>
        </el-button>
      </div>

      <form-create
        :rule="rule"
        v-model="fApi"
        :option="options"
        :value.sync="value"
      />

      <el-divider></el-divider>

      <el-row type="flex" justify="space-between">
        <router-link to="/forget-password">
          <el-button plain type="text">忘記密碼？</el-button>
        </router-link>
        <router-link to="/forget-account">
          <el-button plain type="text">忘記帳號？</el-button>
        </router-link>
        <div>
          <router-link to="/register" class="mr">
            <el-button plain>註冊</el-button>
          </router-link>
          <el-button plain type="primary" @click="send()">登入</el-button>
        </div>
      </el-row>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/style/variable.scss';

.login {
  margin-top: $navigationHeight;
  height: auto;
  padding: 5vw 0;
  display: flex;
  align-items: center;
  .login-form {
    min-width: 340px;
    width: 30%;
    margin-top: -10%;
    margin: 0 auto;
    h1 {
      margin-bottom: 50px;
      color: $primaryColor;
    }
    .social-login {
      width: 100%;
      margin-bottom: 50px;
      i {
        margin-right: 8px;
      }
      button {
        padding-right: 0;
        padding-left: 0;
        width: 30%;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .login {
    .login-form {
      h1 {
        text-align: center;
      }
    }
  }
}
</style>

<script>
export default {
  name: 'Login',
  components: {},
  data() {
    return {
      fApi: {},
      value: {},
      options: {
        form: {
          labelPosition: 'left'
        },
        submitBtn: {
          show: false
        },
        onSubmit: (formData) => {
          alert(JSON.stringify(formData))
        }
      },
      rule: [
        {
          type: 'input',
          field: 'username',
          title: '帳號',
          value: '',
          validate: [{ type: 'string', required: true, message: '請輸入帳號' }],
          props: {
            placeholder: '輸入帳號',
            clearable: true
          }
        },
        {
          type: 'input',
          field: 'password',
          title: '密碼',
          value: '',
          validate: [{ type: 'string', required: true, message: '請輸入密碼' }],
          props: {
            placeholder: '輸入密碼',
            clearable: true,
            type: 'password'
          }
        }
        // {
        //   type: "input",
        //   field: "captcha",
        //   title: "驗證碼",
        //   value: "",
        //   validate: [
        //     { type: "string", required: true, message: "請輸入驗證碼" },
        //   ],
        //   col: {
        //     span: this.$isMobile() ? 16 : 14,
        //   },
        //   props: {
        //     clearable: true,
        //   },
        // },
      ]
    }
  },
  methods: {
    send() {
      this.fApi.submit(
        (formData, fapi) => {
          this.$store.dispatch('login', formData)
        },
        () => {}
      )
    }
  }
}
</script>
