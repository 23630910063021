<template>
  <div id="about" class="section section5 w1200">
    <h1>關於寶實潔 <br v-if="$isMobile()" />BABY CLEAN</h1>
    <div class="about">
      <div class="about-content">
        <p>
          寶實潔提供居家、辦公室、社區、營業場所、賣場、裝潢後等清潔相關服務，希望透過網路的便利性，整合生活中各種息息相關的服務，打造一個便利、專業、可信賴的全方位服務平台。
          <br /><br />
          選擇寶實潔，居清師擁有完整職前教育訓練，專注細節的職人態度，讓我們為您打造整潔又舒適的生活環境，我們會是您安心的最佳選擇。
        </p>
      </div>
      <div class="about-img">
        <img src="@/assets/home/about/1.png" alt="" srcset="" />
      </div>
    </div>
    <div class="about-qa" id="qa">
      <el-tabs v-model="activeQaTab" :stretch="true">
        <el-tab-pane
          v-for="tab in faqTypes.filter((e) => e._remove_ === '0')"
          :key="tab.code"
          :label="tab.description"
          :name="tab.code"
        >
          <el-collapse>
            <el-collapse-item
              v-for="qa in faqData.filter(
                (e) => e.type === activeQaTab && e.enabled === 1
              )"
              :key="qa.id"
            >
              <template slot="title">
                <h3 v-html="qa.title"></h3>
              </template>
              <div v-html="qa.content"></div>
            </el-collapse-item>
          </el-collapse>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import '@/assets/style/variable.scss';
.section5 {
  padding-top: 5vw;
  .about {
    max-width: 1200px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
    margin: 0 auto;
    .about-content {
      h1 {
        text-align: left;
        margin-top: 0;
        margin-bottom: 10px;
      }
      p {
        font-size: 18px;
        color: $blackTextColor;
        font-weight: 100;
        line-height: 1.5;
      }
    }
    .about-img {
      img {
        width: 100%;
      }
    }
  }
  .about-qa {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 50px;
    h3 {
      font-size: 18px;
      font-weight: 500;
    }
  }
}
@media screen and (max-width: 500px) {
  @import '@/assets/style/variable.scss';
  .section5 {
    padding-top: 5vw;
    .about {
      max-width: 1200px;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 50px;
      margin: 0 auto;
      padding: 20px;
      .about-content {
        h1 {
          text-align: left;
          margin-top: 0;
          margin-bottom: 10px;
        }
        p {
          font-size: 14px;
          color: $blackTextColor;
          font-weight: 100;
          line-height: 1.5;
        }
      }
      .about-img {
        img {
          width: 100%;
        }
      }
    }
    .about-qa {
      margin-top: 20px;
      padding: 20px;
      h3 {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
</style>
<script>
import aboutList from '@/info/aboutList'

export default {
  name: 'About',
  data() {
    return {
      aboutList,
      openedQA: []
    }
  },
  computed: {
    faqTypes() {
      return this.$store.state.faqTypes
    },
    faqData() {
      return this.$store.state.faqData
    },
    activeQaTab() {
      return this.$store.state.faqTypes.filter((e) => e._remove_ === '0')[0]
        .code
    }
  },
  created() {},
  mounted() {},
  methods: {}
}
</script>
