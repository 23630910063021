<template>
  <div class="step1">
    <el-alert
      class="step1-tip"
      title="預約提醒"
      type="info"
      show-icon
      :closable="false"
      >若您不知道如何填寫評估資料，請加入 寶實潔專業清潔官方 LINE@ (<a
        href="https://page.line.me/919djnmr?openQrModal=true"
        >連結</a
      >)，會有專人協助您預約喔～</el-alert
    >
    <Step1Service1
      :key="$store.state.formService"
      :service="$props.service"
      v-if="$props.service == '居家清潔'"
    />
    <Step1Service2
      :key="$store.state.formService"
      :service="$props.service"
      v-else-if="$props.service == '辦公室清潔'"
    />
    <Step1Service8
      :key="$store.state.formService"
      :service="$props.service"
      v-else-if="$props.service == '購買時數'"
    />
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variable.scss';
.step1 {
  .step1-tip {
    margin: 20px 0;
  }
}
</style>

<script>
import Step1Service1 from '@/components/order/step1service/Service1.vue'
import Step1Service2 from '@/components/order/step1service/Service2.vue'
import Step1Service8 from '@/components/order/step1service/Service8.vue'

export default {
  name: 'Step1',
  props: {
    service: String,
    subOrder: String
  },
  components: {
    Step1Service1,
    Step1Service2,
    Step1Service8
  },
  data() {
    return {}
  },
  mounted() {
    this.$store.dispatch('checkUserData')
  }
}
</script>
