var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"calender-block"},[_c('p',[_c('el-alert',{staticClass:"mt mb",attrs:{"title":"每月15日可預約下個月","type":"warning","center":"","show-icon":""}})],1),_c('v-calendar',{ref:"calendar",staticClass:"custom-calendar",attrs:{"masks":_vm.masks,"attributes":_vm.attributes,"min-date":new Date(),"max-date":_vm.lastDay,"is-expanded":""},scopedSlots:_vm._u([{key:"day-content",fn:function({ day, attributes }){return [_c('div',{staticClass:"day-block"},[(_vm.dayjs(day.date).unix() > _vm.dayjs().unix())?_c('div',{staticClass:"day-d day-prev",on:{"click":function($event){return _vm.resetAvalible()}}},[_vm._v(" "+_vm._s(day.day)+" ")]):_c('div',{staticClass:"day-d day-normal",on:{"click":function($event){return _vm.resetAvalible()}}},[_vm._v(" "+_vm._s(day.day)+" ")]),_vm._l((attributes),function(attr,i){return _c('div',{key:i},[(
              _vm.dayjs(day.date).unix() > _vm.dayjs().unix() &&
              attr.customData.status == 0
            )?_c('div',{staticClass:"day-d day-not-avalible"},[_vm._v(" "+_vm._s(day.day)+" ")]):(
              _vm.dayjs(day.date).unix() > _vm.dayjs().unix() &&
              attr.customData.status == 2
            )?_c('div',{staticClass:"day-d day-full"},[_vm._v(" "+_vm._s(day.day)+" ")]):(attr.customData.status == 1)?_c('div',{staticClass:"day-d day-avalible",attrs:{"id":'day-' + day.day},on:{"click":function($event){return _vm.setAvalibleTime(attr, attr.dates, day.day)}}},[_vm._v(" "+_vm._s(day.day)+" ")]):_vm._e()])})],2)]}}])}),(_vm.avalibleTime.length > 0)?_c('div',{staticClass:"avalible-time"},[_c('h3',[_vm._v("請選擇時段 或 直接下一步")]),_c('div',{staticClass:"avalible-time-selection"},[_vm._l((_vm.avalibleTime),function(time,i){return [_c('el-button',{key:i,staticClass:"time-selection",attrs:{"id":'time-selection-' + time.time_period_id,"round":"","type":"info"},on:{"click":function($event){return _vm.setParentSelectedDate(
              time.time_period_id,
              time.time_period_title,
              time.employee_schedule_ids
            )}}},[_vm._v(_vm._s(time.time_period_title))])]})],2)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }