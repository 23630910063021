<template>
  <div class="step2" type="flex">
    <div class="info-block">
      <Calendar @setSelectedDate="setSelectedDate" />
      <OrderDetail />
    </div>
    <div class="tip-text">
      <div v-if="selectedTime.timeId">
        <h3>
          已選擇時段：{{ selectedTime.date }}

          <template
            v-if="
              $store.state.formType == 'type1' ||
              $store.state.formType == 'type2'
            "
          >
            <span v-if="selectedTime.timeLabel">
              ，{{ selectedTime.timeLabel }}
            </span>
          </template>
        </h3>
      </div>
      <h3 v-else>請選擇時段 或 填寫客戶資料</h3>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variable.scss';

.step2 {
  margin-top: 50px;
  .info-block {
    display: flex;
    align-items: flex-start;
    .form-order-detail {
      margin-left: 15px;
    }
  }
  .tip-text {
    position: fixed;
    left: 40px;
    bottom: 20px;
    z-index: 51;
    color: #fff;
    h3 {
      font-weight: 300;
      margin: 0;
      font-size: 24px;
    }
  }
}

@media screen and (max-width: 500px) {
  .step2 {
    margin-top: 50px;
    .info-block {
      flex-wrap: wrap;
      margin-bottom: 120px;
      .form-order-detail {
        margin-top: 10px;
        margin-left: 0px;
      }
    }
    .tip-text {
      left: 0;
      top: unset;
      width: 100%;
      text-align: center;
      h3 {
        font-size: 16px;
      }
    }
  }
}
</style>

<script>
import Calendar from '@/components/order/step2/Calendar.vue'
import OrderDetail from '@/components/order/OrderDetail.vue'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import _ from 'lodash'
dayjs.extend(isBetween)

export default {
  name: 'Step2',
  data() {
    return {
      selectedTime: {},
      passServices: [
        '其他清潔',
        '防疫消毒',
        '購買時數',
        '除蟎清潔',
        '冷氣清潔',
        '地毯清潔',
        '床墊清潔',
        '沙發清潔',
        '洗地打蠟'
      ],
      currentStep: 2
    }
  },
  components: {
    Calendar,
    OrderDetail
  },
  computed: {
    step1() {
      return JSON.parse(window.sessionStorage.getItem('step1'))
    }
  },
  created() {
    if (this.passServices.indexOf(this.$store.state.formService) > -1) {
      if (this.$store.state.formLastStep > this.$store.state.formStep) {
        this.$store.dispatch('prevFormStep')
      } else {
        this.$store.dispatch('nextFormStep')
      }
    }

    const titleEl = document.querySelector('head title')
    titleEl.textContent = '預約 ' + this.$route.params.service
  },
  methods: {
    setSelectedDate(data) {
      this.selectedTime = data
      this.saveSessionFormData()
    },
    saveSessionFormData() {
      window.sessionStorage.setItem(
        'step' + this.currentStep,
        JSON.stringify({
          selectedTime: this.selectedTime
        })
      )
      this.$store.commit('setSelectedTime', this.selectedTime)
      this.updateOrderPrice()
      this.$store.dispatch('setFormValid', true)
    },
    getSessionFormData() {
      let sessionData = window.sessionStorage.getItem('step' + this.currentStep)

      if (sessionData) {
        sessionData = JSON.parse(sessionData)
        //this.selectedTime = sessionData.selectedTime;
        this.$store.dispatch('setFormValid', true)
      }
    },
    updateOrderPrice() {
      if (this.selectedTime.date) {
        const selectedWeekDay = new Date(this.selectedTime.date).getDay()

        let pricePerHour = parseInt(
          this.$store.state.serviceDetail.service.price
        )
        //以小時計算價格
        const predictTime = sessionStorage.getItem('predictTime')
        let total = 0
        let specialHourPrice = 0
        // filter 特殊價格
        let specialPriceItems =
          this.$store.state.serviceDetail.service.special_price_items.filter(
            (e) => {
              // 判斷特殊價格可用，且時間符合規則
              return e._remove_ === '0' && (
                (e.type === 'range' && dayjs(this.selectedTime.date).isBetween(e.start, e.end)) ||
                (e.type === 'day' && parseInt(e.weekday) === selectedWeekDay)
              )
            }
          )

        // 先依照 type 降冪排序, 再依照 id 升冪排序
        specialPriceItems = _.orderBy(specialPriceItems, ['type'], ['desc'])

        // 取第一筆特殊價格
        const special = specialPriceItems[0]

        if (special) {
          if (special.way === 'add') {
            specialHourPrice = parseInt(special.hour_price)
          } else {
            specialHourPrice = -parseInt(special.hour_price)
          }
        }

        if (predictTime) {
          total = pricePerHour * predictTime
        }
        this.$store.commit('setFormPrice', total)
        this.$store.commit(
          'setSpecialPrice',
          specialHourPrice * Math.ceil(predictTime)
        )
      }
    }
  },
  mounted() {
    this.selectedTime = {}
    this.$store.dispatch('setFormValid', true)
    window.scrollTo({ top: 0, behavior: 'smooth' })

    this.getSessionFormData()
  }
}
</script>
