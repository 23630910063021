import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'

import Home from '@/views/Home.vue'
import About from '@/views/About.vue'
import Section from '@/views/Section.vue'
import Login from '@/views/Login.vue'
import Register from '@/views/Register.vue'
import Member from '@/views/Member.vue'
import ForgetPassword from '@/views/ForgetPassword.vue'
import ForgetAccount from '@/views/ForgetAccount.vue'
import ResetPassword from '@/views/ResetPassword.vue'
import Comment from '@/views/Comment.vue'
import OrderDetail from '@/views/OrderDetail.vue'

import Order from '@/views/Order.vue'
import FinishOrder from '@/views/FinishOrder.vue'
import Join from '@/views/Join.vue'
import AuthSuccess from '@/views/AuthSuccess.vue'
import Notfound from '@/views/404.vue'

import serviceList from '@/info/serviceList'
import meta from '@/info/meta'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      requireLoadService: true
    }
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/' + meta.servicePrefix,
    name: 'service',
    component: Section,
    children: serviceList,
    meta: {
      requireLoadService: true
    }
  },
  {
    path: '/order',
    name: 'order',
    component: Order,
    props: true,
    meta: {
      requireLoadService: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/team',
    name: 'join',
    component: Join
  },
  {
    path: '/register/:encrypted?',
    name: 'register',
    component: Register
  },
  {
    path: '/member',
    name: 'member',
    component: Member,
    children: [
      {
        path: 'order',
        name: 'member-order',
        component: Member
      },
      {
        path: 'book',
        name: 'member-book',
        component: Member
      },
      {
        path: 'profile',
        name: 'member-profile',
        component: Member
      },
      {
        path: 'change-password',
        name: 'member-change-password',
        component: Member
      },
      {
        path: 'address',
        name: 'member-address',
        component: Member
      }
    ],
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/forget-password',
    name: 'forget-password',
    component: ForgetPassword
  },
  {
    path: '/forget-account',
    name: 'forget-account',
    component: ForgetAccount
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPassword
  },
  {
    path: '/notfound',
    name: 'notfound',
    component: Notfound
  },
  {
    path: '/orderDetail/:orderId',
    name: 'orderDetail',
    component: OrderDetail,
    props: true
  },
  {
    path: '/comment/:orderId',
    name: 'comment',
    component: Comment,
    props: true
  },
  {
    path: '/auth_success/:token',
    name: 'auth_success',
    component: AuthSuccess
  },
  {
    path: '/finish-order',
    name: 'finish-order',
    component: FinishOrder,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/*',
    component: Notfound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to) {
    if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

router.beforeEach((to, from, next) => {
  const storageUserData = localStorage.getItem('token')
  const expiryTime = sessionStorage.getItem('expiry')
  if (storageUserData) {
    Vue.axios.defaults.headers.common['Authorization'] =
      'Bearer ' + storageUserData //設置全局 Token

    store.commit('setToken', storageUserData)
    const now = new Date()
    if (now.getTime() > expiryTime) {
      store.dispatch('refreshToken')
    }
  }

  if (store.state.serviceRanges.length == 0) {
    store.dispatch('getBackendServices')
  }

  if (store.state.faqData.length == 0) {
    store.dispatch('getFaqType')
  }

  if (to.meta.requireAuth) {
    store.dispatch('showLoading')
    if (store.state.userToken) {
      next()
    } else {
      next({
        path: '/login'
      })
      store.dispatch('hideLoading')
    }
  } else {
    next()
  }
})

router.afterEach((route) => {
  store.dispatch('hideLoading')
})

export default router
