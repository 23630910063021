<template>
  <div class="member-sidebar">
    <el-avatar
      class="avatar"
      :size="150"
      fit="cover"
      :src="$props.avatar"
    ></el-avatar>
    <div class="userinfo">
      <p class="username">Hi, {{ $store.state.userData.name }}</p>
      <p class="email">{{ $store.state.userData.email }}</p>
      <p class="points">剩餘時數：{{ this.$store.state.userHours }} 小時</p>
    </div>
    <el-button size="small" type="danger" @click="logout">登出</el-button>
    <el-menu
      class="menu"
      :mode="$isMobile() ? 'horizontal' : 'vertical'"
      :default-active="$route.path"
      :router="true"
    >
      <el-menu-item index="/member/order">
        <i class="el-icon-finished"></i>
        <span slot="title">我的訂單</span>
      </el-menu-item>
      <el-menu-item index="/member/book">
        <i class="el-icon-date"></i>
        <span slot="title">時數管理</span>
      </el-menu-item>
      <el-menu-item index="/member/profile">
        <i class="el-icon-edit-outline"></i>
        <span slot="title">會員資料</span>
      </el-menu-item>
      <!-- <el-menu-item index="/member/change-password">
        <i class="el-icon-lock"></i>
        <span slot="title">變更密碼</span>
      </el-menu-item> -->
    </el-menu>
  </div>
</template>

<style lang="scss" scoped>
.member-sidebar {
  text-align: center;
  .avatar {
    box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.1î);
  }
  .userinfo {
    color: #333;
    font-size: 14px;
    .username {
      font-weight: bold;
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 500px) {
  .menu {
    display: flex;
    overflow: auto;
  }
}
</style>

<script>
export default {
  props: ["avatar"],
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>
