import Section from '../views/Section.vue'
import meta from '@/info/meta'

export default [
  {
    name: '居家清潔',
    no: '0000',
    path: 'household',
    mainImg: require('../assets/home/service/1.jpg'),
    isParent: false,
    showHome: true,
    showCleanService: true,
    component: Section,
    detail: {
      slides: [
        require('../assets/service/household/1.png'),
        require('../assets/service/household/2.jpg'),
        require('../assets/service/household/3.jpg'),
        require('../assets/service/household/4.jpg'),
        require('../assets/service/household/5.jpg')
      ],
      img: [
        require('../assets/service/household/6.png'),
        require('../assets/service/household/7.jpg')
      ],
      content: `
            <h2>費用說明</h2>
            NT$1,500起-3小時，客製化居家清潔服務，幫你解決高處難清潔的灰塵，清潔不容易打掃的居家角落。<br><br>
            ● 基本三小時起，35坪以下可1人<br><br>
            ● 35坪以上，或者有衛浴兩間，建議2人<br><br>
            ● 居家清潔加購防疫消毒折價💰300<br><br>
            <h2>服務範圍</h2>
            台北市、新北市(大眾運輸工具能到達的區域)<br><br>
            ＊特殊地段請先確認
            `
    }
  },
  {
    name: '辦公室清潔',
    no: '0001',
    path: 'office',
    mainImg: require('../assets/home/service/2.jpg'),
    isParent: false,
    showHome: true,
    showCleanService: true,
    component: Section,
    detail: {
      slides: [
        require('../assets/service/office/1.jpg'),
        require('../assets/service/office/2.jpg'),
        require('../assets/service/office/3.jpg')
      ],
      img: [],
      content: `
            <h2>費用說明</h2>
            NT$1,200起-3小時<br><br>
            ● 辦公室會議室、隔間擦拭清潔<br>
            ● 隔間玻璃窗戶內側刮拭<br>
            ● 地面地毯清潔除塵<br>
            ● 大門入口處清潔<br>
            ● 廁所茶水間整理<br>
            ● 公共區域清潔<br>
            ● 日用耗材更換<br>
            ● 垃圾收取
            `
    }
  },
  {
    name: '營業場所清潔',
    no: '0003',
    path: 'business_premises',
    mainImg: require('../assets/home/service/3.png'),
    isParent: false,
    showHome: true,
    showCleanService: true,
    component: Section,
    formLink: 'https://forms.gle/XaTkYrTSZ35wJyeR8',
    detail: {
      slides: [
        require('../assets/service/business_premises/1.png'),
        require('../assets/service/business_premises/2.png'),
        require('../assets/service/business_premises/3.png'),
        require('../assets/service/business_premises/4.png'),
        require('../assets/service/business_premises/5.jpg')
      ],
      img: [],
      content: `
            <h2>費用說明</h2>
            客服詢價<br><br>
            營業場所的清潔﹐首重完工品質及持久性﹐所以無論是室內外地板清潔打蠟、地毯、沙發清洗維護、 專業石材養護、研磨晶化抛光工程﹐我們都會幫您做到最好。<br><br><br><br>
            ● 夜店/酒吧<br>
            ● 婚宴會館<br>
            ● 招待所/KTV<br>
            ● 電影院<br>
            ● 廚房
            `
    }
  },
  {
    name: '裝潢細清',
    no: '0002',
    path: 'upholstery',
    mainImg: require('../assets/home/service/5.png'),
    isParent: false,
    showHome: true,
    showCleanService: true,
    component: Section,
    formLink:
      'https://docs.google.com/forms/d/1AsmxgmrTwDySMbqzVWApBdyU7jqmvH7PrWSZkVQ9g4Y/edit',
    detail: {
      slides: [require('../assets/service/upholstery/1.jpg')],
      img: [],
      content: `
            <h2>費用說明</h2>
            客服詢價($700/HR)<br><br>
            粗清細清差別<br>
            <h4>粗清</h4>
            ‧ 大型垃圾回收清除（燈具、家電的紙箱保麗龍等大型包裝材，清理木皮、廢料）。<br>
            ‧ 初步清理粉塵。<br>
            ‧ 清除施工時的殘留的水泥塊、殘膠、現場的保護材等。<br>
            <h4>細清</h4>
            ‧ 由上而下清掃（天花板、間接照明、崁燈、櫃體、牆面、門板、窗面、窗框、窗溝）。<br>
            ‧ 所有碰觸到的物體表面及櫃內所有隔間。<br>
            ‧ 清潔後可直接入住。<br>
            寶實潔只處理入住前的「細部清潔」，在工班粗清並退場後3-5天進行細清。<br>
            ※需自備梯子及垃圾袋<br>
            ※場勘討論報價後，才會進行服務
            `
    }
  },
  {
    name: '消毒服務',
    no: '0004',
    path: 'disinfect',
    mainImg: require('../assets/home/service/6.png'),
    isParent: false,
    showHome: true,
    component: Section,
    formLink:
      'https://docs.google.com/forms/d/e/1FAIpQLSdiIq6n0wwfjI-M9-SMjcBkurUuo2-1LMYDLGfs2bhF-A-JOQ/viewform',
    detail: {
      slides: [
        require('../assets/service/disinfect/1.jpg'),
        require('../assets/service/disinfect/2.jpg')
      ],
      img: [require('../assets/service/disinfect/3.jpg')],
      content: `
            <h2>費用說明</h2>
            NT$1,500  <span class='slash'>(NT$2,000)</span><br><br>
            『寶實潔消毒滅菌再升級』<br>
            選用SNQ國家品質標章防疫認證消毒液、無毒無刺激，大人、小孩、寵物都安心<br><br>
            ULV噴霧機噴灑式消毒，死角K.O.、達到99.9%殺菌效果，有2-8天殘效性，防護加分(Awesome)<br><br>
            辦公室、餐聽也提供服務喔！<br>
            居家清潔加購防疫消毒折價💰300
            `
    }
  },
  {
    name: '其他清潔',
    path: 'other',
    mainImg: require('../assets/home/service/7.png'),
    isParent: false,
    showHome: true,
    component: Section,
    children: [
      {
        name: '冷氣/洗衣機清潔',
        no: '0005',
        path: 'ac_clean',
        component: Section,
        formLink:
          'https://docs.google.com/forms/d/e/1FAIpQLScBeEgzBncPH7xq6hmQlToMnYVXu4108ki45OHRIxJNa2ANEw/viewform',
        detail: {
          slides: [require('../assets/service/other/ac_clean.jpg')],
          img: [],
          content: `
                    <h2>費用說明</h2>
                    NT$1,500起<br><br>
                    長久使用冷氣機的散熱器與蒸發器容易堆積灰塵汙垢。造成出風口阻塞，冷房效果降低、冷氣不冷、耗電量增加，也可能使得壓縮機過載和過熱致使壓縮機損壞，固定清洗可延長冷氣機壽命，每年省下10%以上的電費。<br>建議每半年～1年清洗保養一次
                    `
        }
      },
      {
        name: '外牆清潔',
        no: '0006',
        path: 'washer_clean',
        component: Section,
        formLink:
          'https://docs.google.com/forms/d/e/1FAIpQLSffwal6XxAqQsRiCfJ6Z5Wk1ipkSTysHqkZ6gAEZB7VHZ6mnA/viewform',
        detail: {
          slides: [
            require('../assets/service/other/washer_clean/washer_clean.jpg'),
            require('../assets/service/other/washer_clean/washer_clean_2.jpg')
          ],
          img: [],
          content: `
                    <h2>費用說明</h2>
                    客服詢價(NT$2,000起)<br><br>
                    此項目包含一張床或是一張沙發<br>
                    具備HEPA 13等級以上的水過濾式除蹣吸塵器，獲得英國過敏協會認證及歐盟過濾認證，HEPA過濾器也可以去除空氣中的異味與細小灰塵顆粒，有效去除家中粉塵。<br>
                    塵蟎以人類脫落的皮屑、毛髮維生，喜愛潮濕溫暖的環境，<br>
                    會造成過敏和皮膚發癢，小心家中床墊、沙發、枕頭…可能暗藏著上百萬隻塵蟎！
                    `
        }
      }
    ]
  },
  {
    name: '購買時數',
    path: 'hours',
    mainImg: require('../assets/home/service/7.png'),
    isParent: false,
    showHome: true,
    component: Section,
    detail: {
      slides: [
        require('../assets/service/hours/1.jpg'),
        require('../assets/service/hours/2.jpg')
      ],
      img: [],
      content: `
            `
    }
  }
]
