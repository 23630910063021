<template>
  <div class="finish">
    <el-result icon="success" title="預約成功">
      <template slot="extra">
        <!-- <div class="finish-text">
          寶實潔清潔公司感謝您的預約<br />
          請登入會員中心查詢付款資訊<br />
          會員帳號: 0000<br />
          會員密碼: 1111<br />
          請於8/31 24:00前完成付款（下單日隔天24:00前）<br />
          訂單逾期未付款系統將自動取消訂單喔
        </div> -->
        <router-link to="/member">
          <el-button class="finish-button" type="success" plain
            >查看訂單</el-button
          >
        </router-link>
      </template>
    </el-result>
  </div>
</template>


<style lang="scss" scoped>
@import '@/assets/style/variable.scss';
.finish {
  .finish-text {
    line-height: 2;
    color: #333;
    font-size: 20px;
    font-weight: 300;
    text-align: left;
    background: rgba(0, 0, 0, 0.03);
    padding: 30px;
    border-radius: 5px;
  }
  .finish-button {
    margin: 20px 0;
  }
}
</style>

<script>
export default {
  name: 'Finish',
  data() {
    return {}
  },
  created() {},
  beforeDestroy() {
    this.$store.dispatch('setFormValid', false)
    this.$store.dispatch('setFormService', {})
  }
}
</script>
