<template>
  <div class="member">
    <el-row :gutter="$isMobile() ? 0 : 30">
      <el-col :span="6" :xs="24">
        <Sidebar
          avatar="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
        />
      </el-col>
      <el-col :span="18" :xs="24">
        <OrderLayout v-if="page == 'member-order'" />
        <Book v-else-if="page == 'member-book'" />
        <Profile v-else-if="page == 'member-profile'" />
        <ChangePassword v-else-if="page == 'member-change-password'" />
      </el-col>
    </el-row>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variable.scss';
.member {
  margin: 0 auto;
  max-width: 1200px;
  margin-top: calc(50px + #{$navigationHeight});
}
</style>

<script>
import Sidebar from '@/components/member/sidebar.vue'
import Book from '@/components/member/book.vue'
import Profile from '@/components/member/profile.vue'
import ChangePassword from '@/components/member/changePassword.vue'
import OrderLayout from '@/components/member/orderLayout.vue'

export default {
  components: {
    Sidebar,
    Book,
    Profile,
    ChangePassword,
    OrderLayout
  },
  data() {
    return {
      page: '',
      totalHour: 0,
    }
  },
  mounted() {
    this.page = this.$router.currentRoute.name

    if (this.page == 'member') {
      this.$router.push({ name: 'member-order' })

      return
    }
    this.$store.dispatch('checkUserData', true)
  },
  methods: {
  }
}
</script>
