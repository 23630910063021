<template>
  <div class="order-detail">
    <div class="order-detail-form">
      <h1>
        <div>訂單明細({{ orderId }})</div>
        <el-button
          style="margin-top: 20px"
          plain
          type="success"
          icon="el-icon-edit"
          size="small"
          @click="goComment()"
          :disabled="orderStatus != 2"
          >留下評價</el-button
        >
      </h1>

      <el-descriptions :column="3" title="訂購人資料"></el-descriptions>
      <el-table
        :data="customerData"
        :span-method="spanMethon1"
        style="margin-bottom: 20px"
      >
        <el-table-column prop="name" label="訂購人"></el-table-column>
        <el-table-column prop="addr" label="服務地址"></el-table-column>
        <el-table-column label=""></el-table-column>
      </el-table>
      <el-table :data="customerData" style="margin-bottom: 20px">
        <el-table-column prop="phone" label="電話"></el-table-column>
        <el-table-column prop="email" label="E-mail"></el-table-column>
        <el-table-column prop="note" label="備註"></el-table-column>
      </el-table>

      <el-descriptions :column="3" title="服務內容"></el-descriptions>
      <el-table :data="serviceContent" style="margin-bottom: 20px">
        <el-table-column prop="orderDate" label="訂購日期"></el-table-column>
        <el-table-column prop="area" label="室內總坪數"></el-table-column>
        <!-- <el-table-column prop="orderId" label="訂單編號"></el-table-column> -->
        <el-table-column prop="totalTime" label="總時數"></el-table-column>
      </el-table>
      <el-table
        :data="serviceContent"
        :span-method="spanMethon2"
        style="margin-bottom: 20px"
      >
        <el-table-column prop="serviceItem" label="服務項目"></el-table-column>
        <el-table-column
          prop="serviceDetail"
          label="服務內容"
          style="white-space: pre-wrap !important"
        ></el-table-column>
        <el-table-column label=""></el-table-column>
      </el-table>

      <el-descriptions :column="3" title="服務時間"></el-descriptions>
      <el-table :data="serviceDate" style="margin-bottom: 20px">
        <el-table-column prop="serviceDate" label="服務日期"></el-table-column>
        <el-table-column prop="serviceTime" label="服務時段"></el-table-column>
        <el-table-column label=""></el-table-column>
      </el-table>

      <el-descriptions :column="3" title="付款資訊"></el-descriptions>
      <el-table
        :data="billData"
        :span-method="spanMethon3"
        style="margin-bottom: 20px"
      >
        <el-table-column prop="price" label="匯款/刷卡金額"></el-table-column>
        <el-table-column prop="payMethod" label="付款方式"></el-table-column>
        <el-table-column prop="totalPrice" label="總付金額"></el-table-column>
        <el-table-column prop="payStatus" label=""></el-table-column>
        <el-table-column prop="" label="付款狀態"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/style/variable.scss';

.order-detail {
  margin-top: $navigationHeight;
  height: auto;
  padding: 5vw 0;
  display: flex;
  align-items: center;

  .order-detail-form {
    width: 55%;
    margin-top: -10%;
    margin: 0 auto;

    h1 {
      margin-bottom: 50px;
      color: $primaryColor;
    }
  }
}

@media screen and (max-width: 500px) {
  .order-detail {
    margin-top: $navigationHeight;
    height: auto;
    padding: 5vw 0;
    display: flex;
    align-items: center;

    .order-detail-form {
      width: 90%;
      margin-top: -10%;
      margin: 0 auto;

      h1 {
        text-align: center;
      }
    }
  }
}
</style>

<script>
export default {
  name: 'OrderDetail',
  components: {},
  props: {
    orderId: {
      type: String
    }
  },
  mounted() {
    this.getOrderList()
  },
  data() {
    return {
      fApi: {},
      value: { field1: '', field2: '' },
      customerData: [],
      serviceContent: [],
      serviceDate: [],
      billData: [],
      orderStatus: 0,

      options: {
        form: {
          labelPosition: 'left',
          labelWidth: this.$isMobile() ? '100px' : ''
        },
        submitBtn: {
          show: false
        },
        onSubmit: (formData) => {
          alert(JSON.stringify(formData))
        }
      }
    }
  },
  methods: {
    spanMethon1({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 1) return [1, 2]
    },
    spanMethon2({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 1) return [1, 2]
    },
    spanMethon3({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 2) return [1, 2]
    },
    goComment() {
      this.$router.push('/comment/' + this.orderId)
    },
    getOrderList() {
      this.$store.dispatch('showLoading')
      this.axios
        .get(this.$store.state.apiUrl + 'orders/')
        .then((response) => {
          if (response.status == 200) {
            let item = []
            let list = response.data.data.orderNew

            if (list != null) {
              for (let i = 0; i < list.length; i++) {
                if (list[i].no == this.orderId) {
                  item = list[i]

                  break
                }
              }
            }

            this.orderStatus = item.status
            let customer = {}

            customer['name'] = item.name
            customer['addr'] = item.address
            customer['phone'] = item.mobile
            customer['email'] = item.email
            customer['note'] = item.remark['order_remark']
            this.customerData.push(customer)

            let service = {}
            service['orderDate'] = item.created_at
            service['area'] = item.work_pys_data
            service['orderId'] = item.no

            let workItemAry = item.work_items
            let workItems = {}

            for (let i = 0; i < workItemAry.length; i++) {
              if (workItemAry[i].name in workItems)
                workItems[workItemAry[i].name] += 1
              else workItems[workItemAry[i].name] = 1
            }

            let workItemsStr = ''
            let additionalServiceStr = ''
            let keys = Object.keys(workItems)
            if (keys.length == 0) {
              workItemsStr = ''
            } else {
              for (let i = 0; i < keys.length; i++) {
                workItemsStr += keys[i]
                workItemsStr += ': ('
                workItemsStr += workItems[keys[i]]
                workItemsStr += ')'
                if (i != keys.length - 1) workItemsStr += ', '
              }
            }
            additionalServiceStr = ''
            for (let i = 0; i < item.additional.length; i++) {
              additionalServiceStr += item.additional[i].name
              if (i != item.additional.length - 1) additionalServiceStr += ', '
            }

            service['serviceDetail'] =
              workItemsStr + '\n\n' + additionalServiceStr
            service['serviceItem'] = item.title
            service['totalTime'] =
              item.total_hour +
              '小時 | ' +
              item.people +
              '人' +
              item.hour +
              '小時'
            this.serviceContent.push(service)

            let sDate = {}

            sDate['serviceDate'] = item.reserve_date
            sDate['serviceTime'] =
              item.reserve_stime + ' - ' + item.reserve_etime

            this.serviceDate.push(sDate)

            let bill = {}

            bill['price'] = item.total_amount
            bill['payMethod'] = item.payment_method_name
            bill['totalPrice'] =
              item.total_amount +
              '( 車馬費 ' +
              item.traffic_expenses +
              ' 元現場酌收 )'
            bill['payStatus'] = item.order_status_name

            this.billData.push(bill)
          }
        })
        .catch((e) => {
          this.$message({
            message: '憑證錯誤，請重新登入',
            type: 'error'
          })
        })
        .then(() => {
          this.$store.dispatch('hideLoading')
        })
    }
  }
}
</script>

<style>
.cell {
  white-space: pre-wrap !important;
}
</style>
