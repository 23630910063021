export default [
  {
    title: '線上預定',
    desc: '填寫資料、輕鬆預定、清潔服務',
    icon: require('../assets/home/step/booking.gif'),
  },
  {
    title: '完成付款',
    desc: '提供不同付款方式，快速預約',
    icon: require('../assets/home/step/credit-card.gif'),
  },
  {
    title: '到府服務',
    desc: '居清師到場溝通服務細節及施作',
    icon: require('../assets/home/step/housekeeping.gif'),
  },
  {
    title: '清潔驗收',
    desc: '清潔完畢、解說驗收、清潔項目',
    icon: require('../assets/home/step/search.gif'),
  },
];
