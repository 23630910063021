<template>
  <div class="auth-success">
    <el-result
      icon="success"
      title="登入成功"
      subTitle="頁面將自動跳轉，請稍候"
      v-if="isValid"
    >
    </el-result>
    <el-result icon="error" title="登入失敗" subTitle="非法操作" v-else>
    </el-result>
  </div>
</template>


<style lang="scss" scoped>
@import "@/assets/style/variable.scss";

.auth-success {
  margin-top: $navigationHeight;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<script>
export default {
  name: "notfound",
  data() {
    return {
      isValid: false,
    };
  },
  created() {
    const self = this;
    const token = this.$route.params.token;
    if (token.length > 300) {
      this.isValid = true;

      /**
       * TOKEN 驗證，避免直接輸入URL登入導致 router 出錯
       */
      // this.axios.defaults.headers.common["Authorization"] =
      //   "Bearer " + token; //設置全局 Token

      // this.axios
      //   .get(self.$store.state.apiUrl + "auth/refresh")
      //   .then((response) => {
      //     console.log(response);
      //     // if (response.status == 200) {
      //     //   commit("setToken", response.data.data.token);
      //     //   localStorage.setItem("token", response.data.data.token);
      //     //   self.router.push({ name: "member" });
      //     // }
      //   })
      //   .catch((e) => {
      //     console.log(e.response.data.status);
      //     // if (e.response.data.status == 401) {
      //     //   this._vm.$message({
      //     //     message: "登入失敗，帳號或密碼錯誤",
      //     //     type: "error",
      //     //   });
      //     // } else {
      //     //   this._vm.$message({
      //     //     message: "目前無法登入，請稍後再試",
      //     //     type: "error",
      //     //   });
      //     // }
      //   });

      this.axios.defaults.headers.common["Authorization"] = "Bearer " + token; //設置全局 Token
      this.$store.commit("setToken", token);
      localStorage.setItem("token", token);


    } else {
      this.isValid = false;
    }
  },
  mounted() {
    setTimeout(() => {
      this.$router.push({ name: "home" });
    }, 2000);
  },
};
</script>
