<template>
  <div class="order">
    <Progress />
    <el-alert
      v-if="
        hasSessionData &&
        $store.state.formStep <= 4 &&
        currentService == $store.state.formService
      "
      title="您的預約尚未完成，已為您載入最後填寫資料，建議儘快完成預約！"
      style="margin-top: 50px"
      type="info"
      effect="dark"
    ></el-alert>
    <Step1
      :key="currentService"
      :service="currentService"
      :subOrder="$props.subOrder"
      v-if="$store.state.formStep == 1"
    />
    <Step2 :key="currentService" v-else-if="$store.state.formStep == 2" />
    <Step3 :key="currentService" v-else-if="$store.state.formStep == 3" />
    <Step4 :key="currentService" v-else-if="$store.state.formStep == 4" />
    <Navigation />
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variable.scss';
.order {
  margin-top: $navigationHeight !important;
  height: auto;
  padding: 5vw;
  max-width: 1200px;
  margin: 0 auto;
}
</style>

<script>
import Progress from '@/components/order/Progress.vue'
import Navigation from '@/components/order/Navigation.vue'

import Step1 from '@/components/order/Step1.vue'
import Step2 from '@/components/order/Step2.vue'
import Step3 from '@/components/order/Step3.vue'
import Step4 from '@/components/order/Step4.vue'
export default {
  name: 'order',
  props: {
    service: String,
    subOrder: String
  },
  components: {
    Progress,
    Navigation,
    Step1,
    Step2,
    Step3,
    Step4
  },
  data() {
    return {
      currentService: '',
      hasSessionData: false
    }
  },
  watch: {
    $route() {
      this.$store.dispatch('resetFormStep')
      this.currentService = this.$route.params.service

      const serviceType = this.getServiceTypeByName(this.currentService)

      if (this.$store.state.formType != 'type7') {
        this.$store.dispatch('setFormService', {
          name: this.currentService,
          type: serviceType
        })
      }
    },
    currentService() {
      const serviceType = this.getServiceTypeByName(this.currentService)

      if (this.$store.state.formType != 'type7') {
        this.$store.dispatch('setFormService', {
          name: this.currentService,
          type: serviceType
        })
      }

      // 服務檢查，若後臺無此服務，則返回
      if (!serviceType && this.currentService != '購買時數') {
        //暫時手動排除購買時數

        if (!serviceType) {
          this.$router.push({ name: 'home' })
          return
        }

        this.$router.push({ name: 'home' })
        this.$message({
          message: '目前此服務不開放，請重新選擇',
          type: 'warning'
        })

        this.$store.dispatch('setFormService', {})
      }
    }
  },
  created() {
    if (!this.$route.params.service) {
      //this.checkSessionFormData();
    } else {
      this.currentService = this.$route.params.service
    }
  },
  beforeCreate() {
    const serviceType = this.$store.state.serviceTypes.find(
      (item) => item.description == this.$route.params.service
    )

    if (!serviceType && this.$route.params.service != '購買時數') {
      this.$router.push({ name: 'home' })
      return
    }

    if (this.$store.state.formType != 'type7') {
      this.$store.dispatch('setFormService', {
        name: this.$route.params.service,
        type: serviceType?.description
      })
    }
    // }
  },
  methods: {
    checkSessionFormData() {
      let sessionData = window.sessionStorage.getItem('step1')

      if (sessionData) {
        sessionData = JSON.parse(sessionData)
        this.currentService = sessionData.service
        this.hasSessionData = true
      } else {
        this.$router.push({ name: 'notfound' })
      }
    }
  }
}
</script>
