<template>
  <div class="control" v-if="$store.state.formStep <= 4">
    <el-row type="flex" :justify="$isMobile() ? 'space-around' : 'end'">
      <transition name="el-fade-in-linear">
        <el-button round @click="prevStep" v-if="$store.state.formStep >= 2"
          ><i class="el-icon-arrow-left"></i> 上一步</el-button
        >
      </transition>
      <el-button
        round
        :type="$store.state.formValid ? 'success' : 'info'"
        @click="nextStep"
      >
        <span v-if="$store.state.formStep == 1"
          >下一步 <i class="el-icon-arrow-right"></i
        ></span>
        <span v-else-if="$store.state.formStep == 2"
          >填寫客戶資料 <i class="el-icon-arrow-right"></i
        ></span>
        <span v-else-if="$store.state.formStep == 3"
          >訂單確認 <i class="el-icon-arrow-right"></i
        ></span>
        <span v-else>成立訂單 <i class="el-icon-check"></i></span>
      </el-button>
    </el-row>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variable.scss';
.control {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 50;
  width: 100%;
  padding: 20px;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
}
@media screen and (max-width: 500px) {
  .control {
    top: unset;
    bottom: 0;
    width: 100%;
    padding: 20px 20px 60px 20px;
    .el-row {
      width: 100%;
    }
  }
}
</style>

<script>
export default {
  name: 'Navigation',
  methods: {
    prevStep() {
      if (this.$route.params.service == '購買時數') {
        // this.$store.commit('setFormLastStep', 3)
        // this.$store.commit('setFormStep', 1)
        this.$store.dispatch('prevFormStep')
        this.$store.dispatch('setFormValid', false)
      } else {
        if (this.$store.state.formStep === 4) {
          this.$store.commit('setCouponAmount', 0)
        }
        this.$store.dispatch('prevFormStep')
        this.$store.dispatch('setFormValid', false)
      }
    },
    nextStep() {
      if (this.$store.state.formStep == 1) {
        if (!this.$store.state.formValid) {
          this.$message({
            message: '有必填欄位尚未填寫，請填寫後再繼續',
            type: 'warning'
          })
          return
        }
        const step1 = JSON.parse(sessionStorage.getItem('step1'))
        if (this.$route.params.service == '購買時數') {
          if (!step1) {
            this.$store.dispatch('setFormValid', false)
            this.$message({
              message: '有必填欄位尚未填寫，請填寫後再繼續',
              type: 'warning'
            })
            return
          } else {
            this.getOrderPrice()
            this.$store.dispatch('getInvoiceTypes').then(() => {
              this.$store.dispatch('getPaymentMethodDay').then(() => {
                this.$store.commit('setFormLastStep', 1)
                this.$store.commit('setFormStep', 3)
                return
              })
            })
          }
        }
        if (!step1) {
          this.$store.dispatch('setFormValid', false)

          this.$message({
            message: '有必填欄位尚未填寫，請填寫後再繼續',
            type: 'warning'
          })
          return
        }
        this.$store.dispatch('showLoading')
        this.axios
          .post(this.$store.state.apiUrl + 'services/check-address', {
            address: step1.address2
          })
          .then((response) => {
            if (response.status == 200) {
              const pass = response.data.data.correct

              if (pass == true) {
                if (
                  this.$store.state.formType == 'type1' ||
                  this.$store.state.formType == 'type2'
                ) {
                  if (this.$store.state.formPrice == 0) {
                    this.$store.dispatch('setFormValid', false)

                    this.$message({
                      message: '請選擇服務範圍',
                      type: 'warning'
                    })
                    return
                  } else if (!this.$store.state.formValid) {
                    this.$message({
                      message: '有必填欄位尚未填寫，請填寫後再繼續',
                      type: 'warning'
                    })
                    return
                  } else if (response.data.data.zone === null) {
                    this.$message({
                      message: '此地址不在服務範圍內',
                      type: 'warning'
                    })
                    return
                  } else {
                    this.$store.commit('setTrafficDetail', {
                      traffic_expenses:
                        response.data.data.zone.traffic_expenses,
                      traffic_expenses_status:
                        response.data.data.zone.traffic_expenses_status
                    })
                    this.$store.commit(
                      'setServiceRangeId',
                      response.data.data.zone.id
                    )
                    this.$store.dispatch('setFormValid', true)
                    this.$store.dispatch('nextFormStep')
                  }
                } else {
                  this.$store.dispatch('setFormValid', true)
                  this.$store.dispatch('nextFormStep')
                }
              } else {
                this.$message({
                  message:
                    '您填寫的地址不在服務範圍，請確認地址或與LINE客服聯絡',
                  type: 'error'
                })
              }
            }
          })
          .catch((e) => {})
          .then(() => {
            this.$store.dispatch('hideLoading')
          })
      } else if (
        this.$store.state.formStep == 2 &&
        this.$store.state.formValid
      ) {
        if (this.$store.state.selectedTime.timeId === 0) {
          this.$message({
            message: '請選擇預約時段',
            type: 'warning'
          })
        } else {
          this.$store.dispatch('getInvoiceTypes').then(() => {
            this.$store.dispatch('getPaymentMethodDay').then(() => {
              this.$store.dispatch('nextFormStep')
            })
          })
        }
      } else if (this.$store.state.formStep == 4) {
        if (this.$store.state.formValid) {
          if (this.$route.params.service == '購買時數') {
            this.$store.dispatch('createHoursOrder')
          } else {
            this.$store.dispatch('createOrder')
          }
        } else {
          const step4 = JSON.parse(sessionStorage.getItem('step4'))
          if (
            (step4.payment_method === 'Hours') &
            (this.$store.state.userHours <= 0)
          ) {
            this.$message({
              message: '剩餘時數不足，請先購買時數',
              type: 'warning'
            })
          } else {
            this.$message({
              message: '有必填欄位尚未填寫，請填寫後再繼續',
              type: 'warning'
            })
          }
        }
      } else if (
        this.$store.state.formStep == 3 &&
        this.$store.state.formValid
      ) {
        if (localStorage.getItem('token') === null) {
          this.$store.dispatch('registerBeforeOrder')
        } else {
          this.$store.dispatch('nextFormStep')
        }
      } else {
        this.$message({
          message: '有必填欄位尚未填寫，請填寫後再繼續',
          type: 'warning'
        })
      }
    },
    getOrderPrice() {
      if (this.$route.params.service == '購買時數') {
        const order = JSON.parse(sessionStorage.getItem('step1'))

        const orderData = this.$store.state.timeProducts.find(
          (item) => item.id == order.selectedTimeProduct
        )
        this.$store.commit('setFormPrice', parseInt(orderData.price))
      } else {
        const pricePerHour = parseInt(
          this.$store.state.serviceDetail.service.price
        )
        //以小時計算價格
        let predictTime = sessionStorage.getItem('predictTime')
        let total = 0
        if (predictTime) {
          total = pricePerHour * predictTime
        }
        this.$store.commit('setFormPrice', total)
      }
    }
  },
  beforeDestroy() {
    this.$store.dispatch('resetFormStep')
  }
}
</script>
