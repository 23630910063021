<template>
  <div class="notfound">
    <el-result icon="error" title="404" subTitle="你所前往的頁面不存在">
      <template slot="extra">
        <router-link to="/">
          <el-button size="large">回首頁</el-button>
        </router-link>
      </template>
    </el-result>
  </div>
</template>


<style lang="scss" scoped>
@import "@/assets/style/variable.scss";

.notfound {
  margin-top: $navigationHeight;
}
</style>

<script>
export default {
  name: "notfound",
  data() {
    return {};
  },
  created() {},
};
</script>
