<template>
  <div class="order-list">
    <div class="order-list-form">
      <el-table :data="orders" style="width: 100%">
        <el-table-column prop="orderNum" label="訂單編號" min-width="10">
        </el-table-column>
        <el-table-column prop="hours" label="時數" min-width="4">
        </el-table-column>
        <el-table-column prop="createDate" label="訂購日期" min-width="14">
        </el-table-column>
        <el-table-column prop="price" label="訂單金額" min-width="8">
        </el-table-column>
        <el-table-column prop="status" label="訂單狀態" min-width="10">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variable.scss';
.order {
  h2 {
    text-align: center;
    color: $primaryColor;
  }

  .order-item {
    background: rgba(183, 238, 255, 0.2);
    padding: 24px;
    border-radius: 20px;
    box-shadow: 10px 10px 20px rgba(134, 205, 226, 0.2);
    transition: all 0.3s;
    margin-bottom: 35px;
    border: 1px solid rgba(183, 238, 255, 0.35);

    &:hover {
      cursor: pointer;
      background: rgba(183, 238, 255, 0.35);
    }
  }

  .pagination {
    text-align: center;
    margin: 20px 0;
  }
}
@media screen and (max-width: 500px) {
  .order {
    h2 {
      margin: 50px 0;
    }
  }
}
</style>

<script>
export default {
  data() {
    return {
      orders: [],
      openRateDialog: false,
      rateDialogData: {
        point: null,
        content: ''
      }
    }
  },
  methods: {
    sendRate() {
      this.openRateDialog = false
      this.orders[2].rating = this.rateDialogData
      this.rateDialogData = {
        point: null,
        content: ''
      }
    }
  },
  created() {
    const self = this

    this.$store.dispatch('showLoading')
    this.$store.dispatch('getOrdeStatusDay')
    this.axios
      .get(this.$store.state.apiUrl + 'time-orders')
      .then((response) => {
        if (response.status == 200) {
          this.$store.commit('setUserBookPoint', response.data.data.time_orders)
          response.data.data.time_orders.forEach((elem) => {
            let obj = {
              id: elem.id,
              orderNum: elem.no,
              price: elem.total_amount,
              createDate: elem.created_at,
              hours: elem.minute / 60,
              status: this.$store.state.orderStatusDay[elem.status]
            }
            this.orders.push(obj)
          })
        }
      })
      .catch((e) => {})
      .then(() => {
        this.$store.dispatch('hideLoading')
      })
  }
}
</script>
