<template>
  <div class="alert">
    <div class="alert-title">注意事項</div>
    <div class="alert-text" v-html="alertText"></div>
  </div>
</template>
<style lang="scss" scoped>
@import "@/assets/style/variable.scss";
.alert {
  margin-top: 50px;
  background: #eee;
  padding: 30px 14px;
  border-radius: 10px;
  .alert-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 30px;
  }
  .alert-text {
    max-width: 800px;
    margin: 0 auto;
    color: $blackTextColor;
    font-weight: 100;
    line-height: 1.5;
  }
}

@media screen and (max-width: 500px) {
  .alert {
    padding: 20px;
    .alert-text {
      max-width: 800px;
      margin: 0 auto;
      color: $blackTextColor;
      font-weight: 100;
      line-height: 1.5;
    }
  }
}
</style>
<script>
export default {
  name: "SectionAlert",
  data() {
    return {
      alertText: `
      。本公司不提供藝術品、 古董字畫、昂貴及易壞物品之清潔，若有此類物品甲方須事先告知並妥善保管。若有非上述之昂貴物品且可歸因於乙方服務 員之過失而致使損壞者，應由雙方共同商請公正第三方進行估價鑑定，以負損害賠償責任，最高賠償金額為壹萬元整。
      <br><br>
      。依據勞工安全衛生法規定， 打掃時無立足點及有危險性之清潔工作， 服務員可拒絕執行。
      <br><br>
      。若需取消預約者，最晚請於前一日下午六點前告知，若當日臨時取消者，僅退款50%。如因客戶端原因導致清潔服務時間延後或較晚開始，則不補時數。`,
    };
  },
  mounted() {},
  methods: {},
};
</script>
