<template>
  <div class="forget-password">
    <div class="forget-password-form">
      <h1>重設密碼</h1>

      <form-create
        :rule="rule"
        v-model="fApi"
        :option="options"
        :value.sync="value"
        v-if="formVerified"
      />

      <el-row type="flex" justify="center">
        <el-button plain type="success" @click="send()" v-if="formVerified"
          >送出</el-button
        >
      </el-row>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/style/variable.scss';

.forget-password {
  margin-top: $navigationHeight;
  height: auto;
  padding: 5vw 0;
  display: flex;
  align-items: center;
  .forget-password-form {
    width: 30%;
    margin-top: -10%;
    margin: 0 auto;
    h1 {
      margin-bottom: 50px;
      color: $primaryColor;
    }
  }
}

@media screen and (max-width: 500px) {
  .forget-password {
    margin-top: $navigationHeight;
    height: auto;
    padding: 5vw 0;
    display: flex;
    align-items: center;
    .forget-password-form {
      width: 90%;
      margin-top: -10%;
      margin: 0 auto;
      h1 {
        text-align: center;
      }
    }
  }
}
</style>

<script>
export default {
  name: 'Register',
  components: {},
  data() {
    const self = this
    return {
      fApi: {},
      value: { field1: '', field2: '' },
      options: {
        form: {
          labelPosition: 'left',
          labelWidth: this.$isMobile() ? '100px' : ''
        },
        submitBtn: {
          show: false
        },
        onSubmit: (formData) => {
          alert(JSON.stringify(formData))
        }
      },
      rule: [
        {
          type: 'input',
          field: 'password',
          title: '新密碼',
          value: '',
          validate: [
            { required: true, message: '此欄位必填' },
            { type: 'string', min: 8, message: '最少8個字元' }
          ],
          props: {
            placeholder: '輸入密碼',
            clearable: true,
            size: 'large',
            type: 'password',
            minlength: 8
          }
        },
        {
          type: 'input',
          field: 'passwordConfirm',
          title: '確認新密碼',
          Value: '',
          validate: [
            { required: true, message: '此欄位必填' },
            {
              validator: (rule, value, d) => {
                self.fApi.form.password != value ? d(true) : d()
              },
              message: '兩次輸入密碼不一致'
            },
            { type: 'string', min: 8, message: '最少8個字元' }
          ],
          props: {
            placeholder: '再次輸入密碼',
            clearable: true,
            size: 'large',
            type: 'password'
          }
        }
      ],
      formVerified: true
    }
  },
  methods: {
    send() {
      let urlParams = this.$route.query
      const self = this
      this.fApi.submit(
        (formData, fapi) => {
          const params = {
            username: urlParams.username,
            verify_code: urlParams.code,
            password: formData.password
          }

          this.$store.dispatch('showLoading')
          this.axios
            .post(
              this.$store.state.apiUrl + 'auth/forgot-password/password-reset',
              params
            )
            .then((response) => {
              if (response.status == 200) {
                self.$message({
                  message: '重設密碼成功',
                  type: 'success'
                })

                this.$router.push({ name: 'login' })
              }
            })
            .catch((e) => {
              let response = e.response.data

              self.$message({
                message: '重設密碼失敗',
                type: 'error'
              })
            })
            .then(() => {
              this.$store.dispatch('hideLoading')
            })
        },
        () => {
          this.$message({
            message: '請輸入新密碼',
            type: 'error'
          })
        }
      )
    },
    verify() {
      let urlParams = this.$route.query

      this.$store.dispatch('showLoading')
      this.axios
        .post(this.$store.state.apiUrl + 'auth/forgot-password/verify-code', {
          username: urlParams.username,
          verify_code: urlParams.code
        })
        .then((response) => {
          if (response.status == 200) {
            this.formVerified = true
          }
        })
        .catch((e) => {
          this.$message({
            message: '憑證驗證失敗',
            type: 'error'
          })
        })
        .then(() => {
          this.$store.dispatch('hideLoading')
        })
    }
  },
  mounted() {
    this.verify()
  }
}
</script>
