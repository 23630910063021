<template>
  <div>
    <el-card class="sidebar-menu-box">
      <div slot="header" class="sidebar-menu-header">商品分類</div>
      <el-menu
        :default-active="$route.path"
        mode="vertical"
        :router="true"
        background-color="#fff"
        text-color="#333"
        class="sidebar-menu"
      >
        <template v-for="(service, i) in $props.serviceList">
          <el-menu-item
            v-if="!service.children"
            :index="`/${meta.servicePrefix}/${service.path}`"
            :key="i"
          >
            {{ service.name }}
          </el-menu-item>
          <el-submenu
            v-else
            :index="`/${meta.servicePrefix}/${service.path}`"
            :show-timeout="0"
            :hide-timeout="0"
            :key="i"
          >
            <template slot="title">{{ service.name }}</template>
            <el-menu-item
              v-for="(children, i) in service.children"
              :key="i"
              :index="`/${meta.servicePrefix}/${service.path}/${children.path}`"
            >
              {{ children.name }}
            </el-menu-item>
          </el-submenu>
        </template>
      </el-menu>
    </el-card>
  </div>
</template>

<style lang="scss" scoped>
.sidebar-menu-box {
  box-shadow: none !important;
  border-radius: 10px;
  .sidebar-menu-header {
    text-align: center;
  }
  .sidebar-menu {
    border-right: 0;
  }
}
</style>

<script>
import meta from "@/info/meta";

export default {
  data() {
    return {
      meta,
    };
  },
  props: {
    serviceList: Array,
  },
};
</script>