<template>
  <div class="form-order-detail">
    <p class="title">訂單資訊</p>
    <div v-if="$store.state.formService !== '購買時數'">
      <p>
        總預估時數：{{ predictTime }}小時 | {{ predictCleaner }}人{{
          predictTime / predictCleaner
        }}小時
      </p>
      <p>服務日期：{{ selectedTime?.date }}</p>
      <p>服務時段：{{ selectedTime?.timeLabel }}</p>
      <p v-if="selectedTime.timeLabel">清潔費用：{{ formPrice }}</p>
      <p>加購：{{ additionalPrice }}元</p>
      <p v-if="selectedTime.timeLabel && specialPrice !== 0">
        年前加價：{{ specialPrice }}元
      </p>
      <p v-if="couponAmount !== 0">折扣：{{ couponAmount }}元</p>
      <p v-if="selectedTime.timeLabel">
        總計：{{ formPrice - couponAmount + additionalPrice + specialPrice }}元
      </p>
      <p
        v-if="
          trafficDetail.traffic_expenses_status === 0 ||
          trafficDetail.traffic_expenses !== 0
        "
      >
        車馬費：{{
          trafficDetail.traffic_expenses
        }}元(本區域若有車馬費，會現場收取)
      </p>
    </div>
    <div v-else>
      <p>訂購內容：{{ timeProductName }}</p>
      <p>
        總計：{{ formPrice - couponAmount + additionalPrice + specialPrice }}
      </p>
    </div>
    <div v-if="$store.state.formService === '購買時數'">
      <p class="title">提醒</p>
      <p>※服務地址有車馬費,於每次服務現場收取</p>
      <p>※年前加價,依年度公告而定</p>
    </div>
    <div v-if="$store.state.formService !== '購買時數'">
      <p class="title">服務明細</p>
      <p>{{ step1.service }}</p>
      <p>室內總坪數：{{ sizeLabel }}</p>
      <p
        v-for="range in workRanges.filter((e) => e.number > 0)"
        :key="range.name"
      >
        {{ range.name }}：{{ range.number }}
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/variable.scss';
.form-order-detail {
  width: 400px;
  // margin-left: 20px;
  // margin-top: 12px;
  padding: 15px;
  background: $primaryColorBg;
  .title {
    font-weight: 500;
    text-align: center;
  }
}
@media screen and (max-width: 500px) {
  .form-order-detail {
    width: 100%;
    margin-left: 0px;
  }
}
</style>

<script>
export default {
  name: 'OrderDetail',
  data() {
    return {
      step1: {},
      predictCleaner: 0,
      predictTime: 0,
      sizeLabel: '',
      timeProductName: ''
    }
  },
  mounted() {
    this.getSessionData()
  },
  computed: {
    workRanges() {
      return this.$store.state.formWorkRange
    },
    workRangesNum() {
      return this.$store.state.formWorkRangeNum
    },
    formPrice() {
      return this.$store.state.formPrice
    },
    trafficDetail() {
      return this.$store.state.trafficDetail
    },
    selectedTime() {
      return this.$store.state.selectedTime
    },
    specialPrice() {
      return this.$store.state.specialPrice
    },
    additionalPrice() {
      return this.$store.state.additionalPrice
    },
    couponAmount() {
      return this.$store.state.couponAmount
    }
  },
  methods: {
    getSessionData() {
      this.step1 = JSON.parse(sessionStorage.getItem('step1'))
      if (this.$route.params.service == '購買時數') {
        const orderData = this.$store.state.timeProducts.find(
          (item) => item.id == this.step1.selectedTimeProduct
        )
        this.timeProductName = orderData.name
      } else {
        this.predictCleaner = window.sessionStorage.getItem('predictCleaner')
        this.predictTime = window.sessionStorage.getItem('predictTime')
        this.sizeLabel = this.$store.state.servicePys.find(
          (e) => e.code === this.step1.size
        ).description
      }
    }
  }
}
</script>
