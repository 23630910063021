<template>
  <div class="navigation" ref="nav">
    <div class="navigation-content">
      <div class="left">
        <router-link to="/">
          <div class="logo">
            <img src="@/assets/img/logo.png" alt="" srcset="" />
          </div>
        </router-link>
      </div>
      <div class="right">
        <div class="menu-toggle" v-if="$isMobile()">
          <button
            class="hamburger hamburger--arrow-r"
            v-bind:class="showMenu ? 'is-active' : ''"
            @click="showMenu = !showMenu"
            type="button"
          >
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button>
        </div>
        <el-menu
          :default-active="$route.path"
          :mode="$isMobile() ? 'vertical' : 'horizontal'"
          v-bind:class="showMenu ? 'show' : ''"
          :router="true"
          text-color="#4bc4dd"
          active-text-color="#f4732d"
        >
          <el-submenu
            index="/order"
            class="order-now"
            :show-timeout="0"
            :hide-timeout="0"
          >
            <template slot="title">立即預約</template>

            <template
              v-for="(service, i) in serviceList.filter(
                (item) => item.name != '購買時數'
              )"
            >
              <el-menu-item
                v-if="!service.children"
                :index="`/order#${service.name}`"
                :key="i"
                @click="
                  orderServiceNow(service.name, service.no, service.formLink)
                "
              >
                <span>{{ service.name }}</span>
              </el-menu-item>
              <el-submenu
                v-else
                :index="`/order#${service.name}`"
                :show-timeout="0"
                :hide-timeout="0"
                :key="i"
              >
                <template slot="title">{{ service.name }}</template>
                <el-menu-item
                  v-for="(children, i) in service.children"
                  :key="i"
                  :index="`/order#${service.name}${children.path}`"
                  @click="orderNow(service.name, children.name)"
                >
                  <a v-if="children.formLink" :href="children.formLink">{{
                    children.name
                  }}</a>
                  <span v-else>{{ children.name }}</span>
                </el-menu-item>
              </el-submenu>
            </template>
          </el-submenu>
          <el-submenu
            :index="`/${meta.servicePrefix}`"
            :show-timeout="0"
            :hide-timeout="0"
          >
            <template slot="title">服務項目</template>
            <template
              v-for="(service, i) in serviceList.filter(
                (item) => item.showCleanService
              )"
            >
              <el-menu-item
                v-if="!service.children"
                :index="`/${meta.servicePrefix}/${service.path}`"
                :key="i"
              >
                {{ service.name }}
              </el-menu-item>
              <el-submenu
                v-else
                :index="`/${meta.servicePrefix}/${service.path}`"
                :show-timeout="0"
                :hide-timeout="0"
                :key="i"
              >
                <template slot="title">{{ service.name }}</template>
                <el-menu-item
                  v-for="(children, i) in service.children"
                  :key="i"
                  :index="`/${meta.servicePrefix}/${service.path}/${children.path}`"
                >
                  {{ children.name }}
                </el-menu-item>
              </el-submenu>
            </template>
          </el-submenu>
          <el-menu-item :index="`/${meta.servicePrefix}/disinfect`">
            消毒服務
          </el-menu-item>
          <el-menu-item :index="`/${meta.servicePrefix}/other/ac_clean`">
            其他清潔
          </el-menu-item>
          <el-menu-item :index="`/${meta.servicePrefix}/hours`">
            購買時數
          </el-menu-item>
          <el-menu-item index="/#feature">特色</el-menu-item>
          <el-menu-item index="/team">加入寶實潔</el-menu-item>
          <el-submenu index="/#" :show-timeout="0" :hide-timeout="0">
            <template slot="title">關於</template>
            <el-menu-item index="/about">關於寶實潔</el-menu-item>
            <el-menu-item index="/#qa">Q&A</el-menu-item>
          </el-submenu>
          <el-menu-item index="/member">
            <template slot="title">
              <i class="el-icon-user-solid"></i>
              <span>會員中心</span>
            </template>
          </el-menu-item>
        </el-menu>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/style/variable.scss';
.el-menu--popup {
  padding: 0 !important;
}
.el-menu {
  border-bottom: 0 !important;
  .el-menu-item {
    padding: 0 14px;
    display: flex;
    align-items: center;
    &:hover {
      background-color: $primaryColor !important;
      color: #fff !important;
    }
    a {
      color: unset;
    }
  }
  .el-submenu {
    .el-submenu__title {
      padding: 0 14px;
      display: flex;
      align-items: center;
      &:hover {
        background-color: $primaryColor !important;
        color: #fff !important;
      }
    }

    &.order-now {
      .el-submenu__title {
        background: $primaryColor;
        color: #fff !important;

        i {
          color: #fff;
        }
        &:hover {
          background: $secondColor !important;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import '@/assets/style/variable.scss';

.navigation {
  width: 100%;
  height: $navigationHeight;
  padding: 20px 50px;
  box-sizing: border-box;
  align-items: center;
  position: relative;
  position: fixed;
  top: 0;
  left: 0%;
  z-index: 1000;
  transition: all 0.2s;
  background: #fff;

  &.active {
    background: #fff;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.2);
  }

  .navigation-content {
    width: 100%;
    max-width: 1500px;
    justify-content: space-between;
    display: flex;
    margin: 0 auto;
    margin-top: -10px;
    transition: all 0.8s;
    .left {
      display: flex;
      align-items: center;
      .logo {
        height: 50px;
        img {
          transition: all 0.2s;
          height: 100%;
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
    }
  }
}

@media screen and (max-width: 500px) {
  .navigation {
    width: 100%;
    height: $navigationHeight;
    padding: 0;
    box-sizing: border-box;
    align-items: center;
    position: relative;
    position: fixed;
    top: 0;
    left: 0%;
    z-index: 1000;
    transition: all 0.2s;
    background: #fff;

    &.active {
      background: #fff;
      box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.2);
    }

    .navigation-content {
      height: 100%;
      margin-top: 0;
      .left {
        height: 100%;
        .logo {
          height: 50px;
          img {
            transition: all 0.2s;
            height: 100%;
          }
        }
      }
      .right {
        height: 100%;
        .el-menu {
          position: fixed;
          right: 0;
          top: $navigationHeight - 1px;
          width: 100%;
          max-height: calc(100vh - #{$navigationHeight});
          overflow: auto;
          box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
          transform: translateX(100%);
          transition: all 0.3s ease-in-out;

          &.show {
            transform: translateX(0%);
            z-index: 50;
          }
        }
        .menu-toggle {
          transform: scale(0.7);
        }
      }
    }
  }
}
</style>

<style lang="scss">
@import '@/assets/style/variable.scss';
.el-menu-item {
  font-size: 16px !important;
  &:hover {
    color: $hoverSubColor !important;
  }
}
.el-submenu__title {
  font-size: 16px !important;
  &:hover {
    color: $hoverSubColor !important;
  }
}
</style>

<script>
import serviceList from '@/info/serviceList'
import meta from '@/info/meta'

export default {
  name: 'navigation',
  data() {
    return {
      serviceList,
      meta,
      defaultLinkHeight: 24,
      showMenu: this.$isMobile() ? false : true
    }
  },
  watch: {
    $route() {
      this.showMenu = false
    }
  },
  created() {},
  methods: {
    orderServiceNow(serviceName, serviceNo, formLink) {
      if (formLink) {
        window.location = formLink
      } else {
        this.$store.dispatch('getServicePys')
        this.$store.dispatch('getServiceDetail', serviceNo).then(() => {
          this.$router.push({
            name: 'order',
            params: { service: serviceName, serviceNo: serviceNo }
          })
        })
      }
    },
    orderNow(serviceName, subOrder) {
      this.$router.push({
        name: 'order',
        params: { service: serviceName }
      })
    }
  },
  mounted() {
    const self = this
    window.addEventListener('scroll', () => {
      let pos = window.scrollY
      if (pos > 150) {
        self.$refs.nav?.classList.add('active')
      } else {
        self.$refs.nav?.classList.remove('active')
      }
    })
  }
}
</script>
