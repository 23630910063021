<template>
  <div id="app">
    <Navigation />
    <router-view :key="$route.path"></router-view>
    <Footer v-if="$route.name !== 'order'" />
    <Loading />
  </div>
</template>

<style>
.el-message {
  white-space: pre-wrap !important;
}
</style>

<script>
import Navigation from '@/components/layout/navgation.vue'
import Footer from '@/components/layout/footer.vue'
import Loading from '@/components/loading.vue'

export default {
  components: {
    Navigation,
    Footer,
    Loading
  },
  mounted() {
    this.$store.dispatch('checkUserData')
  }
}
</script>
