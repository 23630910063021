<template>
  <div class="home">
    <Banner />
    <Services />
    <Feature />
    <Step />
    <CommentList />
    <About />
    <!-- <Alert /> -->
  </div>
</template>

<style lang="scss">
@import "@/assets/style/variable.scss";

.home {
  margin-top: $navigationHeight;
  .section {
    //max-width: 80%;
    margin: 0 auto;
    max-width: 1900px;

    h1 {
      display: inline-block;
      color: $primaryColor;
      margin: 100px 0;
      text-align: center;
      font-weight: bold;
      letter-spacing: 5px;
      position: relative;
      z-index: 1;
      left: 50%;
      transform: translateX(-50%) skewX(-6deg);

      &::before {
        content: "";
        width: 10vw;
        background: $primaryColor;
        height: 1px;
        position: absolute;
        left: -15vw;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;
      }

      &::after {
        content: "";
        width: 10vw;
        background: $primaryColor;
        height: 1px;
        position: absolute;
        right: -15vw;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;
      }
    }

    &.w1200 {
      max-width: 1200px;
    }
  }
}

@media screen and (max-width: 500px) {
  .home {
    margin-top: $navigationHeight;
    .section {
      //max-width: 80%;
      margin: 0 auto;
      max-width: 1900px;

      h1 {
        transform: translateX(-50%) skewX(-6deg) scale(0.9);
        margin: 50px 0;

        &::before {
          content: none;
        }

        &::after {
          content: none;
        }
      }

      &.w1200 {
        max-width: 1200px;
      }
    }
  }
}
</style>

<script>
import Banner from "@/components/home/banner.vue";
import Services from "@/components/home/services.vue";
import Feature from "@/components/home/feature.vue";
import Step from "@/components/home/step.vue";
import CommentList from "@/components/home/comment.vue";
import About from "@/components/home/about.vue";
// import Alert from "@/components/home/alert.vue";

export default {
  name: "Home",
  components: {
    Banner,
    Services,
    Feature,
    Step,
    CommentList,
    About,
    // Alert,
  },
  mounted() {
    setTimeout(() => this.scrollFix(this.$route.hash), 1);
  },
  methods: {
    scrollFix(hashbang) {
      location.hash = hashbang;
    },
  },
};
</script>
