<template>
  <div class="footer">
    <div class="footer-content">
      <div>
        <h3>BabyClean寶實潔</h3>
        <p>想找清潔，交給寶實潔</p>
        <div class="links">
          <a
            href="https://www.facebook.com/BabyCleanClean"
            class="link"
            target="_blank"
          >
            <span>Facebook</span>
          </a>
          <a href="https://lin.ee/sflyMeO" class="link" target="_blank">
            <span>LINE</span>
          </a>
        </div>
      </div>
      <div>
        <h3>聯絡方式</h3>
        <p>
          TEL：02-2771-1316<br />
          E-mail：service@babyclean.com.tw<br />
          ADD：台北市大安區仁愛路四段137號6樓之1<br />
          LINE加好友：@babyclean <br />
          客服時間：週一至週五09:00-18:00
        </p>
      </div>
      <div>
        <div
          class="fb-page"
          data-href="https://www.facebook.com/%E5%AF%B6%E5%AF%A6%E6%BD%94-Baby-Clean-109729197057151"
          data-width="280"
          data-hide-cover="false"
          data-show-facepile="false"
          lazy="true"
        ></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #23282d;
  height: 20vw;
  margin-top: 5vw;
  .footer-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    color: #fff;
    position: relative;
    width: 80%;

    div {
      &:nth-child(1) {
        h3 {
          font-weight: 500;
          margin-top: 0;
        }
        p {
          font-weight: 100;
        }
        .links {
          margin-top: 10px;
          display: flex;
          .link {
            color: #fff;
            font-size: 14px;
            margin-right: 6px;
            font-size: 100;
            border-right: 1px solid #fff;
            padding-right: 6px;
            &:last-child {
              padding-right: 0;
              margin-right: 0;
              border: 0;
            }
          }
        }
      }
      &:nth-child(2) {
        h3 {
          margin-top: 0;
        }
        p {
          font-size: 14px;
          line-height: 2;
        }
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .footer {
    height: auto;
    .footer-content {
      padding: 30px 0;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      row-gap: 30px;
      width: 80%;
    }
  }
}
</style>
<script>
export default {}
</script>
