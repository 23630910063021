<template>
  <div class="join">
    <el-collapse
      v-model="activeItem"
      @change="handleChange"
      class="join-collapse"
    >
      <el-collapse-item
        :name="occupation.id"
        v-for="occupation in occupations"
        :key="occupation.id"
        class="collapse-item"
      >
        <template slot="title">
          <div v-html="occupation.title" class="job-title" />
        </template>
        <div v-html="occupation.brief_description"></div>
        <div v-html="occupation.description" />
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<style lang="scss">
@import '@/assets/style/variable.scss';
.join {
  width: 100%;
  max-width: 1200px;
  margin: $navigationHeight auto 20px;
  height: auto;
  padding: 5vw 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .join-collapse {
    width: 100%;
    border: none;
    .collapse-item {
      margin: 10px 0;
      .el-collapse-item__header {
        border: 1px solid #ebeef5 !important;
        padding: 10px;
        font-size: 16px;
        .el-collapse-item__arrow {
          color: #909399;
        }
      }
      .el-collapse-item__content {
        margin-top: 10px;
        border: 1px solid #ebeef5 !important;
        padding: 10px;
      }
    }
  }
}
</style>

<script>
export default {
  name: 'Join',
  data() {
    return {
      activeItem: ''
    }
  },
  computed: {
    occupations() {
      return this.$store.state.occupations
    }
  },
  watch: {
    occupations() {
      this.activeItem = this.occupations[0].id
    }
  },
  created() {
    this.$store.dispatch('getOccupations')
  },
  methods: {
    handleChange(val) {}
  }
}
</script>
