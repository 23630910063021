<template>
  <div>
    <el-steps
      :active="$store.state.formStep - 1"
      finish-status="success"
      v-if="$store.state.formStep <= 4 && !$isMobile()"
      simple
    >
      <el-step
        v-for="(step, i) in steps.filter(
          (item) => item.passServices.indexOf($store.state.formService) == -1
        )"
        :title="step.title"
        :key="i"
      ></el-step>
    </el-steps>

    <el-steps
      :active="$store.state.formStep - 1"
      finish-status="success"
      v-else-if="$store.state.formStep <= 4 && $isMobile()"
      align-center
    >
      <el-step
        v-for="(step, i) in steps.filter(
          (item) => item.passServices.indexOf($store.state.formService) == -1
        )"
        :title="step.title"
        :key="i"
      ></el-step>
    </el-steps>
  </div>
</template>

<script>
export default {
  name: 'Progress',
  data() {
    return {
      steps: [
        {
          title: '預約資料',
          passServices: []
        },
        {
          title: '預約時間',
          passServices: [
            '其他清潔',
            '防疫消毒',
            '購買時數',
            '除蟎清潔',
            '冷氣清潔',
            '地毯清潔',
            '床墊清潔',
            '沙發清潔',
            '洗地打蠟'
          ]
        },
        {
          title: '客戶資料',
          passServices: []
        },
        {
          title: '訂單確認',
          passServices: [
            '營業場所清潔',
            '大掃除清潔',
            '裝潢細清',
            '其他清潔',
            '防疫消毒',
            '除蟎清潔',
            '冷氣清潔',
            '地毯清潔',
            '床墊清潔',
            '沙發清潔',
            '洗地打蠟'
          ]
        }
      ]
    }
  }
}
</script>
