<template>
  <div class="register">
    <div class="register-form">
      <h1>會員註冊</h1>

      <form-create
        :rule="rule"
        v-model="fApi"
        :option="options"
        :value.sync="value"
      />

      <el-divider></el-divider>

      <el-row type="flex" justify="center">
        <el-button plain type="info" @click="$router.go(-1)">返回</el-button>
        <el-button plain type="success" @click="send()">註冊</el-button>
      </el-row>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/style/variable.scss';

.register {
  margin-top: $navigationHeight;
  height: auto;
  padding: 5vw 0;
  display: flex;
  align-items: center;
  .register-form {
    width: 30%;
    margin-top: -10%;
    margin: 0 auto;
    h1 {
      margin-bottom: 50px;
      color: $primaryColor;
    }
    .social-login {
      width: 100%;
      margin-bottom: 50px;
      i {
        margin-right: 8px;
      }
      button {
        width: calc(100% / 3);
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .register {
    margin-top: $navigationHeight;
    height: auto;
    padding: 5vw 0;
    display: flex;
    align-items: center;
    .register-form {
      width: 90%;
      margin-top: -10%;
      margin: 0 auto;
      h1 {
        text-align: center;
      }
      .social-login {
        width: 100%;
        margin-bottom: 50px;
        i {
          margin-right: 8px;
        }
        button {
          width: calc(100% / 3);
        }
      }
    }
  }
}
</style>

<script>
export default {
  name: 'Register',
  components: {},
  created() {
    if (this.$route.params.encrypted !== undefined) {
      let buff = Buffer.from(this.$route.params.encrypted, 'base64')
      let mail = buff.toString('ascii')
      const field = this.rule.find((r) => r.field === 'email')
      field && (field.value = mail)
    }
  },
  data() {
    const self = this

    return {
      fApi: {},
      value: {},
      options: {
        form: {
          labelPosition: 'left',
          labelWidth: this.$isMobile() ? '100px' : ''
        },
        submitBtn: {
          show: false
        },
        onSubmit: (formData) => {
          alert(JSON.stringify(formData))
        }
      },
      rule: [
        {
          type: 'el-col',
          children: [
            {
              type: 'el-divider',
              children: ['帳號']
            }
          ]
        },
        {
          type: 'input',
          field: 'account',
          title: '帳號',
          value: '',
          validate: [
            { required: true, message: '此欄位必填' },
            {
              validator: (rule, val, d) => {
                val && !/^[a-z 0-9]+$/.test(val) ? d(true) : d()
              },
              message: '只能使用英文或數字'
            },
            { type: 'string', max: 8, message: '最多8個字元' }
          ],
          props: {
            placeholder: '輸入帳號',
            clearable: true,
            size: 'large',
            minlength: 5
          }
        },
        {
          type: 'el-col',
          children: [
            {
              type: 'el-row',
              class: 'notice',
              children: ['「企業會員」建議使用統編']
            }
          ]
        },
        {
          type: 'el-col',
          children: [
            {
              type: 'el-row',
              class: 'notice',
              children: [
                '「個人用戶」可自行設定，含英文數字最多有8個字，不可含特殊符號'
              ]
            }
          ]
        },

        // {
        //   type: "input",
        //   field: "email",
        //   title: "Email",
        //   value: "",
        //   validate: [
        //     { required: true, message: "此欄位必填" },
        //     { type: "email", message: "無效的 Email" },
        //   ],
        //   props: {
        //     placeholder: "請輸入 Email",
        //     clearable: true,
        //     size: "large",
        //   },
        // },
        {
          type: 'el-col',
          children: [
            {
              type: 'el-divider',
              children: ['會員資料']
            }
          ]
        },
        {
          type: 'input',
          field: 'name',
          title: '會員名稱',
          value: '',
          validate: [{ required: true, message: '此欄位必填' }],
          props: {
            placeholder: '客戶姓名或公司名稱、飯店名稱',
            clearable: true,
            size: 'large'
          }
        },
        {
          type: 'input',
          field: 'address',
          title: '地址',
          value: '',
          validate: [{ required: true, message: '此欄位必填' }],
          props: {
            placeholder: '請輸入地址',
            clearable: true,
            size: 'large'
          }
        },
        {
          type: 'input',
          field: 'email',
          title: 'email',
          validate: [
            { required: true, message: '此欄位必填' },
            { type: 'email', message: '無效的email' }
          ],
          value: '',
          props: {
            placeholder: '輸入email',
            clearable: true,
            size: 'large',
            minlength: 5
          }
        },
        {
          type: 'input',
          field: 'phone',
          title: '電話',
          validate: [{ required: true, message: '此欄位必填' }],
          value: '',
          props: {
            placeholder: '請輸入電話',
            clearable: true,
            size: 'large',
            type: 'tel',
            maxlength: 10
          }
        },
        {
          type: 'el-col',
          children: [
            {
              type: 'el-divider',
              children: ['聯絡人資料']
            }
          ]
        },
        {
          type: 'input',
          field: 'contactName',
          title: '聯絡人姓名',
          value: '',
          validate: [],
          props: {
            placeholder: '請填寫全名',
            clearable: true,
            size: 'large'
          }
        },
        {
          type: 'input',
          field: 'contact_phone',
          title: '聯絡電話',
          validate: [],
          value: '',
          props: {
            placeholder: '請輸入聯絡電話',
            clearable: true,
            size: 'large',
            type: 'tel',
            maxlength: 10
          }
        },
        {
          type: 'input',
          field: 'contact_address',
          title: '聯絡地址',
          value: '',
          validate: [],
          props: {
            placeholder: '請輸入聯絡地址',
            clearable: true,
            size: 'large'
          }
        }
        // {
        //   type: "checkbox",
        //   title: "隱私條款",
        //   field: "terms",
        //   validate: [{ required: true, message: "此欄位必填" }],
        //   value: [],
        //   props: {
        //     max: 1,
        //     min: 0,
        //   },
        //   options: [{ value: "terms", label: "我已看過並同意隱私條款" }],
        // },
      ]
    }
  },
  methods: {
    send() {
      const self = this

      this.fApi.submit(
        (formData, fapi) => {
          const payload = {
            username: formData.account,
            name: formData.name,
            address: formData.address,
            email: formData.email,
            phone: formData.phone,
            type: 'one',
            contact_name: [formData.contactName],
            contact_position: [formData.contactName],
            contact_mobile: [formData.contact_phone],
            contact_address: [formData.contact_address]
          }

          self.$store.dispatch('register', payload)
        },
        () => {
          self.$message({
            message: '資料格式錯誤',
            type: 'error'
          })
        }
      )
    }
  }
}
</script>

<style>
.notice {
  font-size: 12px;
  color: #44af71;
}
</style>
