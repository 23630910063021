<template>
  <div class="finish-order">
    <Finish :key="currentService" />
  </div>
</template>


<style lang="scss" scoped>
@import "@/assets/style/variable.scss";
.finish-order {
  margin-top: $navigationHeight !important;
  height: auto;
  padding: 10vw 5vw 5vw 5vw;
  max-width: 1200px;
  margin: 0 auto;
}
</style>

<script>
import Finish from "@/components/order/Finish.vue";

export default {
  name: "order",
  props: {
    service: String,
  },
  components: {
    Finish,
  },
  data() {
    return {};
  },
  watch: {},
  created() {},
};
</script>
