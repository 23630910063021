<template>
  <div class="order">
    <OrderList></OrderList>
    <!-- <template
      v-for="(orderData, index) in orderList.slice(
        (currentPage - 1) * pagesize,
        currentPage * pagesize
      )"
    >
    </template> -->
    <el-empty
      description="目前沒有訂單"
      v-if="orderList.length == 0"
    ></el-empty>
    <!-- <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[1, 10, 20, 40]"
      :page-size="pagesize"
      layout="prev, pager, next"
      :total="orderList.length"
    >
    </el-pagination> -->
  </div>
</template>
<script>
import OrderList from '@/views/OrderList.vue'
export default {
  components: {
    OrderList
  },
  data() {
    return {
      orders: [],
      orderNew: [],
      orderList: [],
      openOrderDetail: false,
      openOrderDetailData: null,
      openRateDialog: false,
      rateCol: this.$isMobile() ? 24 : 12,
      rateDialogData: {
        order_id: '',
        point: null,
        content: '',
        menu: {
          on_time: '1',
          communicate: '1',
          attitude: '1',
          uniform: '1',
          check: '1'
        }
      },
      repay: '',
      currentPage: 1,
      pagesize: 3,
      ecpayHtml: ''
    }
  },
  created() {
    // this.getOrders()
  },
  methods: {
    sendRate(id) {
      if (!this.rateDialogData.point || !this.rateDialogData.content) {
        this.$message({
          message: '請填寫評分與內容',
          type: 'warning'
        })

        return
      }

      this.$store.dispatch('showLoading')
      this.axios
        .patch(
          this.$store.state.apiUrl +
            'ordernew/' +
            this.rateDialogData.order_id +
            '/comment',
          {
            point: this.rateDialogData.point,
            content: this.rateDialogData.content,
            menu: this.rateDialogData.menu
          }
        )
        .then((response) => {
          if (response.status == 200) {
            this.$message({
              message: '評論成功！',
              type: 'success'
            })
            let index = this.orderList.findIndex(
              (d) =>
                d.id === this.rateDialogData.order_id && d.order_check === 'new'
            )
            this.orderList[index].point = this.rateDialogData.point
          }
        })
        .catch((e) => {
          this.$message({
            message: '憑證錯誤，請重新登入',
            type: 'error'
          })
        })
        .then(() => {
          this.$store.dispatch('hideLoading')
          //   this.rateDialogData = {
          //     point: null,
          //     content: "",
          //   };
        })
      this.openRateDialog = false
    },
    showOrderDetail(id) {
      let index = this.orderList.findIndex(
        (d) => d.id === id && d.order_check === 'new'
      )
      let data = this.orderList[index]

      let dayList = ['日', '一', '二', '三', '四', '五', '六']
      let day = dayList[new Date(data.reserve_date).getDay()]
      this.openOrderDetailData = [
        [
          {
            name: '訂購人姓名',
            value: data.name
          },
          {
            name: '服務地址',
            value: data.address
          },
          {
            name: '電話',
            value: data.mobile
          },
          {
            name: '備註',
            value: data.remark['order_remark']
          },
          {
            name: 'E-mail',
            value: data.email
          }
        ],
        [
          {
            name: '訂購日期',
            value: data.created_at
          },
          {
            name: '室內總坪數',
            value: data.work_pys_data
          },
          {
            name: '訂單編號',
            value: data.no
          },
          {
            name: '服務內容',
            value: data.work_items
              .filter(function (item) {
                return item.number > 0
              })
              .map((item) => {
                return item.name + '*' + item.number
              })
              .toString()
          },
          {
            name: '服務項目',
            value: data.title
          },
          {
            name: '總時數',
            value:
              data.total_hour +
              '小時 | ' +
              data.people +
              '人' +
              data.hour +
              '小時'
          }
        ],
        [
          {
            name: '服務日期',
            value: data.reserve_date + '[' + day + ']'
          },
          {
            name: '服務時段',
            value: data.reserve_stime + '-' + data.reserve_etime
          }
        ],
        [
          {
            name: '匯款/刷卡金額',
            value: data.total_amount
          },
          {
            name: '付款方式',
            value: data.payment_method_name
          },
          {
            name: '總付金額',
            value: data.payment_amount
          },
          {
            name: '付款狀態',
            value: data.order_status_name
          }
        ]
      ]
      this.openOrderDetail = true
      //   console.log(this.openOrderDetailData);
      //   console.log(data);
      //   console.log(id);
      //   this.getDetail(id);
      //   this.openOrderDetailData = this.orders.find((item) => item.id == id);
    },
    orderPayment(id) {
      const self = this

      this.$store.dispatch('showLoading')
      this.axios
        .post(this.$store.state.apiUrl + 'ordernew/repay', { id: id })
        .then((response) => {
          if (response.status == 200) {
            this.ecpayHtml = response.data

            // this.repay = response.data.data;
            setTimeout(() => {
              document.getElementById('__ecpayForm').submit()
            }, 0)
          }
        })
        .catch((e) => {
          this.$message({
            message: '憑證錯誤，請重新登入',
            type: 'error'
          })
        })
        .then(() => {
          setTimeout(() => {
            this.$store.dispatch('hideLoading')
          }, 100)
        })
    },
    handleSizeChange: function (size) {
      this.pagesize = size
    },
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage
    },
    openMarkDialog(id) {
      this.rateDialogData.order_id = id
      this.$store.dispatch('showLoading')
      this.axios
        .get(this.$store.state.apiUrl + 'ordernew/' + id + '/comment')
        .then((response) => {
          if (response.status == 200) {
            let defaultData = {
              order_id: id,
              point: null,
              content: '',
              menu: {
                on_time: '1',
                communicate: '1',
                attitude: '1',
                uniform: '1',
                check: '1'
              }
            }
            this.rateDialogData = response.data ? response.data : defaultData
            this.openRateDialog = true
          }
        })
        .catch((e) => {
          this.$message({
            message: '憑證錯誤，請重新登入',
            type: 'error'
          })
        })
        .then(() => {
          this.$store.dispatch('hideLoading')
        })
    },
    getOrders() {
      const self = this

      this.$store.dispatch('showLoading')
      this.axios
        .get(this.$store.state.apiUrl + 'orders')
        .then((response) => {
          if (response.status == 200) {
            this.orderList = response.data.data.orderList
          }
        })
        .catch((e) => {
          this.$message({
            message: '憑證錯誤，請重新登入',
            type: 'error'
          })
        })
        .then(() => {
          this.$store.dispatch('hideLoading')
        })
    }
  }
}
</script>
