<template>
  <div class="swipe">
    <swiper
      ref="mainSwiper"
      :options="swiperOptions"
      class="swiper-main"
      @slideChangeTransitionEnd="slideChange"
    >
      <swiper-slide v-for="(img, i) in $props.slides" :key="i">
        <img :src="img" alt="" />
      </swiper-slide>
    </swiper>
    <swiper
      ref="thumbsSwiper"
      :options="ThumbsSwiperOption"
      class="swiper-thumbs"
      @click-slide="thumbsClick"
    >
      <swiper-slide
        v-for="(img, i) in $props.slides"
        :key="i"
        v-bind:class="{ active: activeIndex == i }"
      >
        <img :src="img" alt="" />
      </swiper-slide>
    </swiper>
  </div>
</template>

<style lang="scss" scoped>
.swipe {
  border: 1px solid #eee;
  border-radius: 10px;
  overflow: hidden;
  .swiper-main {
    .swiper-slide {
      height: auto;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      margin-bottom: 20px;
      img {
        max-height: 100%;
        max-width: 100%;
      }
    }
  }
  .swiper-thumbs {
    .swiper-slide {
      width: 100px;
      height: 100px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      filter: brightness(30%);
      transition: all 0.3s;
      overflow: hidden;
      img {
        height: 110%;
      }
      &.active {
        filter: brightness(100%);
      }
      &:hover {
        filter: brightness(100%);
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .swipe {
    border: none;
    border-radius: 0;
    margin: -20px;

    .swiper-main {
      .swiper-slide {
        align-items: center;
      }
    }
  }
}
</style>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import Swiper2, { Pagination, Autoplay } from "swiper";
Swiper2.use([Pagination, Autoplay]);

export default {
  props: {
    slides: Array,
  },
  data() {
    return {
      swiperOptions: {
        loop: false,
        spaceBetween: 50,
      },
      ThumbsSwiperOption: {
        loop: false,
        centeredSlides: false,
        slideToClickedSlide: true,
        spaceBetween: 3,
        breakpoints: {
          300: { slidesPerView: 3 },
          500: { slidesPerView: 4 },
        },
      },
      activeIndex: 0
    };
  },
  computed: {
    mainSwiper() {
      return this.$refs.mainSwiper.$swiper;
    },
    thumbsSwiper() {
      return this.$refs.thumbsSwiper.$swiper;
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {
    slideChange(slide) {
      this.thumbsSwiper.slideTo(slide.activeIndex, 100, false);
      this.activeIndex = slide.activeIndex;
    },
    thumbsClick(slide) {
      this.activeIndex = slide;
      this.mainSwiper.slideTo(slide, 100, false);
    },
  },
};
</script>