import { render, staticRenderFns } from "./Service8.vue?vue&type=template&id=2b7b8416&scoped=true&"
import script from "./Service8.vue?vue&type=script&lang=js&"
export * from "./Service8.vue?vue&type=script&lang=js&"
import style0 from "./Service8.vue?vue&type=style&index=0&id=2b7b8416&prod&lang=scss&scoped=true&"
import style1 from "./Service8.vue?vue&type=style&index=1&id=2b7b8416&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b7b8416",
  null
  
)

export default component.exports