<template>
  <div class="section section2 w1200" id="services">
    <h1>服務項目</h1>
    <div class="services">
      <template>
        <router-link
          v-for="service in serviceList"
          :key="service.name"
          :to="'/' + meta.servicePrefix + '/' + service.path"
        >
          <div class="service-item-content">
            <div class="service-item">
              <div
                class="service-img"
                :style="{ backgroundImage: 'url(' + service.mainImg + ')' }"
              ></div>
              <div class="service-name" v-html="service.name"></div>
            </div>
          </div>
        </router-link>
      </template>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "@/assets/style/variable.scss";
.section2 {
  .services {
    position: relative;
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 35px;

    .service-item-content {
      box-sizing: border-box;
      .service-item {
        height: 100%;
        background: #fff;
        box-shadow: 2px 5px 10px rgba($color: #000, $alpha: 0.1);
        border-radius: 20px 20px 10px 10px;
        box-sizing: border-box;
        transition: all 1s;
        overflow: hidden;
        position: relative;
        border: 1px solid rgba($color: #000000, $alpha: 0.2);
        &:hover {
          box-shadow: 2px 15px 15px rgba($color: #000, $alpha: 0.25);
          transform: translateY(-7px);
          transform-origin: center;
          .service-name {
            transform: scale(1.2);
          }
          .service-img {
            transform: scale(1.2);
            filter: brightness(100%);
          }
        }

        .service-name {
          position: absolute;
          right: 1vw;
          bottom: 1vw;
          font-size: 18px;
          color: #fff;
          letter-spacing: 2px;
          transition: all 0.2s;
          transform-origin: right bottom;
          background: rgba(0, 0, 0, 0.4);
          padding: 4px 8px;
          border-radius: 5px;
          &::first-letter {
            font-size: 22px;
          }
        }
        .service-img {
          width: 100%;
          height: 0;
          padding-bottom: 100%;
          background-size: cover;
          background-position: center;
          margin: 0 auto;
          transition: all 0.2s;
          filter: brightness(80%);
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .section2 {
    .services {
      position: relative;
      width: 100%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 35px;
      padding: 20px;

      .service-item-content {
        box-sizing: border-box;
        .service-item {
          height: 100%;
          background: #fff;
          box-shadow: 2px 5px 10px rgba($color: #000, $alpha: 0.1);
          border-radius: 20px 20px 10px 10px;
          box-sizing: border-box;
          transition: all 1s;
          overflow: hidden;
          position: relative;
          border: 1px solid rgba($color: #000000, $alpha: 0.2);
          &:hover {
            box-shadow: 2px 15px 15px rgba($color: #000, $alpha: 0.25);
            transform: translateY(-7px);
            transform-origin: center;
            .service-name {
              transform: scale(1.2);
            }
            .service-img {
              transform: scale(1.2);
              filter: brightness(100%);
            }
          }

          .service-name {
            right: 15px;
            bottom: 15px;
            font-size: 18px;
            &::first-letter {
              font-size: 22px;
            }
          }
          .service-img {
            width: 100%;
            height: 250px;
            background-size: cover;
            background-position: center;
            margin: 0 auto;
            transition: all 0.2s;
            filter: brightness(80%);
          }
        }
      }
    }
  }
}
</style>
<script>
import serviceList from "@/info/serviceList";
import meta from '@/info/meta'

export default {
  name: "Service",
  data() {
    return {
      serviceList,
      meta,
    };
  },
  mounted() {
    this.serviceList = this.serviceList.filter((item) => item.showHome);
  },
};
</script>
