<template>
  <div class="calender-block">
    <p>
      <el-alert
        title="每月15日可預約下個月"
        type="warning"
        center
        show-icon
        class="mt mb"
      >
      </el-alert>
    </p>

    <v-calendar
      class="custom-calendar"
      :masks="masks"
      :attributes="attributes"
      :min-date="new Date()"
      :max-date="lastDay"
      is-expanded
      ref="calendar"
    >
      <template v-slot:day-content="{ day, attributes }">
        <div class="day-block">
          <div
            class="day-d day-prev"
            v-if="dayjs(day.date).unix() > dayjs().unix()"
            @click="resetAvalible()"
          >
            {{ day.day }}
          </div>
          <div class="day-d day-normal" v-else @click="resetAvalible()">
            {{ day.day }}
          </div>
          <div v-for="(attr, i) in attributes" :key="i">
            <!-- <div
              class="day-d day-full day-prev"
              v-if="dayjs(day.date).unix() < dayjs().unix()"
            >
              {{ day.day }}
            </div> -->
            <div
              class="day-d day-not-avalible"
              v-if="
                dayjs(day.date).unix() > dayjs().unix() &&
                attr.customData.status == 0
              "
            >
              {{ day.day }}
            </div>
            <div
              class="day-d day-full"
              v-else-if="
                dayjs(day.date).unix() > dayjs().unix() &&
                attr.customData.status == 2
              "
            >
              {{ day.day }}
            </div>
            <div
              class="day-d day-avalible"
              :id="'day-' + day.day"
              v-else-if="attr.customData.status == 1"
              @click="setAvalibleTime(attr, attr.dates, day.day)"
            >
              {{ day.day }}
            </div>
          </div>
        </div>
      </template>
    </v-calendar>

    <div class="avalible-time" v-if="avalibleTime.length > 0">
      <h3>請選擇時段 或 直接下一步</h3>
      <div class="avalible-time-selection">
        <template v-for="(time, i) in avalibleTime">
          <el-button
            class="time-selection"
            :id="'time-selection-' + time.time_period_id"
            round
            type="info"
            :key="i"
            @click="
              setParentSelectedDate(
                time.time_period_id,
                time.time_period_title,
                time.employee_schedule_ids
              )
            "
            >{{ time.time_period_title }}</el-button
          >
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/style/variable.scss';

$dayWidth: 40px;
.calender-block {
  width: 100%;
}
.custom-calendar {
  .vc-header {
    padding-bottom: 20px;
  }
  .vc-day {
    padding: 8px;
    .day-block {
      position: relative;
      min-height: 40px;
      display: flex;
      align-items: flex-start;
      justify-content: center;

      &:hover {
        .day-avalible-time {
          transform: translateY(-4px);
        }
        .day-text {
          transform: scale(1.1);
        }
      }
      .day-d {
        width: $dayWidth;
        height: $dayWidth;
        text-align: center;
        line-height: $dayWidth;
        border-radius: 100%;
        font-weight: bold;
        transition: all 0.2s;
      }
      .day-text {
        position: relative;
        background: $primaryColor;
        color: #fff;
        z-index: 0;

        &:hover {
          opacity: 0.8;
          cursor: pointer;
          transform: scale(1.1);
          color: #333;
        }
      }
      .day-normal {
        position: absolute;
        left: 50%;
        top: 0%;
        transform: translate(-50%, 0%);
        z-index: 1;
        background: rgb(110, 110, 110);
        color: #333;
        z-index: 1;
        &:hover {
          cursor: not-allowed;
        }
      }
      .day-full {
        position: absolute;
        left: 50%;
        top: 0%;
        transform: translate(-50%, 0%);
        z-index: 1;
        background: rgb(255, 161, 161);
        color: #333;
        z-index: 1;
        &:hover {
          cursor: not-allowed;
        }
      }
      .day-not-avalible {
        position: absolute;
        left: 50%;
        top: 0%;
        transform: translate(-50%, 0%);
        z-index: 1;
        background: rgb(236, 227, 142);
        color: #333;
        z-index: 1;
        &:hover {
          cursor: not-allowed;
        }
      }
      .day-prev {
        position: absolute;
        left: 50%;
        top: 0%;
        transform: translate(-50%, 0%);
        z-index: 2;
        background: rgb(226, 226, 226);
        color: #333;
        z-index: 1;
        color: transparent;
        &::before {
          position: absolute;
          left: 0;
          content: 'X';
          width: 40px;
          height: 40px;
          color: #fff;
        }
        &:hover {
          cursor: not-allowed;
        }
      }
      .day-today {
        position: absolute;
        left: 50%;
        top: 0%;
        transform: translate(-50%, 0%);
        z-index: 1;
        background: $secondColor;
        color: #fff;
        z-index: 2;
        &:hover {
          cursor: not-allowed;
        }
      }
      .day-avalible {
        position: absolute;
        left: 50%;
        top: 0%;
        transform: translate(-50%, 0%);
        z-index: 5;
        background: $primaryColor;
        color: #fff;

        &:hover {
          cursor: pointer;
        }
        &.active {
          background: $secondColor;
        }
      }
      .day-avalible-time {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 4px;
        font-size: 12px;
        text-align: center;
        color: #fff;
        padding: 4px;
        border-radius: 5px;
        background: rgb(148, 148, 148);
        line-height: 1.2;
        transition: all 0.2s;
      }
    }
  }
}

.avalible-time {
  margin-top: 50px;
  position: relative;
  h3 {
    text-align: center;
  }
  .avalible-time-selection {
    display: flex;
    align-items: center;
    justify-content: center;
    .time-selection.active {
      background: $secondColor;
      border-color: $secondColor;
    }
  }
}

@media screen and (max-width: 500px) {
  $dayWidth: 30px;

  .custom-calendar {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    .vc-header {
      padding-bottom: 20px;
    }
    .vc-day {
      padding: 8px;
      .day-block {
        min-height: 50px;
        .day-d {
          width: $dayWidth;
          height: $dayWidth;
          line-height: $dayWidth;
        }
        .day-avalible-time {
          padding: 0;
          background: transparent;
          .aval-dot {
            position: relative;
            width: 5px;
            height: 5px;
            background: grey;
            display: inline-block;
            margin: 0 1px;
            border-radius: 100%;
          }
        }
      }
    }
  }

  .avalible-time {
    margin-top: 50px;
    position: relative;
    h3 {
      text-align: center;
    }
    .avalible-time-selection {
      flex-direction: column;
      .el-button {
        margin: 5px 0;
      }
    }
  }
}
</style>

<script>
import dayjs from 'dayjs'

export default {
  name: 'Calendar',
  data() {
    return {
      dayjs,
      masks: {
        weekdays: 'WWW'
      },
      attributes: [],
      avalibleTime: [],
      selectedDate: '',
      today: new Date(),
      firstDay: new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    }
  },
  methods: {
    resetAvalible() {
      this.avalibleTime = []
      this.selectedDate = ''
      this.$emit('setSelectedDate', {
        date: this.selectedDate[0].start.toLocaleDateString(),
        timeId: 0,
        timeLabel: ''
      })
    },
    setAvalibleTime(attr, selectedDate, day) {
      if (this.selectedDate) {
        this.$emit('setSelectedDate', {
          date: selectedDate[0].start.toLocaleDateString(),
          timeId: 0,
          timeLabel: ''
        })
        document.querySelectorAll('.time-selection').forEach((element) => {
          element.classList.remove('active')
        })
      }
      this.avalibleTime = attr.customData.avalible
      this.selectedDate = selectedDate
      document.querySelectorAll('.day-avalible').forEach((element) => {
        element.classList.remove('active')
      })
      document.getElementById('day-' + day).classList.add('active')
      setTimeout(() => {
        document
          .querySelector('.avalible-time')
          .scrollIntoView({ behavior: 'smooth', block: 'center' })
      }, 100)
    },
    setParentSelectedDate(timeId, timeLabel, employee_schedule_ids) {
      document.querySelectorAll('.time-selection').forEach((element) => {
        element.classList.remove('active')
      })
      document
        .getElementById('time-selection-' + timeId)
        .classList.add('active')
      this.$emit('setSelectedDate', {
        date: this.selectedDate[0].start.toLocaleDateString(),
        timeId: timeId,
        timeLabel: timeLabel,
        employee_schedule_ids: employee_schedule_ids
      })
      // this.$store.dispatch('nextFormStep')
    },
    getAvalibleDate(start, end) {
      this.attributes = []
      // this.$store.dispatch("showLoading");
      let step1 = JSON.parse(sessionStorage.getItem('step1'))
      let date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth() + 1
      let lastDay = new Date(y, m + 2, 0)

      const params = {
        hour:
          this.$store.state.formType == 'type7'
            ? this.$store.state.formServiceData.hour
            : sessionStorage.getItem('predictTime') /
              sessionStorage.getItem('predictCleaner'),
        people:
          this.$store.state.formType == 'type7'
            ? this.$store.state.formServiceData.people
            : sessionStorage.getItem('predictCleaner'),
        service_range: this.$store.state.serviceRangeId,
        sdate:
          date.getFullYear() +
          '-' +
          (date.getMonth() + 1) +
          '-' +
          (date.getDate() + 1),
        edate:
          lastDay.getFullYear() +
          '-' +
          lastDay.getMonth() +
          '-' +
          lastDay.getDate()
      }
      this.axios
        .post(
          this.$store.state.apiUrl + 'services/available-reserve-day',
          params
        )
        .then((response) => {
          if (response.status == 200) {
            let data = response.data.data.reserve_days
            let timeSchedule = response.data.data.available_employee_schedules
            let dates = []
            data.forEach((elem) => {
              const timeScheduleByDate = timeSchedule.filter(
                (e) => e.reserve_date === elem
              )
              let emp = []
              timeScheduleByDate.forEach((e) => {
                if (
                  !emp.find(
                    (emp) =>
                      emp.time_period_id === e.time_period_id &&
                      emp.reserve_date === e.reserve_date
                  )
                ) {
                  emp.push({
                    time_period_title: e.time_period_title,
                    time_period_id: e.time_period_id,
                    reserve_date: e.reserve_date,
                    employee_schedule_ids: timeScheduleByDate
                      .filter(
                        (time) =>
                          time.time_period_id === e.time_period_id &&
                          time.reserve_date === e.reserve_date
                      )
                      .map((e) => e.employee_schedule_id)
                  })
                }
              })
              let obj = {
                customData: {
                  status: 1,
                  avalible: emp
                },
                dates: elem
              }
              dates.push(obj)
            })
            this.attributes = dates
          }
        })
        .catch((e) => {})
        .then(() => {
          // this.$store.dispatch("hideLoading");
        })
    }
  },
  computed: {
    lastDay() {
      let LD = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
      if (new Date().getDate() >= 15) {
        LD = new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0)
      }

      return LD
    }
  },
  mounted() {
    this.getAvalibleDate(
      dayjs(this.firstDay).format('YYYY-MM-DD'),
      dayjs(this.lastDay).format('YYYY-MM-DD')
    )
    this.$emit('setSelectedDate', {
      date: '',
      timeId: 0,
      timeLabel: ''
    })
  }
}
</script>
